import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = () => async dispatch => {
    return axiosAPI()
        .get('/home')
        .then(response => {
            dispatch(resultsSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(error.response));
        });
};

function resultsRequested() {
    return {
        type: 'HOME_REQUESTED',
    };
}

function resultsFailed(error) {
    return {
        type: 'HOME_FAILED',
        error,
    };
}

function resultsSuccess(payload) {
    return {
        type: 'HOME_SUCCESS',
        payload,
    };
}
