const initialState = {
    loading: false,
    newsletters: [],
    visible: false,
};

export const newsletterSignupReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_NEWSLETTERS_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'GET_NEWSLETTERS_SUCCESS':
            return {
                ...state,
                newsletters: action.payload.newsletters,
                visible: true,
            };
        case 'GET_NEWSLETTERS_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case 'SUBSCRIBE_TO_NEWSLETTER_REQUESTED':
            return {
                ...state,
            };
        case 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS':
            return {
                ...state,
                visible: action.payload.type === 'error' ? true : false,
            };
        case 'SUBSCRIBE_TO_NEWSLETTER_FAILED':
            return {
                ...state,
                error: action.error,
            };
        case 'HIDE_NEWSLETTER_SIGNUP_MODAL_SUCCESS':
            return {
                ...state,
                visible: false,
            };
    }
};
