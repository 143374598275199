import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loaded: false,
    loading: true,
    author: {},
    posts: [],
    category: {},
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
};

export const authorReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'AUTHOR_REQUESTED':
            return {
                ...state,
                loading: true,
                author: action.payload.author,
                criteria: action.payload.criteria,
            };
        case 'AUTHOR_SUCCESS': {
            let posts = action.payload.posts;
            const pagination = action.payload.author_pagination;
            const author = action.payload.author;

            if (pagination.current_page > 1) {
                posts = [...state.posts, ...posts];
                posts = uniqBy(posts, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                posts,
                ...action.payload.author_pagination,
                author,
                loading: false,
                loaded: moment(),
            };
        }
        case 'AUTHOR_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
                criteria: [],
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            return {
                ...state,
                posts: state.posts.map(a => {
                    if (a.id == postable_id)
                        return {
                            ...a,
                            likes_count: likes_count,
                            post_track: {
                                ...a.post_track,
                                bookmark_flag,
                                like_flag,
                                enrollment_flag,
                            },
                        };
                    return a;
                }),
            };
        }
    }
};
