import React, { Fragment, useRef } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography, Container, useScrollTrigger, Skeleton } from '@mui/material';
import { PostTrack } from '@/shared/articles/post-track';
import {
    PostInfo,
    PostInfoSkeleton,
    Section,
    PostContent,
    PostGrid,
    SignupPopup,
    SignupBlueBanner,
    PostMeta,
    ShareStory,
    ArticleCta,
} from '@/shared/components';
import classes from './post.styles.module.scss';
import clsx from 'clsx';
import { get } from 'lodash';
import { Tags } from '@/shared/articles/tags';
import SafeHtml from '@/shared/components/safe-html/safe-html';

export const Post = ({
    article,
    related,
    loaded,
    showPostInfo = true,
    postInfo,
    showPostTrack = true,
    showSignupPopup,
    showSignupBanner,
    bgcolor,
    showFeaturedImage = true,
    token,
}) => {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 300 });
    const canShowPostInfo = showPostInfo || postInfo;
    const canShowRelated = related && related.length > 0;
    const photo = get(article, 'photos.large.url');
    const viewsCaptured = useRef(false);
    const isAdmin = get(token, 'user.auths.is_admin', false);
    const isSuccessStory = article?.category?.slug == 'success-stories';

    const isVideo = () => {
        return article.type == 'video';
    };

    const isResource = () => {
        return article.type == 'resource';
    };

    const videoIframe = () => {
        if (!isVideo()) return '';
        return `
            <iframe
                width="auto"
                height="315"
                src="https://www.youtube.com/embed/${article?.youtube_video_id}"
                title="${article.title}"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        `;
    };

    const ArticleSkeleton = () => {
        return (
            <Fragment>
                {(isResource() && (
                    <Section>
                        <Section bgcolor={window.isDesktop && bgcolor}>
                            <FlexBox aligncenter>
                                <Container maxWidth="md">
                                    <Skeleton width={'90%'} height={60}></Skeleton>
                                    <Skeleton className="mb-3" width={'60%'} height={60}></Skeleton>
                                    {canShowPostInfo && (
                                        <PostInfoSkeleton
                                            showDate={isAdmin}
                                            showSocial={window.isDestkop}
                                            className="mb-5"
                                            {...postInfo}
                                        ></PostInfoSkeleton>
                                    )}

                                    <Container maxWidth="md" disableGutters>
                                        <FlexBox alignstart justifybetween row>
                                            {photo && showFeaturedImage && (
                                                <Skeleton
                                                    className={classes.photo}
                                                    variant="rect"
                                                    width={'100%'}
                                                    height={isVideo() ? 450 : 550}
                                                ></Skeleton>
                                            )}
                                            <Container maxWidth="md">
                                                {[...Array(7)].map((item, i) => (
                                                    <Skeleton
                                                        className="mt-1"
                                                        key={i}
                                                        width={'100%'}
                                                    ></Skeleton>
                                                ))}
                                                <Skeleton
                                                    className="mt-1 mb-3"
                                                    key={'end1'}
                                                    width={'80%'}
                                                ></Skeleton>
                                                {[...Array(4)].map((item, i) => (
                                                    <Skeleton
                                                        className="mt-1"
                                                        key={i + 10}
                                                        width={'100%'}
                                                    ></Skeleton>
                                                ))}
                                                <Skeleton
                                                    className="mt-1"
                                                    key={'end2'}
                                                    width={'40%'}
                                                ></Skeleton>
                                            </Container>
                                        </FlexBox>
                                    </Container>
                                </Container>
                            </FlexBox>
                        </Section>
                    </Section>
                )) || (
                        <Section>
                            <Section bgcolor={window.isDesktop && bgcolor}>
                                <FlexBox aligncenter>
                                    <Container maxWidth="md">
                                        <Skeleton width={'90%'} height={60}></Skeleton>
                                        <Skeleton className="mb-3" width={'60%'} height={60}></Skeleton>
                                        {canShowPostInfo && (
                                            <PostInfoSkeleton
                                                showDate={isAdmin}
                                                showSocial={window.isDestkop}
                                                className="mb-5"
                                                {...postInfo}
                                            ></PostInfoSkeleton>
                                        )}

                                        {photo && showFeaturedImage && (
                                            <Skeleton
                                                className={classes.photo}
                                                variant="rect"
                                                width={'100%'}
                                                height={isVideo() ? 450 : 550}
                                            ></Skeleton>
                                        )}
                                    </Container>
                                </FlexBox>
                            </Section>
                            <Section>
                                <Container maxWidth="md">
                                    {[...Array(7)].map((item, i) => (
                                        <Skeleton className="mt-1" key={i} width={'100%'}></Skeleton>
                                    ))}
                                    <Skeleton
                                        className="mt-1 mb-3"
                                        key={'end1'}
                                        width={'80%'}
                                    ></Skeleton>
                                    {[...Array(4)].map((item, i) => (
                                        <Skeleton
                                            className="mt-1"
                                            key={i + 10}
                                            width={'100%'}
                                        ></Skeleton>
                                    ))}
                                    <Skeleton className="mt-1" key={'end2'} width={'40%'}></Skeleton>
                                </Container>
                            </Section>
                        </Section>
                    )}
            </Fragment>
        );
    };

    return (
        (loaded && (
            <Fragment>
                <PostMeta post={article} />
                <Section bgcolor={window.isDesktop && bgcolor} className="pb-m-4">
                    <Container maxWidth="md" disableGutters>
                        <FlexBox alignstart justifybetween row>
                            <Typography variant="h1" className={classes.title}>
                                {article.title}
                            </Typography>
                            {window.isDesktop && showPostTrack && (
                                <PostTrack
                                    className="ml-5 ml-m-0 mt-2"
                                    post={article}
                                    showLikes={isResource() ? false : true}
                                    showEdit
                                    showBookmark={isResource() ? false : true}
                                />
                            )}
                        </FlexBox>
                        {canShowPostInfo && (
                            <PostInfo
                                className="mt-3"
                                showCategory
                                showDate={isAdmin}
                                showSocial={window.isDesktop}
                                post={article}
                                {...postInfo}
                            ></PostInfo>
                        )}

                        {(isVideo() && (
                            <FlexBox
                                alignstart
                                justifybetween
                                row
                                className={clsx(classes.videoWrapper, 'mt-5')}
                            >
                                <SafeHtml>{videoIframe()}</SafeHtml>
                            </FlexBox>
                        )) ||
                            (photo && !isResource() && showFeaturedImage && (
                                <img className={clsx(classes.photo, 'mt-5')} alt="" src={photo} />
                            ))}
                    </Container>
                </Section>
                {showSignupPopup && <SignupPopup></SignupPopup>}
                <Section className="pt-0">
                    {trigger && (showPostTrack || canShowPostInfo) && (
                        <div className={classes.bottomBarContainer}>
                            <FlexBox justifycenter className={classes.bottomBar}>
                                <Container maxWidth="md">
                                    <FlexBox w100 h100 row justifybetween aligncenter>
                                        {canShowPostInfo && (
                                            <PostInfo
                                                post={article}
                                                showSocial
                                                showAvatar={false}
                                                showName={false}
                                                {...postInfo}
                                            ></PostInfo>
                                        )}
                                        {showPostTrack && (
                                            <PostTrack
                                                className="ml-4"
                                                post={article}
                                                showLikes
                                                showEdit
                                                captureViews={!viewsCaptured.current}
                                                onCapturedViews={() =>
                                                    (viewsCaptured.current = true)
                                                }
                                            />
                                        )}
                                    </FlexBox>
                                </Container>
                            </FlexBox>
                        </div>
                    )}

                    {(isResource() && (
                        <Container
                            disableGutters
                            maxWidth="md"
                            className={clsx(classes.resourceContainer)}
                        >
                            {photo && (
                                <img
                                    className={clsx(classes.resourcePhoto, 'mr-5')}
                                    alt=""
                                    src={photo}
                                />
                            )}
                            <PostContent className="wp-content" content={article.content} />
                        </Container>
                    )) || <PostContent className="wp-content" content={article.content} />}

                    {(isSuccessStory && (
                        <Fragment>
                            <Container disableGutters className="mt-5" maxWidth="md">
                                <Tags
                                    categoryLink={article.category.link}
                                    tags={article.tags}
                                    currentTag={true}
                                    showMore={false}
                                />
                            </Container>
                            <Container disableGutters className="mt-5" maxWidth="md">
                                <ShareStory></ShareStory>
                            </Container>
                        </Fragment>
                    )) ||
                        (!isResource() && (
                            <Fragment>
                                <Container disableGutters className="mt-5" maxWidth="md">
                                    <ArticleCta></ArticleCta>
                                </Container>
                            </Fragment>
                        ))}

                    {(isVideo() && (
                        <div className={clsx(classes.viewsCount, 'mt-5')}>
                            &mdash; This video has been viewed {article.views_count} times &mdash;
                        </div>
                    )) ||
                        (!isResource() && (
                            <div className={clsx(classes.viewsCount, 'mt-5')}>
                                &mdash; This article has been read {article.views_count} times
                                &mdash;
                            </div>
                        ))}
                </Section>

                {showSignupBanner && (
                    <Container className="mt-4 mb-5" disableGutters maxWidth="md">
                        <SignupBlueBanner></SignupBlueBanner>
                    </Container>
                )}
                {canShowRelated && (
                    <Section bgcolor="gray000">
                        <Typography variant="h3" className="mb-5">
                            Continue Reading
                        </Typography>
                        <PostGrid showAsListItem={!window.isDesktop} results={related} />
                    </Section>
                )}
            </Fragment>
        )) || <ArticleSkeleton></ArticleSkeleton>
    );
};
