import axiosAPI from '@/shared/api';

export const getResult = id => async dispatch => {

    dispatch(resultRequested());

    return axiosAPI()
        .get(`/course/${id}`)
        .then(response => {
            dispatch(resultSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultFailed(error.response));
        });
};

function resultRequested() {
    return {
        type: 'COURSE_REQUESTED',
    };
}

function resultFailed(error) {
    return {
        type: 'COURSE_FAILED',
        error,
    };
}

function resultSuccess(payload) {
    return {
        type: 'COURSE_SUCCESS',
        payload,
    };
}
