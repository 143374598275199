const initialState = {
    missing_fields: [],
    success: false,
};

export const createAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'ACCOUNT_INCOMPLETE_SUCCESS': {
            const missing_fields = action.payload.missing_fields;
            return {
                ...state,
                success: true,
                missing_fields: Object.keys(missing_fields).map(id => {
                    let question = missing_fields[id];
                    let options =
                        question.options &&
                        Object.keys(question.options).map(optionId => {
                            return {
                                id: optionId,
                                title: question.options[optionId],
                            };
                        });
                    return {
                        id: id,
                        ...question,
                        title: question.label_edit,
                        options: options,
                    };
                }),
            };
        }
        case 'ACCOUNT_INCOMPLETE_REQUESTED': {
            return {
                ...state,
                success: false,
            };
        }
        case 'ACCOUNT_INCOMPLETE_FAILED': {
            return {
                ...state,
                success: false,
            };
        }

        case 'ACCOUNT_ANSWER_REQUESTED': {
            return {
                ...state,
                answerSuccess: false,
            };
        }
        case 'ACCOUNT_ANSWER_SUCCESS': {
            return {
                ...state,
                answerSuccess: true,
            };
        }
        case 'ACCOUNT_ANSWER_FAILED': {
            return {
                ...state,
                answerSuccess: false,
            };
        }
    }
};
