import React from 'react';
import { PostCard, PostCardSkeleton } from '../post-card';
import classes from './post-grid.module.scss';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroller';
import FlexBox from '@/shared/components/flex-box/flex-box';
import Placeholder from '@/shared/components/placeholder/placeholder';
import { has, get, isFunction } from 'lodash';
import { Link } from 'react-router-dom';
import { Pagination, PaginationItem } from '@mui/material';
import queryString from 'query-string';
import history from '@/shared/history';

export const PostGrid = props => {
    const {
        current_page,
        total_pages,
        loading,
        placeholderMessage,
        placeholderTitle,
        columns,
        showAsListItem,
        skeletonCount = 9,
        renderItem,
        placeholder,
    } = props;

    const resultsKey = get(props, 'resultsKey', 'results');
    const results = props[resultsKey];

    const isResource = () => {
        return resultsKey == 'resources';
    };

    const loadMore = () => {
        const { loading, current_page, total_pages, criteria } = props;

        if (!loading && current_page < total_pages) {
            props.getResults(props.slug, current_page + 1, criteria);
        }
    };

    let cards = null;
    if ((!results || (results && results.length == 0)) && loading) {
        cards = [...Array(skeletonCount)].map((r, i) => (
            <PostCardSkeleton showAsListItem={showAsListItem} key={i} type={resultsKey} />
        ));
    } else {
        if (renderItem) {
            cards = results && results.map(renderItem);
        } else {
            cards =
                results &&
                results.map(r => {
                    return (
                        <PostCard
                            showAsListItem={showAsListItem}
                            key={r.id}
                            post={r}
                            type={resultsKey}
                        />
                    );
                });
        }
    }

    const morePages = !loading && current_page < total_pages;
    const noResults = !props.loading && (!results || (results && results.length == 0));
    let resultsClasses = [classes.results];
    columns && resultsClasses.push(classes[`col${columns}`]);

    // Pagination is needed for Renderfy so that bots can crawl.
    const infiniteScrollOrPagination = () => {
        if (window.Renderfy) {
            return (
                <div>
                    <div className={isResource() ? classes.resultsResources : clsx(resultsClasses)}>
                        {cards}
                    </div>
                    {total_pages > 0 && (
                        <Pagination
                            className="mt-5"
                            page={current_page}
                            count={total_pages}
                            renderItem={item => {
                                let url = history.location.pathname;
                                let qs = queryString.parse(history.location.search);
                                qs.page = item.page;
                                url = url += '?' + queryString.stringify(qs);

                                return <PaginationItem component={Link} to={url} {...item} />;
                            }}
                        />
                    )}
                </div>
            );
        }
        return (
            <InfiniteScroll
                className={isResource() ? classes.resultsResources : clsx(resultsClasses)}
                pageStart={1}
                useWindow={true}
                loadMore={loadMore}
                threshold={1000}
                hasMore={morePages}
                loader={
                    <div className="loader" key={0}>
                        Loading ...
                    </div>
                }
            >
                {cards}
            </InfiniteScroll>
        );
    };

    return (
        <FlexBox w100>
            {(!noResults && infiniteScrollOrPagination()) || placeholder || (
                <Placeholder
                    height={!window.isDesktop && '50vh'}
                    title={placeholderTitle || 'No Results'}
                    message={placeholderMessage || 'Try expanding your search'}
                    image="https://d2q86h5pvn46dj.cloudfront.net/resources/search_placeholder.svg"
                />
            )}
        </FlexBox>
    );
};
