import React, { Fragment, useEffect } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import history from '@/shared/history';
import { Typography, Grid } from '@mui/material';
import { Categories } from './categories';
import { TopArticles } from './top-articles';
import { Featured } from './featured';
import { Trending } from './trending';
import { PostGrid } from '@/shared/components';
//import { Sort } from '@/shared/components/sort';
import helpers from '@/shared/util/helpers';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { get } from 'lodash';

export const Articles = props => {
    // const sort = option => {
    //     history.replace({
    //         pathname: props.slug,
    //         search: `?sort_by=${option}`,
    //     });

    //     props.getResults(props.slug, 1, {
    //         ...props.criteria,
    //         sort_by: option,
    //     });
    // };

    const getResults = () => {
        const qparams = queryString.parse(props.location.search);
        let page = get(qparams, 'page', 1);

        if (props.total == 0) {
            if (!window.Renderfy) page = 1;
            props.getResults(props.slug, page, qparams);
        }
    };

    useEffect(() => {
        props.expireStore();
        getResults();
    }, []);

    useDidUpdateEffect(() => {
        getResults();
    }, [props.total]);

    const renderTop = () => {
        const qparams = queryString.parse(props.location.search);
        const page = get(qparams, 'page', 1);
        if (window.Renderfy && page > 1) return;

        return (
            <Fragment>
                {/* <Typography variant="h1" className="my-5 my-m-4">
                    Articles
                </Typography> */}
                <Grid container spacing={4} className="my-4">
                    <Grid item {...gridSizes}>
                        <Featured article={props.featured} />
                    </Grid>
                    <Grid item {...gridSizes}>
                        <TopArticles
                            count={4}
                            loading={props.loading}
                            articles={props.topArticles}
                        />
                    </Grid>
                    <Grid item {...gridSizes}>
                        <Categories categories={props.categories} />
                    </Grid>
                </Grid>

                <Trending count={6} articles={props.trending} loading={props.loading} />

                {/* <div className={'mb-4 mt-3 ml-0 mt-m-5'} style={{ textAlign: 'right' }}>
                    <Sort
                        showSelected={true}
                        key="sort"
                        keepScrollPosition
                        criteria={props.criteria}
                        onSort={sort}
                        options={[
                            { title: 'Most Recent', value: 'newest' },
                            {
                                title: 'Popular this Week',
                                value: 'popweek',
                            },
                            {
                                title: 'Popular this Month',
                                value: 'popmonth',
                            },
                            {
                                title: 'Popular this Year',
                                value: 'popyear',
                            },
                            { title: 'Popular all Time', value: 'popall' },
                        ]}
                    />
                </div> */}
            </Fragment>
        );
    };

    const gridSizes = window.isDesktop ? { xs: 4 } : { xs: 12, md: 6, lg: 4 };
    const { setTitle } = helpers;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Articles')}</title>
            </Helmet>

            <Fragment>
                {renderTop()}
                <div className="my-5">
                    <PostGrid showAsListItem={!window.isDesktop} resultsKey="articles" {...props} />
                </div>
            </Fragment>
        </Fragment>
    );
};
