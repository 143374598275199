import React, { useEffect } from 'react';
import { Route, matchPath } from 'react-router-dom';
import { Guards } from './guards';
import helpers from '@/shared/util/helpers';
import { isString } from 'lodash';

export const RouteGuard = ({ guards = [], ...props }) => {
    const { token, path, location } = props;
    const { pathname } = location;
    const matchResult = path ? matchPath(pathname, props) !== null : true;
    const hasMatchedRoute = matchResult;
    let canBeRendered = true;

    if (hasMatchedRoute && guards.length) {
        canBeRendered = guards.every(guard => {
            if (!(guard in Guards)) {
                throw new Error(`RouteGuard: ${guard} is not valid?!`);
            }
            return Guards[guard].test(token);
        });
    }

    useEffect(() => {
        let { redirectUrl, redirectState } = props;

        if (hasMatchedRoute && guards.length) {
            guards.every(guard => {
                if (!(guard in Guards)) {
                    throw new Error(`RouteGuard: ${guard} is not valid?!`);
                }

                const success = Guards[guard].test(token);

                //console.log(guard, success, redirectUrl);

                if (!success && !redirectUrl) {
                    // Set _done for UserGuard
                    if (guard == 'user') {
                        window.localStorage.setItem('_done', props.location.pathname);
                    }

                    if (!redirectUrl) {
                        const fail = Guards[guard].fail(token);
                        if (isString(fail)) {
                            redirectUrl = fail;
                        } else {
                            redirectUrl = fail.url;
                            redirectState = fail?.state;
                        }
                    }

                    //console.log('failed:', guard, redirectUrl, redirectState);

                    if (window.localStorage.getItem('externalRedirectInProgress')) {
                        window.localStorage.removeItem('externalRedirectInProgress');
                        helpers.showLoader();
                    } else {
                        props.queueRedirect(redirectUrl, redirectState, true);
                    }
                }
                return success;
            });
        }
    }, [location, token]);

    return canBeRendered ? <Route {...props} /> : null;
};
