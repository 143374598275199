import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (author = null, page = 1, criteria = {}) => async dispatch => {
    criteria = { ...criteria, page };
    dispatch(resultsRequested(author, criteria));

    let url = `/author/${author}`;

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(resultsSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(error.response));
        });
};

function resultsRequested(author, criteria) {
    return {
        type: 'AUTHOR_REQUESTED',
        payload: {
            author,
            criteria,
        },
    };
}

function resultsFailed(error) {
    return {
        type: 'AUTHOR_FAILED',
        error,
    };
}

function resultsSuccess(payload) {
    return {
        type: 'AUTHOR_SUCCESS',
        payload,
    };
}
