import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from '../../layouts/main-layout';
import { Resources } from '../../resources';
import { Resource } from '../../resources/resource';

export const ResourceRoutes = () => {
    return (
        <Switch>
            <Route exact path={['/resources', '/resources']}>
                <MainLayout footerFixed={true}>
                    <Route
                        path="/resources"
                        exact
                        render={props => {
                            return <Resources {...props}></Resources>;
                        }}
                    />
                </MainLayout>
            </Route>

            <Route path={['/resources/:slug']}>
                <MainLayout fullWidth={true}>
                    <Route
                        exact
                        path="/resources/:slug"
                        render={props => {
                            const slug = props.match.params.slug;
                            return <Resource key={slug} slug={slug} {...props}></Resource>;
                        }}
                    />
                </MainLayout>
            </Route>
        </Switch>
    );
};

export default ResourceRoutes;
