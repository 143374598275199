import React from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Tabs, Tab } from '@mui/material';
import { Password } from './password';
import { Sessions } from './sessions';
import { TFA } from './tfa';
import { TrustedDevices } from './trusted-devices';
import { AccountLayout } from '../account-layout';
import { useDidUpdateEffect } from '@/shared/util/hooks';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <FlexBox>{children}</FlexBox>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Security = props => {
    const tabArr = [
        {
            name: 'Password',
            substring: 'password',
            component: <Password {...props} />,
        },
        {
            name: 'Sessions',
            substring: 'sessions',
            component: <Sessions {...props} />,
        },
        {
            name: 'Trusted Devices',
            substring: 'trusted',
            component: <TrustedDevices {...props} />,
        },
        {
            name: 'Two-Factor Authentication',
            substring: 'tfa',
            component: <TFA {...props} />,
        },
    ];
    const selectedIndex = tabArr.findIndex(tab => {
        return window.location.href.match(tab.substring);
    });

    const [value, setValue] = React.useState(selectedIndex);

    const navigate = (event, newValue) => {
        setValue(newValue);
    };

    useDidUpdateEffect(() => {
        window.history.replaceState(null, null, `${tabArr[value].substring}`);
    }, [value]);

    const tabs = (
        <Tabs
            value={value}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            onChange={navigate}
        >
            {tabArr.map((tab, index) => {
                return <Tab key={index} className="ml-4" label={tab.name} {...a11yProps(index)} />;
            })}
        </Tabs>
    );
    return (
        <AccountLayout
            paddingTop={window.isDesktop ? 0 : 40}
            menuProps={{
                showBack: !window.isDesktop,
                title: 'Security',
                subMenu: !window.isDesktop && tabs,
            }}
        >
            <FlexBox flex1>
                {window.isDesktop && tabs}
                <FlexBox flex1 className="mt-4">
                    {tabArr.map((tab, index) => {
                        return (
                            <TabPanel key={index} value={value} index={index}>
                                {tab.component}
                            </TabPanel>
                        );
                    })}
                </FlexBox>
            </FlexBox>
        </AccountLayout>
    );
};
