import { connect } from 'react-redux';
import { Navigation as NavigationComponent } from './navigation.component';
import { getHandshake, resetStore, updateWebVersion } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.authReducer;
};

export const Navigation = connect(mapStateToProps, {
    getHandshake,
    resetStore,
    updateWebVersion,
})(NavigationComponent);
