import { connect } from 'react-redux';
import { Footer as FooterComponent } from './footer.component';

const mapStateToProps = state => {
    return {
        user: state.authReducer.token?.user,
    };
};

export const Footer = connect(mapStateToProps, {})(FooterComponent);
