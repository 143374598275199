import { connect } from 'react-redux';
import { Category as CategoryComponent } from './category.component';
import { getResults } from './category.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.categoryReducer;
};

export const Category = connect(mapStateToProps, {
    getResults, expireStore
})(CategoryComponent);
