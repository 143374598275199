import React from 'react';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import { styled } from "@mui/system";

const ToBeStyledTabs = ({ className, props }) => {
    <MuiTabs {...props} classes={{ ...className }} TabIndicatorProps={{ children: <span /> }} />
}

export const Tabs = styled(ToBeStyledTabs)(({ theme }) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: theme.palette.primary.highlight,
        },
    },
}));

const ToBeStyledTab = ({ className, props }) => {
    <MuiTab {...props} disableRipple classes={{ ...className }} />
}

export const Tab = styled(ToBeStyledTab)(({ theme }) => ({
    root: {
        textTransform: 'none',
        color: '#000',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
    selected: {
        color: theme.palette.primary.highlight,
    },
}));
