import React, { useEffect } from 'react';
import { PostGrid, LottiePlaceholder } from '@/shared/components';
import lottieJson from '../library.json';

export const Articles = props => {
    useEffect(() => {
        props.expireStore();
        if (props.total == 0) {
            props.getResults('articles', 1);
        }
    }, []);

    const placeholder = (
        <LottiePlaceholder
            json={lottieJson}
            title="Get to Bookmarking!"
            message="Keep articles you like and want to read or revisit later by bookmarking
    them."
        ></LottiePlaceholder>
    );

    return <PostGrid resultsKey="articles" {...props} placeholder={placeholder} />;
};
