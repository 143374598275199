import React from 'react';
import helpers from '@/shared/util/helpers';
import { Typography, Button } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }) {
    window.Sentry.captureException(new Error(`Error Boundary: ${error.message}`));
    return (
        <FlexBox justifycenter aligncenter style={{ height: '90vh' }}>
            <img
                width={window.isDesktop ? 300 : 230}
                src="https://d2q86h5pvn46dj.cloudfront.net/website/broken_robot.png"
                alt="error"
            />
            <Typography className="mt-4" variant="h2">
                Uh Oh!
            </Typography>
            <Typography className="mt-1" variant="h5">
                Something went wrong.
            </Typography>
            <Button className="mt-4" variant="contained" onClick={resetErrorBoundary}>
                Try again
            </Button>
        </FlexBox>
    );
}

export const ErrorBoundary = ({ children, resetStore, getHandshake, updateWebVersion }) => {
    const onReset = () => {
        resetStore();
        getHandshake().then(() => {
            updateWebVersion(helpers.getWebVersion());
        });
    };

    return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={onReset}>
            {children}
        </ReactErrorBoundary>
    );
};
