import { connect } from 'react-redux';
import { Lesson as LessonComponent } from './lesson.component';
import { getResult, saveProgress } from './lesson.actions';
const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state.lessonReducer,
    }

};

export const Lesson = connect(mapStateToProps, {
    getResult, saveProgress,
})(LessonComponent);
