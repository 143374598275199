import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loading: true,
    articles: [],
    categories: [],
    category: {},
    featured: null,
    trending: [],
    topArticles: [],
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    lastLoadAt: null,
};

export const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'ARTICLES_REQUESTED':
            return {
                ...state,
                loading: true,
                category: action.payload.category,
                criteria: action.payload.criteria,
                articles: action.payload.page == 1 ? [] : state.articles,
                featured: action.payload.page == 1 ? null : state.featured,
                topArticles: action.payload.page == 1 ? [] : state.topArticles,
                trending: action.payload.page == 1 ? [] : state.trending,
            };
        case 'ARTICLES_SUCCESS': {
            let articles = action.payload.articles;
            const pagination = action.payload.articles_pagination;
            const categories = action.payload.categories;
            const featured = !state.featured ? articles.shift() : state.featured;
            const topArticles =
                state.topArticles.length == 0 ? articles.splice(0, 4) : state.topArticles;
            const trending = action.payload.trending;
            if (pagination.current_page > 1) {
                articles = [...state.articles, ...articles];
                articles = uniqBy(articles, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                articles,
                ...action.payload.articles_pagination,
                categories,
                featured,
                trending,
                topArticles,
                loading: false,
                lastLoadAt: moment(),
            };
        }
        case 'ARTICLES_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
                criteria: [],
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            const updateId = a => {
                if (a.id == postable_id)
                    return {
                        ...a,
                        likes_count: likes_count,
                        post_track: {
                            ...a.post_track,
                            bookmark_flag,
                            like_flag,
                            enrollment_flag,
                        },
                    };
                return a;
            };

            return {
                ...state,
                articles: state.articles.map(updateId),
                topArticles: state.topArticles.map(updateId),
                trending: state.trending.map(updateId),
                featured:
                    state.featured && postable_id == state.featured.id
                        ? { ...state.featured, post_track }
                        : state.featured,
            };
        }

        case 'EXPIRE_STORE': {
            if (
                action.force ||
                (state.lastLoadAt && moment(state.lastLoadAt) < moment().subtract(60, 'minutes'))
            ) {
                return initialState;
            }
            return { ...state };
        }
    }
};

export { authorReducer } from '../author/author.reducer';
