import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (
    category = null,
    page = 1,
    criteria = {}
) => async dispatch => {
    criteria = { ...criteria, page };
    dispatch(resultsRequested(category, criteria, page));

    let url = '/courses';
    if (category) {
        url += `/${category}`;
    }

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(resultsSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(error.response));
        });
};

function resultsRequested(category, criteria, page) {
    return {
        type: 'COURSES_REQUESTED',
        payload: {
            category,
            criteria,
            page,
        },
    };
}

function resultsFailed(error) {
    return {
        type: 'COURSES_FAILED',
        error,
    };
}

function resultsSuccess(payload) {
    return {
        type: 'COURSES_SUCCESS',
        payload,
    };
}
