import axiosAPI from '@/shared/api';

export const getResult = (lessonId) => async dispatch => {

    dispatch(resultRequested());

    return axiosAPI()
        .get(`/course/lesson/${lessonId}`)
        .then(response => {
            dispatch(resultSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultFailed(error.response));
        });
};

function resultRequested() {
    return {
        type: 'LESSON_REQUESTED',
    };
}

function resultFailed(error) {
    return {
        type: 'LESSON_FAILED',
        error,
    };
}

function resultSuccess(payload) {
    return {
        type: 'LESSON_SUCCESS',
        payload,
    };
}

export const saveProgress = (lessonId, videoTime = 0, isComplete) => async dispatch => {

    dispatch(saveProgressRequested());

    return axiosAPI()
        .post(`/course/lesson/${lessonId}/save-progress`, { video_time:videoTime, is_complete:isComplete })
        .then(response => {
            dispatch(saveProgressSuccess(response.data));
        })
        .catch(error => {
            dispatch(saveProgressFailed(error.response));
        });
};

function saveProgressRequested() {
    return {
        type: 'SAVE_PROGRESS_REQUESTED',
    };
}

function saveProgressFailed(error) {
    return {
        type: 'SAVE_PROGRESS_FAILED',
        error,
    };
}

function saveProgressSuccess(payload) {
    return {
        type: 'SAVE_PROGRESS_SUCCESS',
        payload,
    };
}