import { connect } from 'react-redux';
import { Article as ArticleComponent } from './article.component';
import { getResult } from './article.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state.articleReducer,
    };
};

export const Article = connect(mapStateToProps, {
    getResult,
    expireStore,
})(ArticleComponent);
