import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './score.styles.module.scss';
import { motion } from 'framer-motion';

export const Score = ({ score: incomingScore }) => {
    const [score, setScore] = useState(incomingScore);
    const [difference, setDifference] = useState(0);

    useEffect(() => {
        setDifference(incomingScore - score);
        setScore(incomingScore);
    }, [incomingScore]);

    return (
        <FlexBox justifycenter aligncenter>
            {difference > 0 && (
                <motion.div
                    initial={{ opacity: 0, y: -5, position: 'absolute' }}
                    animate={{ opacity: [1, 0], y: [-20, -50] }}
                >
                    <Typography style={{ color: '#3271a0' }} variant="h4">
                        <strong>+{difference}</strong>
                    </Typography>
                </motion.div>
            )}
            <Typography className={classes.score} variant="h5">
                {score || '0000'}
            </Typography>
        </FlexBox>
    );
};
