import React, { Component } from 'react';
import classes from './progress-bar.module.scss';

export default class ProgressBar extends Component {
    render() {
        const { progress, className } = this.props;
        return (
            <div className={classes.progressContainer + ' ' + className}>
                <div
                    style={{ width: progress + '%' }}
                    className={classes.progress}
                />
            </div>
        );
    }
}
