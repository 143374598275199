import React, { useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import classes from './courses.styles.module.scss';
import clsx from 'clsx';
import { PostGrid } from '@/shared/components';
import helpers from '@/shared/util/helpers';
import { Typography } from '@mui/material';

const { setTitle } = helpers;
export const Courses = props => {
    useEffect(() => {
        props.expireStore();
        if (props.total == 0) {
            props.getResults(null, 1);
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Courses')}</title>
            </Helmet>
            <div id="courses" className="pb-6">
                <Typography variant="h3" className={clsx(classes.pageTitle, 'my-5')}>
                    Courses
                </Typography>
                <PostGrid showAsListItem={false} resultsKey="courses" {...props} />
            </div>
        </Fragment>
    );
};
