import { connect } from 'react-redux';
import { NewsletterSignup as NewsletterSignupComponent } from './newsletter-signup.component';
import {
    getNewsletters,
    subscribeToNewsletter,
    hideNewsletterSignupModal,
} from './newsletter-signup.actions';

const mapStateToProps = state => {
    return state.newsletterSignupReducer;
};

export const NewsletterSignup = connect(mapStateToProps, {
    getNewsletters,
    subscribeToNewsletter,
    hideNewsletterSignupModal,
})(NewsletterSignupComponent);
