import React, { useEffect, useRef } from 'react';
import classes from './lottie-placeholder.styles.module.scss';
import clsx from 'clsx';
import { Player } from '@lottiefiles/react-lottie-player';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography } from '@mui/material';

export const LottiePlaceholder = ({
    json,
    delay = 700,
    style = {},
    title,
    message,
    speed = 1,
    loop = false,
}) => {
    const lottieRef = useRef(null);
    const timerRef = useRef(null);
    useEffect(() => {
        timerRef.current = setTimeout(() => {
            lottieRef.current && lottieRef.current.play();
        }, delay);
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <FlexBox justifycenter aligncenter className={clsx(classes.placeholderContainer, 'mt-3')}>
            <Player
                speed={speed}
                autoplay={false}
                ref={lottieRef}
                keepLastFrame={true}
                loop={loop}
                src={json}
                style={
                    style || window.isDesktop
                        ? { height: '250px', width: '250px' }
                        : { height: '150px', width: '150px' }
                }
            ></Player>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body1" className="mt-1" align="center" style={{ maxWidth: 400 }}>
                {message}
            </Typography>
        </FlexBox>
    );
};
