import axiosAPI from '@/shared/api';

export const getNewsletters = () => async dispatch => {
    dispatch(getNewslettersRequested());

    return axiosAPI()
        .get('/newsletter/list')
        .then(response => {
            dispatch(getNewslettersSuccess(response.data));
        })
        .catch(error => {
            dispatch(getNewslettersFailed(error.response));
        });
};

function getNewslettersRequested() {
    return {
        type: 'GET_NEWSLETTERS_REQUESTED',
    };
}

function getNewslettersSuccess(payload) {
    return {
        type: 'GET_NEWSLETTERS_SUCCESS',
        payload,
    };
}

function getNewslettersFailed(error) {
    return {
        type: 'GET_NEWSLETTERS_FAILED',
        error,
    };
}

export const subscribeToNewsletter = request => async dispatch => {
    dispatch(subscribeToNewsletterRequested());

    return axiosAPI()
        .post('/newsletter', request)
        .then(response => {
            dispatch(subscribeToNewsletterSuccess(response));
        })
        .catch(error => {
            dispatch(subscribeToNewsletterFailed(error.response));
        });
};

function subscribeToNewsletterRequested() {
    return {
        type: 'SUBSCRIBE_TO_NEWSLETTER_REQUESTED',
    };
}

function subscribeToNewsletterSuccess(payload) {
    return {
        type: 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS',
        payload,
    };
}

function subscribeToNewsletterFailed(error) {
    return {
        type: 'SUBSCRIBE_TO_NEWSLETTER_FAILED',
        error,
    };
}

export const hideNewsletterSignupModal = () => dispatch => {
    dispatch(hideNewsletterSignupModalSuccess());
};

function hideNewsletterSignupModalSuccess() {
    return {
        type: 'HIDE_NEWSLETTER_SIGNUP_MODAL_SUCCESS',
    };
}
