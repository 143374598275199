import React, { useState, useEffect } from 'react';
import classes from './radio-control.styles.module.scss';
import FlexBox from '../../flex-box/flex-box';
import { ButtonBase } from '@mui/material';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import clsx from 'clsx';

export const RadioControl = props => {
    const [selected, setSelected] = useState(props.answer);
    let locked = false;

    if (!props.options) return null;

    const answered = index => {
        if (locked) return;
        locked = true;
        let selectedAns = props.options[index].id;

        setSelected(selectedAns);
        props.onAnswer(selectedAns);
    };

    const onClick = (e, i) => {
        if (e.detail == 1) {
            answered(i);
        }
    };

    const answers = props.options.map((ans, i) => {
        const isSelected = selected && ans.id == selected;
        return (
            <div key={`${props.id}${i}`} style={{ padding: 5, ...props.style }}>
                <ButtonBase onClick={e => onClick(e, i)}>
                    <FlexBox
                        row
                        aligncenter
                        justifycenter
                        className={clsx(classes.answer, isSelected && classes.selected)}
                    >
                        {window.isDesktop && (
                            <FlexBox
                                justifycenter
                                aligncenter
                                className={clsx(classes.radio, isSelected && classes.checked)}
                            >
                                <i className={classes.check + ' far fa-check'} />
                            </FlexBox>
                        )}
                        <p className={classes.text}>{ans.title}</p>
                    </FlexBox>
                </ButtonBase>
            </div>
        );
    });

    return answers;
};
