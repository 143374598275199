import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (category = null, page = 1, criteria = {}) => async dispatch => {
    criteria = { ...criteria, page };
    dispatch(getVideosRequested(category, criteria, page));

    let url = '/videos';
    if (category) {
        url += `/${category}`;
    }

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(getVideosSuccess(response.data));
        })
        .catch(error => {
            dispatch(getVideosFailed(error.response));
        });
};

function getVideosRequested(category, criteria, page) {
    return {
        type: 'GET_VIDEOS_REQUESTED',
        payload: {
            category,
            criteria,
            page,
        },
    };
}

function getVideosFailed(error) {
    return {
        type: 'GET_VIDEOS_FAILED',
        error,
    };
}

function getVideosSuccess(payload) {
    return {
        type: 'GET_VIDEOS_SUCCESS',
        payload,
    };
}
