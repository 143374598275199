import { connect } from 'react-redux';
import { Stats as StatsComponent } from './stats.component';
//import { sampleAction } from './stats.actions.js';

const mapStateToProps = state => {
    return state.triviaReducer;
};

export const Stats = connect(mapStateToProps, {
    //sampleAction,
})(StatsComponent);
