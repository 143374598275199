import { connect } from 'react-redux';
import { Scores as ScoresComponent } from './scores.component';
import { getTrivia, getQuestion, getScores } from '../trivia.actions.js';
const mapStateToProps = state => {
    return state.triviaReducer;
};

export const Scores = connect(mapStateToProps, {
    getTrivia,
    getQuestion,
    getScores,
})(ScoresComponent);
