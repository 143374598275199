import React from 'react';
import { app_url } from 'config';
import { FloatingFooter } from '@/shared/components';
import { logo_white_plus } from 'config';

export const Footer = ({ fixed, user }) => {
    const logo = {
        src: logo_white_plus,
        height: 25,
        link: '/articles',
        alt: 'CatholicMatch Plus Home',
    };

    const linkSets = [
        [
            { text: 'Articles', to: '/articles' },
            { text: 'Courses', to: '/courses' },
            { text: 'Videos', to: '/videos' },
            { text: 'CM News', to: '/articles/category/catholicmatch-news' },
        ],
        [
            { text: 'Library', to: '/library' },
            { text: 'Settings', href: `${app_url}/account/settings` },
            { text: 'Share Success Story', href: `${app_url}/share`, target: '_blank' },
            { text: 'Careers', href: 'https://catholicmatch.workable.com', target: '_blank' },
        ],
        [
            { text: 'Resources', to: '/resources' },
            { text: 'Temperaments', to: '/temperaments' },
            { text: 'Privacy', href: `${app_url}/privacy`, target: '_blank' },
            { text: 'Terms of Use', href: `${app_url}/terms`, target: '_blank' },
        ],
    ];
    return (
        <FloatingFooter
            fixed={fixed}
            showAppButtons={user}
            links={linkSets}
            showRaphael={false}
            logo={logo}
        ></FloatingFooter>
    );
};
