import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (s = null, page = 1, criteria = {}) => async dispatch => {
    criteria = { s, ...criteria, page };
    dispatch(resultsRequested(s, criteria));

    let url = '/home/search';

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(resultsSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(error.response));
        });
};

function resultsRequested(s, criteria) {
    return {
        type: 'SEARCH_REQUESTED',
        payload: {
            s,
            criteria,
        },
    };
}

function resultsFailed(error) {
    return {
        type: 'SEARCH_FAILED',
        error,
    };
}

function resultsSuccess(payload) {
    return {
        type: 'SEARCH_SUCCESS',
        payload,
    };
}
