import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import helpers from '@/shared/util/helpers';
import { useInput, useDidUpdateEffect } from '@/shared/util/hooks';
import { Button, TextField, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';

export const ForgotPassword = props => {
    const { bind: email } = useInput('');
    const [emailSent, setEmailSent] = useState(false);

    const { setTitle } = helpers;

    useDidUpdateEffect(() => {
        setEmailSent(true);
    }, [props.passwordResetEmailSent]);

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            sendPasswordResetLink();
        }
    };

    const sendPasswordResetLink = function () {
        props.sendPasswordResetLink({ email: email.value });
    };

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Forgot Password')}</title>
            </Helmet>
            <Container maxWidth="xs" className="py-5 align-center">
                <Typography align="center" variant="h2" component="h1" className="mb-m-4 mb-5">
                    Forgot your password?
                </Typography>
                <TextField
                    className="mt-3 w-100"
                    variant="outlined"
                    {...email}
                    name="email"
                    label="Email Address"
                    inputProps={{
                        autoCapitalize: 'none',
                        autoComplete: 'on',
                        autoCorrect: 'off',
                    }}
                    onKeyPress={handleEnterPress}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className="mt-3"
                    size="large"
                    onClick={sendPasswordResetLink}
                >
                    Send Password Reset Link
                </Button>
            </Container>
        </Fragment>
    );
};
