import React, { Fragment, useState } from 'react';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './tags.styles.module.scss';
import clsx from 'clsx';

export const Tags = ({ tags, categoryLink, currentTag, showMore = true }) => {
    const [showingMoreTags, setShowingMoreTags] = useState(false);

    const renderTags = () => {
        const allTags = [
            <Link key="all" to={categoryLink}>
                <ButtonBase className={clsx(classes.tag, !currentTag && classes.current)}>
                    <SafeHtml className={clsx(classes.name)}>All</SafeHtml>
                </ButtonBase>
            </Link>,
        ];

        const spliceTo = showingMoreTags ? tags.length : 6;

        allTags.push(
            tags.slice(0, spliceTo).map(r => {
                const isCurrent = currentTag == r.slug;
                return (
                    <Link key={r.slug} to={r.link}>
                        <ButtonBase className={clsx(classes.tag, isCurrent && classes.current)}>
                            <SafeHtml className={classes.name}>{r.name}</SafeHtml>
                        </ButtonBase>
                    </Link>
                );
            })
        );

        if (showMore) {
            allTags.push([
                <ButtonBase
                    key="more"
                    onClick={() => {
                        setShowingMoreTags(!showingMoreTags);
                    }}
                    className={clsx(classes.tag, classes.noBackground)}
                >
                    <SafeHtml className={clsx(classes.name, classes.moreLess)}>
                        {showingMoreTags ? 'Less' : 'More'}
                    </SafeHtml>
                </ButtonBase>,
            ]);
        }

        return allTags;
    };

    if (tags.length < 1) {
        return null;
    }

    return <Fragment>{renderTags()}</Fragment>;
};
