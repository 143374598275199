import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import FlexBox from '../flex-box/flex-box';
import classes from './dob-input.styles.module.scss';
import moment from 'moment';
import clsx from 'clsx';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const DobInput = props => {
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');

    const formatDate = () => {
        if (year != '' && month != '' && day != '') {
            const dateFormatted = `${year}-${formatKey(month)}-${formatKey(day)}`;
            var date = moment(dateFormatted, 'YYYY-MM-DD', true);
            if (date.isValid()) {
                return dateFormatted; //api requires string
            }
        }
        return null;
    };

    useEffect(() => {
        props.onChange(formatDate());
    }, [year, month, day]);

    const inputLabel = React.useRef(null);

    const onMonthChange = e => {
        setMonth(e.target.value);
    };
    const onYearChange = e => {
        setYear(e.target.value);
    };
    const onDayChange = e => {
        setDay(e.target.value);
    };

    const formatKey = number => {
        if (number.length < 2) {
            return `0${number.toString()}`;
        }
        return number.toString();
    };

    let monthsRendered = months.map((month, index) => {
        return (
            <option key={index} value={index + 1}>
                {month}
            </option>
        );
    });

    let daysRenedered = [...Array(31)].map((day, index) => {
        return (
            <option key={index} value={index + 1}>
                {index + 1}
            </option>
        );
    });

    const currentYear = moment().year();

    const yearsRendered = [...Array(82)].map((year, index) => {
        const val = currentYear - 18 - index;
        return (
            <option key={index} value={val}>
                {val}
            </option>
        );
    });

    return (
        <FlexBox aligncenter row className={classes.container}>
            <FormControl
                variant="outlined"
                className={clsx(classes.formControl, classes.monthFormControl)}
            >
                <InputLabel ref={inputLabel} htmlFor="month">
                    Month
                </InputLabel>
                <Select
                    native
                    value={month}
                    onChange={onMonthChange}
                    labelWidth={100}
                    className={clsx(classes.formControl, classes.monthFormControl)}
                    inputProps={{
                        name: 'month',
                        id: 'month',
                    }}
                >
                    <option value={''}></option>
                    {monthsRendered}
                </Select>
            </FormControl>
            <FormControl variant="outlined" className={clsx(classes.formControl)}>
                <InputLabel ref={inputLabel} htmlFor="day">
                    Day
                </InputLabel>
                <Select
                    // className={classes.formControl}
                    native
                    value={day}
                    onChange={onDayChange}
                    labelWidth={90}
                    inputProps={{
                        name: 'day',
                        id: 'day',
                    }}
                >
                    <option value={''}></option>
                    {daysRenedered}
                </Select>
            </FormControl>
            <FormControl variant="outlined" className={clsx(classes.formControl, 'mr-0')}>
                <InputLabel ref={inputLabel} htmlFor="year">
                    Year
                </InputLabel>
                <Select
                    native
                    value={year}
                    // className={classes.formControl}
                    onChange={onYearChange}
                    labelWidth={90}
                    inputProps={{
                        name: 'year',
                        id: 'year',
                    }}
                >
                    <option value={''}></option>
                    {yearsRendered}
                </Select>
            </FormControl>
        </FlexBox>
    );
};
