import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useLocation, useHistory, useParams } from 'react-router-dom';
import { RouteGuard } from '@/shared/route-guard';
// This ScrollMemory becomes the ScrollRestoration beneath it going to rect-router-dom v6.
import ScrollMemory from 'react-router-scroll-memory';
// import { ScrollRestoration } from "react-router-dom";
import AccountRoutes from '@/shared/routes/account.routes';
import GuestRoutes from '@/shared/routes/guest.routes';
import VideoRoutes from './routes/video.routes';
import ArticleRoutes from './routes/article.routes';
import AuthorRoutes from './routes/author.routes';
import CourseRoutes from './routes/course.routes';
import ResourceRoutes from './routes/resource.routes';
import TriviaRoutes from './routes/trivia.routes';
import { Exception } from '@/shared/exception';
import { Toast } from '@/shared/toast';
import { NewsletterSignup } from '../newsletter-signup';
import { MainLayout } from '../layouts';
import { CreateAccount } from '../create-account';
import { Library } from '../library';
import { Search } from '../search';
import { Home } from '../home';
import { Page } from '../page';
import helpers from '@/shared/util/helpers';
import history from '@/shared/history';
import queryString from 'query-string';
import { app_url } from 'config';
import { Redirection } from '@/shared/redirection';
import { ErrorBoundary } from '@/shared/error-boundary';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useHistory();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

export const Navigation = props => {
    const [handshake, setHandshake] = useState(false);

    useEffect(() => {
        const webVersion = helpers.getWebVersion();

        if (props.webVersion && props.webVersion !== webVersion) {
            props.resetStore();
        }

        props.getHandshake().then(() => {
            setHandshake(true);
            props.updateWebVersion(helpers.getWebVersion());
        });
    }, []);

    if (!handshake) {
        return null;
    }

    return (
        <ErrorBoundary>
            <BrowserRouter {...props} history={history}>
                <Redirection></Redirection>
                <Exception></Exception>
                <Toast></Toast>
                <MainRoutesWithRouter {...props} />
            </BrowserRouter>
        </ErrorBoundary>
    );
};

const MainRoutes = props => {
    const { token } = props;
    // Signup is only through CM for now...
    if (window.location.pathname == '/signup') {
        window.location = `${app_url}/signup`;
        return null;
    }

    helpers.hideLoader();

    const canShowNewsletter = () => {
        if (token.global_auths?.has_cm_user) return false;
        if (window.Renderfy) return false;
        return !/^\/(signin|signup|account)/.test(window.location.pathname);
    };

    return (
        <Fragment>
            <ScrollMemory />
            {false && canShowNewsletter() && <NewsletterSignup {...props} />}
            <Switch>
                <RouteGuard path={['/signin', '/signup', '/pwdreset']} guards={['guest']}>
                    <GuestRoutes
                        layout={MainLayout}
                        signinHeading="Sign In to CatholicMatch+"
                        signupHeading="Sign Up for CatholicMatch+"
                        {...props}
                    ></GuestRoutes>
                </RouteGuard>

                <RouteGuard path={['/createaccount']} guards={['profileIncomplete']}>
                    <CreateAccount {...props}></CreateAccount>
                </RouteGuard>

                <RouteGuard path="/account" guards={['user']}>
                    <AccountRoutes {...props} layout={MainLayout}></AccountRoutes>
                </RouteGuard>

                <RouteGuard path="/library/:type?" exact guards={['user', 'statusOkay']}>
                    <MainLayout footerFixed={true}>
                        <Library {...props}></Library>
                    </MainLayout>
                </RouteGuard>

                <Route path="/author">
                    <AuthorRoutes {...props}></AuthorRoutes>
                </Route>

                <Route path="/articles">
                    <ArticleRoutes {...props}></ArticleRoutes>
                </Route>

                <Route path="/courses">
                    <CourseRoutes {...props}></CourseRoutes>
                </Route>

                <Route path="/trivia">
                    <TriviaRoutes {...props}></TriviaRoutes>
                </Route>

                <Route path="/videos">
                    <VideoRoutes {...props}></VideoRoutes>
                </Route>

                <Route path="/resources">
                    <ResourceRoutes {...props}></ResourceRoutes>
                </Route>

                <Route exact path={'/temperaments(.*)'}>
                    <MainLayout fullWidth={true}>
                        <Route
                            path="/temperaments(/?):subpages(.*)"
                            render={props => {
                                const subpages = props.match.params.subpages;
                                const path = `/temperaments/${subpages}`;
                                return <Page key={subpages} path={path} {...props}></Page>;
                            }}
                        />
                    </MainLayout>
                </Route>

                <Route path="/">
                    <MainLayout footerFixed={false}>
                        <Route
                            path={['/', '/home']}
                            exact
                            render={props => {
                                const { s } = queryString.parse(props.location.search);
                                if (s != null) {
                                    return <Search queryTerm={s} {...props}></Search>;
                                }
                                // Phase 1 - Temporary
                                //return <Redirect to="/articles" />;
                                return <Home {...props}></Home>;
                            }}
                        />
                    </MainLayout>
                </Route>

                <Route
                    render={() => {
                        //throw sentry error
                        window.Sentry.captureException(
                            new Error(
                                `Catch all for Plus Routes. We don't know how you got here: ${window.location.pathname}`
                            )
                        );
                        return null;
                    }}
                />
            </Switch>
        </Fragment>
    );
};

const MainRoutesWithRouter = withRouter(MainRoutes);
