import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import helpers from '@/shared/util/helpers';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@/shared/styles/tabs.styles';
import { TabPanel } from '@/shared/components/tab-panel/tab-panel.component';
import history from '@/shared/history';
import { Articles } from './articles';
import { Videos } from './videos';
//import { Events } from './events';
import { Courses } from './courses';

export const Library = ({ type }) => {
    const [tabValue, setTabValue] = useState(0);

    // Set type for initial pageload tab
    const match = window.location.pathname.match(/\/library\/(.*)/);
    if (match) {
        type = match[1];
    }

    useEffect(() => {
        switch (type) {
            case 'articles':
                setTabValue(0);
                break;
            case 'videos':
                setTabValue(1);
                break;
            case 'courses':
                setTabValue(2);
                break;
            // case 'events':
            //     setTabValue(3);
            //     break;
            // case 'addons':
            //     setTabValue(4);
            //     break;
        }
    }, [type]);
    const { setTitle } = helpers;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Library')}</title>
            </Helmet>
            <Typography variant="h2" className="mt-5">
                Library
            </Typography>

            <React.Fragment>
                <Typography variant="h3" className="mt-4 mb-5">
                    Items you've bookmarked, enrolled or purchased show here...
                </Typography>
                <Tabs
                    value={tabValue}
                    className="mb-4"
                    onChange={(e, newValue) => {
                        setTabValue(newValue);
                        const type = e.currentTarget.getAttribute('type');
                        history.replace(`/library/${type}`);
                    }}
                >
                    <Tab label="Articles" type="articles" />
                    <Tab label="Videos" type="videos" />
                    <Tab label="Courses" type="courses" />
                    {/* <Tab label="Courses" type="courses" />
                    <Tab label="Add Ons" type="addons" /> */}
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <Articles />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Videos />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Courses />
                </TabPanel>
            </React.Fragment>

            {/* <TabPanel value={tabValue} index={2}>
                <Courses />
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                Add Ons
            </TabPanel> */}
        </Fragment>
    );
};
