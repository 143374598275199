import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from '../../layouts/main-layout';
import { Articles } from '../../articles';
import { Category as ArticlesCategory, Article } from '@/shared/articles';

export const ArticleRoutes = () => {
    return (
        <Switch>
            <Route exact path={['/articles', '/articles/category/:category/:tag?']}>
                <MainLayout footerFixed={true}>
                    <Route
                        path="/articles"
                        exact
                        render={props => {
                            return <Articles {...props}></Articles>;
                        }}
                    />
                    <Route
                        exact
                        path="/articles/category/:category/:tag?"
                        render={props => {
                            const slug = props.match.params.category;
                            const tag = props.match.params.tag;
                            return (
                                <ArticlesCategory
                                    key={slug}
                                    slug={slug}
                                    ptag={tag}
                                    {...props}
                                ></ArticlesCategory>
                            );
                        }}
                    />
                </MainLayout>
            </Route>

            <Route path={['/articles/:slug']}>
                <MainLayout fullWidth={true}>
                    <Route
                        exact
                        path="/articles/:slug"
                        render={props => {
                            const slug = props.match.params.slug;
                            return <Article key={slug} slug={slug} {...props}></Article>;
                        }}
                    />
                </MainLayout>
            </Route>
        </Switch>
    );
};

export default ArticleRoutes;
