import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography, Grid } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './trending.styles.module.scss';
import { PostCard, PostGrid } from '@/shared/components';
import clsx from 'clsx';

export const Trending = props => {
    const renderItem = (post, i) => {
        return (
            <FlexBox row key={i}>
                <Typography className={classes.rankText} variant="h5">
                    0{i + 1}
                </Typography>
                <PostCard showAsListItem={true} key={post.id} post={post} />
            </FlexBox>
        );
    };
    return (
        <div className={clsx(classes.container, 'py-m-5')}>
            <Typography variant="subtitle2" className={clsx(classes.title, 'mb-4')}>
                TRENDING ON CATHOLICMATCH+
            </Typography>
            <PostGrid
                renderItem={renderItem}
                showAsListItem={true}
                skeletonCount={6}
                resultsKey="articles"
                {...props}
            />
        </div>
    );
};
