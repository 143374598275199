import '@/shared/util/polyfills';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './plus.module.scss?global';
import { Provider } from 'react-redux';
import { Navigation } from '@/plus/navigation';
import { store } from '@/plus/configureStore';
import { SnackbarProvider } from 'notistack';
// StylesProvider was removed form @mui and not recommended for use in React 18.
// I'm pulling it out to keep the compilation going, need to revisit.
// import { StylesProvider } from '@mui/material/styles';
import { Theme } from '@/shared/theme';
import { Paper } from '@/shared/components/mui/paper';
import CssBaseline from '@mui/material/CssBaseline';
import '@/shared/styles/css-imports';
import pusherReset from '@/shared/util/pusher-reset';

window.isPlus = true;
const isDesktop = document.body.classList.contains('is-desktop');
window.isDesktop = isDesktop;
if (isDesktop) {
    document.body.style.minWidth = '1188px';
}

pusherReset();

const Root = () => {
    return (
        <Theme>
            <SnackbarProvider maxSnack={3}>
                <Paper level={1} elevation={0}>
                    <div id="content-root">
                        <Navigation isDesktop={isDesktop} />
                    </div>
                </Paper>
            </SnackbarProvider>
        </Theme>
    );
};

class Main extends Component {
    render() {
        return (
            <Provider store={store}>
                <CssBaseline />
                <Root></Root>
            </Provider>
        );
    }
}

const root = document.getElementById('react-root');

if (root) {
    ReactDOM.render(<Main />, root);
}
