import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import {
    Typography,
    Container,
    IconButton,
    Icon,
    Checkbox,
    FormControlLabel,
    Button,
    ButtonBase,
    Grid,
    CircularProgress,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './scores.styles.module.scss';
import { RankList } from '../rank-list';
import clsx from 'clsx';
import moment from 'moment';

import { AnimatePresence, motion } from 'framer-motion';

export const Scores = ({
    getScores,
    display_date,
    previous_week_players: pastPlayers,
    current_week_players: currentPlayers,
    answers,
    moreLoaded,
    scoresLoading,
    last_yw,
    current_yw,
    display_prev_yw,
    display_next_yw,
}) => {
    const [showCurrent, setShowCurrent] = useState(null);
    useEffect(() => {
        if (answers && showCurrent == null) {
            setShowCurrent(answers.count > 0);
        }
    }, [answers]);

    const next = () => {
        getScores(display_next_yw);
    };

    const prev = () => {
        getScores(display_prev_yw);
    };

    const loadMore = () => {
        if (!moreLoaded) {
            getScores(last_yw);
        }
    };

    if ((showCurrent && !currentPlayers) || (!showCurrent && !pastPlayers)) {
        return <Skeleton variant="rect" width={'100%'} height={500} />;
    }
    const showPastViewAll = pastPlayers && !moreLoaded && !scoresLoading;
    const showCurrentViewAll = currentPlayers && !moreLoaded && !scoresLoading;

    const weekText = moment(display_date).format('MMM Do');

    return (
        <FlexBox justifycenter>
            <FlexBox aligncenter row className="mb-4">
                <ButtonBase
                    className={clsx('mr-4', classes.tab, showCurrent && classes.activeTab)}
                    onClick={() => setShowCurrent(true)}
                >
                    <Typography className={classes.title} variant="h6">
                        Current Week
                    </Typography>
                </ButtonBase>
                <ButtonBase
                    className={clsx(classes.tab, 'mr-4', !showCurrent && classes.activeTab)}
                    onClick={() => setShowCurrent(false)}
                >
                    <Typography className={classes.title} variant="h6">
                        Previous Week
                    </Typography>
                </ButtonBase>
            </FlexBox>
            {showCurrent ? (
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    key={display_date}
                >
                    <RankList users={currentPlayers}></RankList>
                    {showCurrentViewAll && (
                        <FlexBox justifycenter aligncenter className="mt-3">
                            <Button onClick={loadMore} color="primary">
                                View All
                            </Button>
                        </FlexBox>
                    )}
                    {scoresLoading && (
                        <FlexBox justifycenter aligncenter className="mt-3">
                            <CircularProgress></CircularProgress>
                        </FlexBox>
                    )}
                </motion.div>
            ) : (
                <FlexBox justifyend>
                    <FlexBox
                        row
                        aligncenter
                        style={{ marginTop: -55, marginBottom: 35 }}
                        className="ml-auto"
                    >
                        <IconButton className="p-1" onClick={prev}>
                            <Icon className={clsx(classes.icon, 'fas fa-chevron-left')} />
                        </IconButton>
                        <Typography
                            className={classes.weekText}
                            color="textSecondary"
                            variant="subtitle2"
                        >
                            {weekText}
                        </Typography>
                        <IconButton
                            className={clsx(classes.nextBtn, 'p-1')}
                            onClick={next}
                            disabled={current_yw == display_next_yw}
                        >
                            <Icon className={clsx(classes.icon, 'fas fa-chevron-right')} />
                        </IconButton>
                    </FlexBox>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            key={weekText}
                        >
                            <RankList startIndex={3} users={pastPlayers}></RankList>
                            {showPastViewAll && (
                                <FlexBox justifycenter aligncenter className="mt-3">
                                    <Button onClick={loadMore} color="primary">
                                        View All
                                    </Button>
                                </FlexBox>
                            )}
                            {scoresLoading && (
                                <FlexBox justifycenter aligncenter className="mt-3">
                                    <CircularProgress></CircularProgress>
                                </FlexBox>
                            )}
                        </motion.div>
                    </AnimatePresence>
                </FlexBox>
            )}
        </FlexBox>
    );
};
