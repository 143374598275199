import { connect } from 'react-redux';
import { CreateAccount as CreateAccountComponent } from './create-account.component';
import { getIncomplete, postAnswer } from './create-account.actions.js';

const mapStateToProps = state => {
    return state.createAccountReducer;
};

export const CreateAccount = connect(mapStateToProps, {
    getIncomplete,
    postAnswer,
})(CreateAccountComponent);
