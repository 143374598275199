import { connect } from 'react-redux';
import { Play as PlayComponent } from './play.component';
import { getTrivia, getQuestion, getScores, clearQuestion } from '../trivia.actions.js';

const mapStateToProps = state => {
    return state.triviaReducer;
};

export const Play = connect(mapStateToProps, {
    getTrivia,
    getQuestion,
    getScores,
    clearQuestion,
})(PlayComponent);
