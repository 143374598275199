import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import helpers from '@/shared/util/helpers';
import {
    Button,
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import FlexBox from '@/shared/components/flex-box/flex-box';

export const Signin = ({ authenticateUser, heading, resetStore, ...props }) => {
    const [request, setRequest] = useState({
        email: '',
        password: '',
        stay_signed_in: true,
    });

    useEffect(() => {
        resetStore();
    }, []);

    const buttonRef = useRef(null);

    const handleChange = event => {
        setRequest({
            ...request,
            [event.target.name]:
                event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        });
    };

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            signin();
            buttonRef.current.focus();
        }
    };

    const signin = () => {
        let theRequest = request;

        authenticateUser(theRequest);
    };

    const { setTitle } = helpers;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Sign In')}</title>
            </Helmet>
            <Container maxWidth="xs" className="py-5">
                <FlexBox column aligncenter w100>
                    <Typography align="center" variant="h2" component="h1" className="mb-m-4 mb-5">
                        {heading}
                    </Typography>
                    <TextField
                        className={'w-100'}
                        variant="outlined"
                        name="email"
                        label="Email Address"
                        value={request.email}
                        onChange={handleChange}
                        autoComplete="on"
                        inputProps={{
                            autoCapitalize: 'none',
                            autoComplete: 'on',
                            autoCorrect: 'off',
                        }}
                        onKeyPress={handleEnterPress}
                    />
                    <TextField
                        className="mt-3 w-100"
                        variant="outlined"
                        label="Password"
                        type="password"
                        name="password"
                        value={request.password}
                        onChange={handleChange}
                        inputProps={{
                            autoCapitalize: 'none',
                            autoComplete: 'off',
                            autoCorrect: 'off',
                        }}
                        onKeyPress={handleEnterPress}
                    />
                    <FormControlLabel
                        className="w-100"
                        control={
                            <Checkbox
                                checked={request.stay_signed_in}
                                name="stay_signed_in"
                                onChange={handleChange}
                                color="primary"
                                onKeyPress={handleEnterPress}
                            />
                        }
                        label="Stay signed in"
                    />

                    <Button
                        className="mt-3 w-100"
                        variant="contained"
                        color="primary"
                        size="large"
                        disableRipple
                        ref={buttonRef}
                        onClick={signin}
                    >
                        Sign In
                    </Button>
                    <Link className="mt-4" to="/signin/forgot_password">
                        <Typography color="textSecondary" variant="subtitle2">
                            Forgot Password?{' '}
                        </Typography>
                    </Link>

                    <Typography color="textSecondary" className="mt-4" variant="subtitle2">
                        Don't have an account?{' '}
                        <Link to="/signup">
                            <Typography color="primary" variant="subtitle2" component="span">
                                Sign Up
                            </Typography>
                        </Link>
                    </Typography>
                </FlexBox>
            </Container>
        </Fragment>
    );
};
