import React, { Fragment, useEffect, useState } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { Avatar, Typography, Container } from '@mui/material';
import { Skeleton } from '@mui/material';
import { PostGrid, Section } from '@/shared/components';
import FlexBox from '@/shared/components/flex-box/flex-box';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import classes from './author.styles.module.scss';
import { get, has, isEqual } from 'lodash';
import clsx from 'clsx';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

const renderAuthorSkeleton = () => {
    return (
        <Fragment>
            <Container
                disableGutters
                maxWidth="sm"
                className={clsx('ml-0 mr-5 mr-m-0', classes.authorContent)}
            >
                <Skeleton variant="rect" className="mb-4" height={50}></Skeleton>
                <Skeleton variant="rect" height={200}></Skeleton>
            </Container>
            <Skeleton variant="circle" className={clsx('mb-m-4', classes.photo)}></Skeleton>
        </Fragment>
    );
};

const renderAuthor = author => {
    const photo = get(author, 'photo.medium.url');
    return (
        <Fragment>
            <Container
                disableGutters
                maxWidth="sm"
                className={clsx('ml-0 mr-5 mr-m-0', classes.authorContent)}
            >
                <Typography variant="h1" className="mb-4">
                    {author.name}
                </Typography>
                <Typography variant="body1" component="div">
                    <SafeHtml>{author.bio}</SafeHtml>
                </Typography>
            </Container>
            <Avatar alt={author.name} src={photo} className={clsx('mb-m-4', classes.photo)} />
        </Fragment>
    );
};

export const Author = props => {
    const [canShow, setCanShow] = useState(false);

    useEffect(() => {
        const authorHasChanged =
            has(props, 'author.slug') && !isEqual(props.author.slug, props.slug);

        const qparams = queryString.parse(props.location.search);
        let page = get(qparams, 'page', 1);

        if (props.total == 0 || authorHasChanged) {
            if (!window.Renderfy) page = 1;
            props.getResults(props.slug, page, qparams);
        } else {
            setCanShow(true);
        }
    }, [props.slug]);

    useDidUpdateEffect(() => {
        setCanShow(true);
    }, [props.loaded]);

    return (
        <Fragment>
            <Helmet>
                <title>{props.author.name}</title>
            </Helmet>

            <Section bgcolor={window.isDesktop && 'background000'} className="pb-m-0">
                <FlexBox justifystart={window.isDesktop} row={window.isDesktop}>
                    {(canShow && renderAuthor(props.author)) || renderAuthorSkeleton()}
                </FlexBox>
            </Section>
            <Section>
                <PostGrid showAsListItem={!window.isDesktop} resultsKey="posts" {...props} />
            </Section>
        </Fragment>
    );
};
