import { connect } from 'react-redux';
import { Page as PageComponent } from './page.component';
import { getResult } from './page.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.pageReducer;
};

export const Page = connect(mapStateToProps, {
    getResult,
    expireStore,
})(PageComponent);
