import { connect } from 'react-redux';
import { Signin as SigninComponent } from './signin.component';
import { authenticateUser } from './signin.actions';
import { resetStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return {
        ...state.signinReducer,
        token: state.authReducer.token,
    };
};

export const Signin = connect(mapStateToProps, { authenticateUser, resetStore })(SigninComponent);
