import React, { Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './main-nav.styles.module.scss';
import history from '@/shared/history';
import { useWhiteLogo } from '@/shared/util/hooks';
import FlexBox from '@/shared/components/flex-box/flex-box';
import clsx from 'clsx';
import { AppBar, Toolbar, IconButton, Drawer, Button, Icon, Link as MuiLink } from '@mui/material';
import { Dropdown } from '@/shared/components/dropdown/dropdown';
import { Search } from './search';
import { app_url, wp_url, logo_white_plus, logo_blue_plus } from 'config';

export const MainNav = ({ token, onlyLogo, signout }) => {
    const user = token?.user;
    const isWhiteLogo = useWhiteLogo();
    const [openDrawer, setOpenDrawer] = useState(false);

    const menuLinks = [
        { title: 'Articles', to: '/articles' },
        { title: 'Videos', to: '/videos' },
        { title: 'Courses', to: '/courses' },
        { title: 'Trivia', to: '/trivia' },
        { title: 'Singles', href: app_url },
        ...(!window.isDesktop ? [{ title: 'Search', to: '/?s=' }] : []),
        ...(!window.isDesktop && !user ? [{ title: 'Sign In', to: '/signin' }] : []),
    ];

    useEffect(() => {
        setOpenDrawer(false);
    }, [history.location.pathname]);

    const accountOptions = [
        {
            display: 'Account',
            href: `${app_url}/account`,
        },
        {
            display: 'Settings',
            href: `${app_url}/account/settings`,
        },
        {
            display: 'Library',
            link: '/library',
        },
        {
            display: 'Help',
            href: `${app_url}/help`,
        },
        ...(user && user.auths.is_admin
            ? [
                  {
                      display: 'CM Admin',
                      href: `${app_url}/admin`,
                  },
                  {
                      display: 'WP Admin',
                      href: `${wp_url}/wp-admin`,
                  },
              ]
            : []),
    ];

    const loggedOutOption = [
        {
            display: 'Sign In',
            link: '/signin',
        },
        {
            display: 'Help',
            href: `${app_url}/help`,
        },
    ];

    const accountSecondOptions = [
        {
            display: 'Sign Out',
            action: signout,
        },
    ];

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const Menu = (
        <FlexBox aligncenter row={window.isDesktop} className={classes.navLinks}>
            {menuLinks.map(link => {
                if (link.to) {
                    return (
                        <NavLink
                            onClick={() => {
                                !window.isDesktop && toggleDrawer();
                            }}
                            className={clsx(classes.link, 'p-3', 'mr-2')}
                            key={link.title}
                            to={link.to}
                        >
                            {link.title}
                        </NavLink>
                    );
                } else if (link.action) {
                    return (
                        <Button
                            onClick={() => {
                                !window.isDesktop && toggleDrawer();
                                link.action();
                            }}
                            className={clsx(classes.link, 'p-3', 'mr-2')}
                            key={link.title}
                        >
                            {link.title}
                        </Button>
                    );
                } else if (link.href) {
                    return (
                        <a
                            onClick={() => {
                                !window.isDesktop && toggleDrawer();
                            }}
                            className={clsx(classes.link, 'p-3', 'mr-2')}
                            key={link.title}
                            href={link.href}
                        >
                            {link.title}
                        </a>
                    );
                }
            })}
        </FlexBox>
    );

    const logo = isWhiteLogo ? logo_white_plus : logo_blue_plus;

    return (
        <AppBar elevation={0} position={'sticky'} color="inherit" classes={{ root: classes.appBarRoot }}>
            <Toolbar className={classes.container}>
                <FlexBox row aligncenter justifybetween w100>
                    <FlexBox row aligncenter>
                        {!onlyLogo && !window.isDesktop && (
                            <IconButton
                                edge="start"
                                className={'mr-3 ml-1'}
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer}
                            >
                                <Icon className={clsx(classes.icon, 'fas fa-bars')} />
                            </IconButton>
                        )}
                        <NavLink
                            style={{ width: window.isDesktop ? 194 : 'auto' }}
                            key="logo"
                            to="/"
                            className={clsx(classes.logo, classes.link, 'mr-5 mr-m-2')}
                        >
                            <img height={window.isDesktop ? 24 : 22} src={logo} alt="CatholicMatch Home" />
                        </NavLink>
                    </FlexBox>
                    {!onlyLogo && window.isDesktop && Menu}
                    {!onlyLogo && (
                        <FlexBox aligncenter row className={clsx(classes.utilMenu, 'ml-2')}>
                            {window.isDesktop && <Search></Search>}
                            {(window.isDesktop || user) && (
                                <Fragment>
                                    <Dropdown
                                        placement="bottom-end"
                                        options={user ? accountOptions : loggedOutOption}
                                        secondOptions={user && accountSecondOptions}
                                        popperStyle={{ minWidth: 150 }}
                                        style={{ maxWidth: 70 }}
                                    >
                                        <IconButton
                                            aria-label="account"
                                            className={clsx('ml-3 ml-m-2', classes.accountLink)}
                                            color="inherit"
                                        >
                                            <FlexBox row aligncenter>
                                                <Icon className={clsx(classes.icon, 'fas fa-user-circle')}></Icon>
                                                <Icon
                                                    className={clsx(classes.accountChevron, 'ml-2 fas fa-chevron-down')}
                                                ></Icon>
                                            </FlexBox>
                                        </IconButton>
                                    </Dropdown>
                                </Fragment>
                            )}
                            {!user && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={clsx('ml-4 ml-m-2', classes.tryBtn)}
                                    component={MuiLink}
                                    href={`${app_url}/signup`}
                                >
                                    Try for Free
                                </Button>
                            )}
                        </FlexBox>
                    )}
                </FlexBox>
            </Toolbar>
            <Drawer classes={{ paper: classes.drawer }} anchor={'left'} open={openDrawer} onClose={toggleDrawer}>
                <FlexBox h100 w100 justifycenter className="p-4">
                    {Menu}
                </FlexBox>
            </Drawer>
        </AppBar>
    );
};
