import React, { Fragment } from 'react';
import { Avatar, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './post-info.styles.module.scss';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    PinterestShareButton,
    PinterestIcon,
} from 'react-share';
import { get } from 'lodash';

export const PostInfoSkeleton = ({
    showSocial,
    showDate,
    className,
    smallAvatar,
    showAvatar = true,
    showName = true,
}) => {
    return (
        <FlexBox aligncenter row className={className}>
            {showAvatar && (
                <Skeleton
                    variant="circle"
                    className={clsx(classes.avatar, smallAvatar && classes.avatar__small, 'mr-3')}
                ></Skeleton>
            )}
            <FlexBox row={window.isDesktop}>
                {showName && <Skeleton className="mr-3" width={240}></Skeleton>}
                {showDate && <Skeleton className="mr-3" width={180}></Skeleton>}
                {showSocial && <Skeleton width={100}></Skeleton>}
            </FlexBox>
        </FlexBox>
    );
};

export const PostInfo = ({
    post,
    showCategory,
    showDate,
    showSocial,
    noLinks,
    className,
    showAvatar = true,
    showName = true,
    caption,
    smallAvatar,
}) => {
    let person =
        post.type == 'sfwd-courses'
            ? { name: post.instructor_name, photo: post.instructor_photo.url }
            : {
                name: post.author.name,
                slug: post.author.slug,
                photo: post.author.photo.thumbnail.url,
            };

    const photo = get(post, 'photos.large.url');

    const hasLessons = post.lesson_count != undefined || post.lesson_count > 0;
    return (
        <FlexBox wrap row aligncenter className={className}>
            {showAvatar && (
                <Avatar
                    alt={person.name}
                    src={person.photo}
                    className={clsx(classes.avatar, smallAvatar && classes.avatar__small, 'mr-3')}
                />
            )}
            <FlexBox
                flex1={window.isDesktop ? false : true}
                column={!window.isDesktop}
                row={window.isDesktop}
                aligncenter={window.isDesktop}
            >
                <div>
                    {showName && (
                        <React.Fragment>
                            {noLinks ? (
                                <Typography noWrap variant={caption ? 'caption' : 'subtitle1'}>
                                    by {person.name}
                                </Typography>
                            ) : (
                                <Typography variant={caption ? 'caption' : 'subtitle1'}>
                                    <Link className={classes.text} to={`/author/${person.slug}`}>
                                        {person.name}
                                    </Link>{' '}
                                    {showCategory && post.category && (
                                        <Fragment>
                                            in{' '}
                                            <Link
                                                className={classes.text}
                                                to={`/articles/category/${post.category.slug}`}
                                            >
                                                {post.category.name}
                                            </Link>
                                        </Fragment>
                                    )}
                                </Typography>
                            )}
                        </React.Fragment>
                    )}
                    {hasLessons && (
                        <Typography noWrap variant="caption" color="textSecondary">
                            {post.lesson_count} lessons
                        </Typography>
                    )}
                </div>

                {showDate && (
                    <Fragment>
                        {window.isDesktop && showName && <div className={classes.divider}></div>}
                        <Typography
                            className="mt-m-1"
                            variant={!window.isDesktop || caption ? 'caption' : 'subtitle1'}
                            color={window.isDesktop ? 'textPrimary' : 'textSecondary'}
                        >
                            {post.date}
                        </Typography>
                    </Fragment>
                )}
            </FlexBox>
            {showSocial && (
                <Fragment>
                    {(showDate || showName) && <div className={classes.divider}></div>}
                    <Typography
                        variant={caption ? 'caption' : 'subtitle1'}
                        style={{ fontSize: '1rem' }}
                    >
                        <FacebookShareButton url={window.location.href}>
                            <FacebookIcon size={24} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton className="ml-3" url={window.location.href}>
                            <XIcon size={24} round={true} />
                        </TwitterShareButton>
                        {photo && (
                            <PinterestShareButton
                                className="ml-3"
                                media={photo}
                                url={window.location.href}
                                description={post.excerpt}
                            >
                                <PinterestIcon size={24} round={true} />
                            </PinterestShareButton>
                        )}
                    </Typography>
                </Fragment>
            )}
        </FlexBox>
    );
};
