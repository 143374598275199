import { connect } from 'react-redux';
import { UserProgress as UserProgressComponent } from './user-progress.component';
//import { sampleAction } from './user-progress.actions.js';

const mapStateToProps = state => {
    return { ...state.triviaReducer, token: state.authReducer.token };
};

export const UserProgress = connect(mapStateToProps, {
    //sampleAction,
})(UserProgressComponent);
