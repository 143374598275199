import React, { Fragment } from 'react';
import { Footer } from '../footer';
import { MainNav } from '../main-nav';
import classes from './main-layout.styles.module.scss';
import FlexBox from '@/shared/components/flex-box/flex-box';

export const MainLayout = ({ fullWidth, onlyLogo, hideFooter, footerFixed, children }) => {
    const layoutClass = fullWidth ? 'full' : 'main';

    return (
        <Fragment>
            <FlexBox justifybetween>
                <MainNav onlyLogo={onlyLogo}></MainNav>
                <FlexBox className={classes[layoutClass]}>{children}</FlexBox>
                {!hideFooter && <Footer fixed={footerFixed}></Footer>}
            </FlexBox>
        </Fragment>
    );
};
