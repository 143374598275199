import React, { Fragment, useState, useEffect } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { Post } from '@/shared/articles';

export const Article = ({ slug, getResult, loaded, ...props }) => {
    const [canShow, setCanShow] = useState(false);

    useEffect(() => {
        getResult(slug);
    }, [slug]);

    useDidUpdateEffect(() => {
        setCanShow(true);
    }, [loaded]);
    return (
        <Fragment>
            <Post {...props} loaded={canShow}></Post>
        </Fragment>
    );
};
