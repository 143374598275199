import React, { useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import helpers from '@/shared/util/helpers';
import { useInput } from '@/shared/util/hooks';
import { Button, TextField, Typography, Container, CircularProgress } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import history from '@/shared/history';

export const ResetPassword = props => {
    const { bind: password } = useInput('');
    const { bind: password_confirmation } = useInput('');

    const { setTitle } = helpers;

    useEffect(() => {
        props.validateReset(props.resetToken, props.userId);
    }, [props.resetToken, props.userId]);

    const request = {
        id: props.userId,
        password: password.value,
        password_confirmation: password_confirmation.value,
        token: props.resetToken,
    };

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            resetPassword();
        }
    };

    const resetPassword = () => {
        props.resetPassword(request);
    };

    const renderValidating = () => {
        return (
            <Container maxWidth="sm" className="py-5 align-center">
                <FlexBox aligncenter>
                    <CircularProgress className="mx-auto"></CircularProgress>
                </FlexBox>
            </Container>
        );
    };

    const renderValidationSuccess = () => {
        return (
            <Container maxWidth="xs" className="py-5 align-center">
                <Typography align="center" variant="h2" component="h1" className="mb-m-4 mb-5">
                    Reset your password
                </Typography>
                <TextField
                    {...password}
                    className="mt-3 w-100"
                    type="password"
                    variant="outlined"
                    name="password"
                    label="Password"
                    inputProps={{
                        autoCapitalize: 'none',
                        autoComplete: 'off',
                        autoCorrect: 'off',
                    }}
                    onKeyPress={handleEnterPress}
                />
                <TextField
                    className="mt-3 w-100"
                    variant="outlined"
                    {...password_confirmation}
                    type="password"
                    name="password_confirmation"
                    label="Confirm Password"
                    inputProps={{
                        autoCapitalize: 'none',
                        autoComplete: 'off',
                        autoCorrect: 'off',
                    }}
                    onKeyPress={handleEnterPress}
                />
                <Button
                    className="mt-3 ml-auto"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={resetPassword}
                >
                    Reset Password
                </Button>
            </Container>
        );
    };

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Password Reset')}</title>
            </Helmet>
            {((props.isValidating || !props.validationSuccess) && renderValidating()) ||
                renderValidationSuccess()}
        </Fragment>
    );
};
