import React, { useEffect, useState, useRef } from 'react';
import { FilledInput, IconButton, InputAdornment } from '@mui/material';
import classes from './search.styles.module.scss';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

export const Search = () => {
    const [searchActive, setSearchActive] = useState(false);
    const inputRef = useRef(null);
    const searchBtn = useRef(null);
    const history = useHistory();

    const handleEnterPress = e => {
        const code = e.keyCode || e.which;
        if (code === 13 && e.target.value.length > 2) {
            inputRef.current.blur();
            e.preventDefault();
            setSearchActive(false);
            history.push(`/?s=${e.target.value}`);
        }
    };

    useEffect(() => {
        if (searchActive && inputRef && inputRef.current) {
            inputRef.current.focus();
        } else if (inputRef && inputRef.current) {
            inputRef.current.value = '';
        }
    }, [searchActive]);

    if (!searchActive) {
        return (
            <IconButton
                onClick={() => {
                    setSearchActive(true);
                }}
                size={searchActive ? 'small' : 'medium'}
                disabled={searchActive}
            >
                <i className={clsx(classes.icon, 'fa  fa-search', searchActive && 'fa-sm')}></i>
            </IconButton>
        );
    }
    return (
        <div className={window.isDesktop && 'ml-auto'} style={{ position: 'relative', minWidth: 43 }}>
            <div className={clsx(window.isDesktop ? classes.open : classes.searchContainerFixed)}>
                <FilledInput
                    name="term"
                    ref={inputRef}
                    className={clsx(classes.searchInput, !searchActive && classes.searchInputHidden)}
                    disableUnderline={true}
                    id="term-input"
                    placeholder="Search..."
                    autoComplete="off"
                    onKeyPress={handleEnterPress}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                ref={searchBtn}
                                onClick={() => {
                                    setSearchActive(true);
                                }}
                                size={searchActive ? 'small' : 'medium'}
                                disabled={searchActive}
                                edge="end"
                            >
                                <i className={clsx(classes.icon, 'fa  fa-search', searchActive && 'fa-sm')}></i>
                            </IconButton>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle search visibility"
                                //  edge="end"
                                onClick={() => {
                                    setSearchActive(false);
                                }}
                            >
                                <i style={{ fontSize: '12px' }} className="fas fa-times"></i>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </div>
        </div>
    );
};
