import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteGuard } from '@/shared/route-guard';
import { get } from 'lodash';
import {
    Orders,
    Order,
    Settings,
    Notifications,
    Payment,
    Account,
    BadEmail,
    BasicInformation,
    CurrentSubscription,
    ProfileStatus,
    Security,
    AccountRecovery,
} from '@/shared/account';

import { Menu as AccountMenu } from '@/shared/account/menu';

export const AccountRoutes = ({ token, layout: MainLayout }) => {
    const ctabar = get(token, 'promo.ctabar');
    return (
        <Routes>
            <RouteGuard
                guards={['statusDeleted']}
                path={'/account/undelete'}
                exact
                render={props => {
                    return (
                        <MainLayout cta={null} logoOnly>
                            <AccountRecovery {...props} />;
                        </MainLayout>
                    );
                }}
            ></RouteGuard>
            <RouteGuard guards={['statusOkay']} path={'/account'}>
                <MainLayout cta={ctabar} hideTabs={!window.isDesktop}>
                    <Account>
                        <Switch>
                            <Route path={'/account/remove'} exact>
                                <Redirect to="/account/contact"></Redirect>
                            </Route>
                            <Route path={'/account'} exact>
                                {window.isDesktop ? (
                                    <Redirect to="/account/contact"></Redirect>
                                ) : (
                                    <AccountMenu />
                                )}
                            </Route>

                            <Route
                                path={'/account/contact'}
                                exact
                                render={props => {
                                    return <BasicInformation {...props} />;
                                }}
                            ></Route>

                            <Route
                                path={'/account/payment'}
                                exact
                                render={props => {
                                    return <Payment {...props}></Payment>;
                                }}
                            ></Route>
                            <Route
                                path={'/account/settings'}
                                exact
                                render={props => {
                                    return (
                                        <Settings
                                            {...props}
                                            key="settings"
                                            title="Settings"
                                        ></Settings>
                                    );
                                }}
                            ></Route>
                            <Route
                                path={'/account/notifications'}
                                exact
                                render={props => {
                                    return (
                                        <Notifications
                                            {...props}
                                            key="notifications"
                                            title="Notifications"
                                        ></Notifications>
                                    );
                                }}
                            ></Route>
                            <Route
                                path={'/account/services'}
                                exact
                                render={props => {
                                    return <CurrentSubscription {...props}></CurrentSubscription>;
                                }}
                            ></Route>
                            <Route
                                path={'/account/status'}
                                exact
                                render={props => {
                                    return <ProfileStatus {...props}></ProfileStatus>;
                                }}
                            ></Route>
                            <Route
                                path={'/account/orders'}
                                exact
                                render={props => {
                                    return <Orders {...props} />;
                                }}
                            ></Route>
                            <Route
                                path={'/account/orders/:id'}
                                exact
                                render={props => {
                                    return <Order {...props} />;
                                }}
                            ></Route>
                            <Route
                                path={'/account/contact'}
                                exact
                                render={props => {
                                    return <BasicInformation {...props} />;
                                }}
                            ></Route>
                            <Route
                                path={'/account/bademail'}
                                exact
                                render={props => {
                                    return <BadEmail {...props}></BadEmail>;
                                }}
                            ></Route>
                            <Route
                                path={[
                                    '/account/password',
                                    '/account/tfa',
                                    '/account/sessions',
                                    '/account/trusted',
                                ]}
                                render={props => {
                                    return <Security {...props} />;
                                }}
                            ></Route>
                        </Switch>
                    </Account>
                </MainLayout>
            </RouteGuard>
        </Routes>
    );
};

export default AccountRoutes;
