import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './question.styles.module.scss';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { Timer } from '../timer';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { Confetti } from '@/shared/components';
import { Score } from './score';
import { Report } from '../report';
import { QuestionContent } from './question-content';
import { QuestionResult } from './question-result';

const Rules = () => {
    return (
        <FlexBox>
            <Typography align="center" className={clsx('mb-3', classes.gradientText)} variant="h4">
                <strong>Trivia Rules</strong>
            </Typography>
            <Typography variant="body1" className={classes.ruleItem}>
                <strong>You have</strong> several seconds to answer before the timer starts!
            </Typography>
            <Typography variant="body1" className={classes.ruleItem}>
                <strong>After the</strong> timer starts, you have 10 seconds to submit your answer! The faster you answer,
                the more points you get.
            </Typography>
            <Typography variant="body1" className={classes.ruleItem}>
                <strong>You can</strong> answer up to 40 questions every week, from Sunday to Saturday. You can answer them
                all at once or spread it out over the week.
            </Typography>
            <Typography variant="body1" className={classes.ruleItem}>
                <strong>Pit your</strong> skill and knowledge against other members to see who comes out on top. Only one
                member will be named the winner each week!
            </Typography>
        </FlexBox>
    );
};
const Completed = ({ current_answers }) => {
    return (
        <FlexBox justifycenter aligncenter>
            <Typography align="center" className={clsx('mb-3', classes.gradientText)} variant="h4">
                <strong>Final Score</strong>
            </Typography>
            {current_answers?.points && (
                <Typography
                    align="center"
                    className={clsx('mb-3', classes.gradientText)}
                    style={{ fontSize: 60 }}
                    variant="h4"
                >
                    <strong>{current_answers?.points}</strong>
                </Typography>
            )}
            <Typography variant="h5" align="center">
                You've answered all the questions for this week. Come back next week for more and to see how you ranked
                against everyone else!
            </Typography>
            <Confetti fireworks></Confetti>
        </FlexBox>
    );
};

export const Question = ({
    getQuestion,
    answerQuestion,
    question: incomingQuestion,
    answer,
    success,
    error,
    current_answers,
    total_questions,
    completed,
    showRules: incomingShowRules,
    onBack,
}) => {
    const [isAnswered, setIsAnswered] = useState(false);
    const [selected, setSelected] = useState(false);
    const [question, setQuestion] = useState(incomingQuestion);
    const [time, setTime] = useState(10000);
    const [timesUp, setTimesUp] = useState(false);
    const [isFirstQuestion, setIsFirstQuestion] = useState(true);
    const [showRules, setShowRules] = useState(incomingShowRules);
    const [showReport, setShowReport] = useState(false);

    useEffect(() => {
        if (!showRules) {
            getQuestion();
        }
    }, []);
    useDidUpdateEffect(() => {
        setIsAnswered(false);
        setSelected(null);
        setTimesUp(false);
        setQuestion(incomingQuestion);
    }, [incomingQuestion]);

    useDidUpdateEffect(() => {
        setIsAnswered(true);
    }, [success, error]);

    const onAnswer = value => {
        setSelected(value);
        const roundedTime = Math.round(time * 100) / 100;
        answerQuestion(answer.id, {
            guessed_answer: value,
            seconds_remain: Math.min(Math.floor(roundedTime, 10)),
        });
    };

    const getNextQuestion = e => {
        if (e.detail == 1) {
            getQuestion();
            if (isFirstQuestion) {
                setIsFirstQuestion(false);
            }
        }
    };

    if (!question && !showRules && !completed) return null;

    const onTimeCompleted = () => {
        setTimesUp(true);
        setSelected(true);
        answerQuestion(answer.id, { guessed_answer: '', seconds_remain: 0 });
    };
    const onTimeChanged = time => {
        if (!isAnswered) {
            setTime(time);
        }
    };

    const onStart = () => {
        setShowRules(false);
        getQuestion();
    };
    return (
        <FlexBox h100>
            {!completed && !showRules && (
                <FlexBox style={{ maxWidth: 500 }} className="mx-auto px-2 pb-1 mb-3" w100 justifybetween aligncenter row>
                    <Typography color="textSecondary" variant="h5">
                        {current_answers?.count || 0}/{total_questions || 40}
                    </Typography>
                    <Score key={question?.id || 'rules'} score={current_answers?.points}></Score>
                </FlexBox>
            )}
            <FlexBox flex1 style={{ position: 'relative' }}>
                <AnimatePresence>
                    <FlexBox
                        initial={!isFirstQuestion && { opacity: 0, x: window.innerWidth / 2 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -window.innerWidth / 2, position: 'absolute' }}
                        // flex1
                        transition={{ duration: 0.4 }}
                        aligncenter
                        key={question?.id || 'rules'}
                        className={classes.question}
                    >
                        {completed ? (
                            <Completed current_answers={current_answers}></Completed>
                        ) : showRules ? (
                            <Rules></Rules>
                        ) : (
                            <React.Fragment>
                                <FlexBox justifycenter aligncenter style={{ height: 80 }}>
                                    <AnimatePresence exitBeforeEnter>
                                        {!timesUp && selected && isAnswered && (
                                            <QuestionResult isCorrect={selected == question.correct_answer}></QuestionResult>
                                        )}
                                        {!selected && (
                                            <motion.div
                                                key="two"
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 0 }}
                                                transition={
                                                    isAnswered ? { duration: 0.1, delay: 0 } : { duration: 0.3, delay: 0.2 }
                                                }
                                            >
                                                <Timer
                                                    onTimeChanged={onTimeChanged}
                                                    onTimeCompleted={onTimeCompleted}
                                                    key={question.id}
                                                    buffer={question.buffer}
                                                ></Timer>
                                            </motion.div>
                                        )}
                                        {timesUp && (
                                            <motion.div
                                                key="three"
                                                initial={{ opacity: 0, scale: 0 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 0 }}
                                                transition={{ duration: 0.1, delay: 0 }}
                                            >
                                                <Typography variant="h4" color="secondary">
                                                    <strong>TIMES UP!!!</strong>
                                                </Typography>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </FlexBox>
                                <QuestionContent
                                    showAnswer={isAnswered}
                                    question={question}
                                    onAnswer={onAnswer}
                                ></QuestionContent>
                            </React.Fragment>
                        )}
                    </FlexBox>
                </AnimatePresence>
            </FlexBox>
            <AnimatePresence>
                {(isAnswered || showRules || completed) && (
                    <FlexBox
                        initial={{ y: 140 }}
                        animate={{ y: 0 }}
                        exit={{ y: 140 }}
                        transition={{ delay: isAnswered ? 0.4 : 0, duration: 0.15 }}
                        justifycenter
                        aligncenter
                        className={classes.bottomBar}
                    >
                        <FlexBox justifycenter aligncenter row w100 className="mt-4 mb-3">
                            <Button variant="contained" className={clsx(classes.doneBtn)} onClick={onBack}>
                                {completed ? 'OK' : showRules ? 'Cancel' : "I'm done"}
                            </Button>
                            {!completed && (
                                <Button
                                    variant="contained"
                                    className={clsx(classes.startBtn, 'ml-4')}
                                    onClick={showRules ? onStart : selected && getNextQuestion}
                                >
                                    {showRules ? "Got it! Let's Start!" : 'Next question'}
                                </Button>
                            )}
                        </FlexBox>
                        {!completed && !showRules && (
                            <Button
                                className="mb-2"
                                style={{ color: 'rgba(255,255,255,.7)' }}
                                onClick={() => setShowReport(true)}
                            >
                                Report Question
                            </Button>
                        )}
                    </FlexBox>
                )}
            </AnimatePresence>
            {showReport && <Report onClose={() => setShowReport(false)}></Report>}
        </FlexBox>
    );
};
