import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import classes from './dropdown.module.scss';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
//import history from '@/shared/history';

export const Dropdown = props => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory();
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleClick = o => {
        console.log('HI THERE');
        console.log({o});
        if (o.action) {
            o.action();
        } else if (o.href) {
            if (o.openNewTab) {
                window.open(o.href, '_blank');
            } else {
                window.location = o.href;
            }
        } else if (o.link) {
            console.log({history})
            history.push(o.link);
        }
        setOpen(false);
    };
    const renderOptions = options => {
        return options.map((o, i) => {
            if (o.href && !o.openNewTab) {
                return (
                    <MenuItem
                        href={o.href}
                        component="a"
                        key={i}
                        disabled={o.disabled}
                        className={classes.menuItem}
                    >
                        {o.icon && <div className="mr-3">{o.icon}</div>}
                        {o.display}
                    </MenuItem>
                );
            }
            return (
                <MenuItem
                    key={i}
                    disabled={o.disabled}
                    onClick={() => handleClick(o)}
                    className={classes.menuItem}
                >
                    {o.icon && <div className="mr-3">{o.icon}</div>}
                    {o.display}
                </MenuItem>
            );
        });
    };
    return (
        <div className={clsx(classes.menu, props.className)} style={props.style}>
            <div
                aria-label="dropdown"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={props.className}
                style={props.style}
            >
                {props.children}
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={props.placement}
                style={{
                    ...props.popperStyle,
                    zIndex: 5,
                    position: 'absolute',
                }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper className={classes.menu}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    disablePadding
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {renderOptions(props.options)}
                                    {props.secondOptions && <div className={classes.divider} />}
                                    {props.secondOptions && renderOptions(props.secondOptions)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};
