import React, { useEffect } from 'react';
import { PostGrid, LottiePlaceholder } from '@/shared/components';
import lottieJson from '../library.json';

export const Courses = props => {
    useEffect(() => {
        props.expireStore();
        if (props.total == 0) {
            props.getResults('courses', 1);
        }
    }, []);

    const placeholder = (
        <LottiePlaceholder
            json={lottieJson}
            title="Get to Bookmarking!"
            message="Keep courses you like and want to view or revisit later by bookmarking
    them."
        ></LottiePlaceholder>
    );

    return <PostGrid resultsKey="courses" {...props} placeholder={placeholder} />;
};
