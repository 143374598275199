import { connect } from 'react-redux';
import { Trivia as TriviaComponent } from './trivia.component';
import { getTrivia, getQuestion, getScores } from './trivia.actions.js';

const mapStateToProps = state => {
    return {
        ...state.triviaReducer,
        token: state.authReducer.token,
    };
};

export const Trivia = connect(mapStateToProps, {
    getTrivia,
    getQuestion,
    getScores,
})(TriviaComponent);
