import React, { useEffect, useState, Fragment } from 'react';
import helpers from '@/shared/util/helpers';
import { Helmet } from 'react-helmet';
import { OutlinedInput, IconButton, Icon, Container, Typography } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import GeocodeEarth from '@/shared/components/geocode-earth-input/geocode-earth-input';
import { RadioControl } from '@/shared/components/quizzer/radio-control';
import { DobInput } from '@/shared/components/dob-input/dob-input.component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import classes from './create-account.styles.module.scss';
import history from '@/shared/history';

export const CreateAccount = ({
    getIncomplete,
    postAnswer,
    success,
    answerSuccess,
    missing_fields,
    ...props
}) => {
    const { setTitle } = helpers;
    const [selected, setSelected] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [location, setLocation] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState({});

    useEffect(() => {
        getIncomplete();
    }, []);

    useDidUpdateEffect(() => {
        if (missing_fields.length < 1) {
            history.replace('/');
        } else {
            setCurrentQuestion(missing_fields[0]);
        }
    }, [success]);

    useDidUpdateEffect(() => {
        if (answerSuccess) {
            getIncomplete();
        }
    }, [answerSuccess]);

    const { id, label_edit, label_view, options } = currentQuestion;

    const answerQuestion = answer => {
        postAnswer(id, answer);
    };

    const handleInputChange = changeEvent => {
        setSelected(changeEvent.target.value);
        if (changeEvent.target.value.length > 2) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    };

    const handleLocationChange = changeEvent => {
        setLocation(changeEvent.target.value);
        if (changeEvent.target.value == selected && !enabled) {
            setEnabled(true);
        } else if (enabled) {
            setEnabled(false);
        }
    };

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13 && enabled) {
            event.preventDefault();
            answerQuestion(selected);
        }
    };

    const showTextInput = () => {
        return (
            <OutlinedInput
                style={{ maxWidth: 300, width: '100%' }}
                autoComplete="off"
                id={id}
                placeholder={label_view}
                value={selected}
                onChange={handleInputChange}
                required
                onKeyPress={handleEnterPress}
            />
        );
    };

    const updateLocation = ({ suggestion }) => {
        const value = suggestion.value;
        setSelected(value);
        setLocation(value);
        setEnabled(true);
    };

    const showLocationInput = () => {
        return (
            <div
                style={{
                    maxWidth: window.isDesktop ? 300 : 'none',
                    width: '100%',
                }}
            >
                <GeocodeEarth
                    value={location}
                    onChange={updateLocation}
                    onSelect={updateLocation}
                    onTextChange={handleLocationChange}
                    currentLatitude={props.guest.latitude}
                    currentLongitude={props.guest.longitude}
                />
            </div>
        );
    };

    const showRadioInput = () => {
        return (
            <FlexBox row wrap justifycenter={window.isDesktop} justifystart={!window.isDesktop}>
                <RadioControl onAnswer={answerQuestion} options={currentQuestion.options} />
            </FlexBox>
        );
    };

    const showDateInput = () => {
        const onDobChange = date => {
            setSelected(date);
            setEnabled(true);
        };
        return <DobInput onChange={onDobChange}></DobInput>;
    };

    const renderInput = () => {
        if (id == '_geostring') {
            return showLocationInput();
        } else if (id == 'date_of_birth') {
            return showDateInput();
        } else if (options && options.length > 0) {
            return showRadioInput();
        } else {
            return showTextInput();
        }
    };

    const showInput = currentQuestion.id ? true : false;
    const hideBtn = options && options.length > 0;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Create Your Plus Account')}</title>
            </Helmet>
            <TransitionGroup component={null}>
                <CSSTransition key={currentQuestion.id} timeout={300} classNames="create-slide">
                    <Container maxWidth="xs" className="py-5">
                        <FlexBox w100>
                            <Typography
                                className="mb-4 onboarding-header"
                                variant="h6"
                                component="label"
                                htmlFor={currentQuestion.id}
                                style={{
                                    marginLeft: !window.isDesktop ? 4 : 0,
                                }}
                            >
                                {label_edit}
                            </Typography>

                            <FlexBox
                                row
                                w100
                                justifycenter={window.isDesktop}
                                justifystart={!window.isDesktop}
                            >
                                {showInput && renderInput()}
                                {!hideBtn && showInput && (
                                    <IconButton
                                        aria-label="next"
                                        onClick={() => answerQuestion(selected)}
                                        disabled={!enabled}
                                        color="primary"
                                    >
                                        <Icon className="fal fa-arrow-right"></Icon>
                                    </IconButton>
                                )}
                            </FlexBox>
                        </FlexBox>
                    </Container>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );
};
