import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Typography, Button } from '@mui/material';
import classes from './theme-79793.module.scss';
import clsx from 'clsx';


export const Theme79793 = props => {

    return (
        <Fragment>
            <div className={classes.hero} style={{ backgroundImage: `url(${props.course.photos.large.url})` }}>
                <Typography variant="h1" gutterBottom className={classes.title}>
                    {props.course.title}
                </Typography>
            </div>
        </Fragment>
    )

};