import React, { memo, useEffect, useState } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography } from '@mui/material';
import CountdownComponent from 'react-countdown';
const Timer = ({ secondsRemaining, onComplete }) => {
    const [time, setTime] = useState(0);
    useEffect(() => {
        setTime(Date.now() + secondsRemaining * 1000);
    }, [secondsRemaining]);

    const renderer = timerProps => {
        const units = ['days', 'hours', 'minutes', 'seconds'];

        const unitsRendered = units.map((unit, index) => {
            return (
                <FlexBox className="number alert" justifycenter key={index} aligncenter>
                    {timerProps[unit].toString()}
                    <Typography className="alert" variant="caption">
                        {unit}
                    </Typography>
                </FlexBox>
            );
        });
        return <FlexBox row>{unitsRendered}</FlexBox>;
    };

    return <CountdownComponent onComplete={onComplete} renderer={renderer} date={time} />;
};

//memoize component so doesn't rerender everytime changes from active/not active, etc and starts over timer
export const Countdown = memo(Timer);
