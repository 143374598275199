import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loading: true,
    events: [],
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    lastLoadAt: null,
};

export const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'LIBRARY_EVENTS_REQUESTED':
            return {
                ...state,
                loading: true,
                criteria: action.payload.criteria,
                events: action.payload.page == 1 ? [] : state.events,
            };
        case 'LIBRARY_EVENTS_SUCCESS': {
            let events = action.payload.events;
            const pagination = action.payload.events_pagination;

            if (pagination.current_page > 1) {
                events = [...state.events, ...events];
                events = uniqBy(events, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                events,
                ...action.payload.events_pagination,
                loading: false,
                lastLoadAt: moment(),
            };
        }
        case 'LIBRARY_EVENTS_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
                criteria: [],
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track } = action.payload;
            const { postable_id, bookmark_flag } = post_track;

            if (bookmark_flag) {
                return initialState;
            }

            return {
                ...state,
                events: state.events.filter(a => {
                    return a.id != postable_id;
                }),
            };
        }

        case 'EXPIRE_STORE': {
            if (
                action.force ||
                (state.lastLoadAt && moment(state.lastLoadAt) < moment().subtract(60, 'minutes'))
            ) {
                return initialState;
            }
            return { ...state };
        }
    }
};
