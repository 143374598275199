import { connect } from 'react-redux';
import { RankList as RankListComponent } from './rank-list.component';
//import { sampleAction } from './test.actions.js';

const mapStateToProps = state => {
    return state.triviaReducer;
};

export const RankList = connect(mapStateToProps, {
    //sampleAction,
})(RankListComponent);
