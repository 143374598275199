import React, { useEffect, useState } from 'react';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './timer.styles.module.scss';
import { motion, useTransform, useMotionValue } from 'framer-motion';

const TIME_INTERVAL = 250;

let interval = undefined;
let timeout = undefined;

export const Timer = ({ onTimeCompleted, onTimeChanged, buffer }) => {
    const [running, setRunning] = useState(true);
    const [progress, setProgress] = useState(10);

    const x = useMotionValue(0);
    const backgroundColor = useTransform(x, [0, 10], ['#FF5733', '#3271a0']);
    const color = useTransform(x, [0, 8], ['#000', '#fff']);
    useEffect(() => {
        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, []);
    useEffect(() => {
        if (running) {
            timeout = setTimeout(() => {
                interval = setInterval(() => {
                    setProgress(prev => Math.max(prev - 0.25, 0));
                }, TIME_INTERVAL);
            }, buffer * 1000);
        } else {
            clearInterval(interval);
            clearTimeout(timeout);
        }
    }, [running]);

    useEffect(() => {
        if (progress === 0) {
            setRunning(false);
            clearInterval(interval);
            clearTimeout(timeout);
            onTimeCompleted();
        }
        const secondsRemain = progress > 10 ? 10 : progress;
        onTimeChanged(secondsRemain);
        x.set(progress);
    }, [progress]);
    return (
        <div className={classes.container}>
            <motion.div
                className="progress"
                style={{ height: '100%', backgroundColor }}
                initial={{ width: '100%' }}
                animate={{ width: `${Math.min(progress * 10, 100)}%` }}
                transition={{ ease: 'linear' }}
            ></motion.div>
            <FlexBox className={classes.textContainer} w100 h100 justifycenter aligncenter>
                <motion.div className={classes.text} style={{ color }}>
                    {Math.min(progress * 100, 1000)}
                </motion.div>
            </FlexBox>
        </div>
    );
};
