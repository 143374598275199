import { connect } from 'react-redux';
import { Resource as ResourceComponent } from './resource.component';
import { getResult } from './resource.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state.resourceReducer,
    };
};

export const Resource = connect(mapStateToProps, {
    getResult,
})(ResourceComponent);
