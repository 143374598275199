import { connect } from 'react-redux';
import { Articles as ArticlesComponent } from './articles.component';
import { getResults } from './articles.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.articlesReducer;
};

export const Articles = connect(mapStateToProps, {
    getResults,
    expireStore,
})(ArticlesComponent);
