import { connect } from 'react-redux';
import { Course as CourseComponent } from './course.component';
import { getResult } from './course.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state.courseReducer,
    }
};

export const Course = connect(mapStateToProps, {
    getResult,
})(CourseComponent);
