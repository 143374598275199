import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography, Button, TextField } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './report.styles.module.scss';
import Modal from '@/shared/components/modal/modal';
import clsx from 'clsx';

export const Report = ({ question, reportQuestion, onClose }) => {
    const [message, setMessage] = useState('');

    const onChange = e => {
        setMessage(e.target.value);
    };

    const onReport = () => {
        reportQuestion({ question_id: question.id, notes: message });
        onClose();
    };

    return (
        <Modal size="auto" mobileFullscreen={false} hideClose>
            <FlexBox column style={{ maxwidth: 400, width: '100%' }} className={clsx('p-4')}>
                <FlexBox>
                    <FlexBox aligncenter className={classes.question}>
                        <Typography align="center" variant="h6">
                            {question.question_text}
                        </Typography>
                        <Typography className={clsx('mt-2', classes.answer)} align="center" variant="subtitle2">
                            {question.options[question.correct_answer]}
                        </Typography>
                    </FlexBox>
                    <Typography align="center" variant="h3" className="mb-3">
                        Please describe the problem with this question
                    </Typography>
                    <TextField
                        className={classes.input}
                        multiline
                        rows={6}
                        variant="outlined"
                        value={message}
                        placeholder="Reports without an adequate description of the problem will be discared"
                        onChange={onChange}
                    ></TextField>
                </FlexBox>
                <FlexBox w100 justifyend row className="mt-4">
                    <Button className="mr-3" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="secondary" disabled={message < 3} onClick={onReport}>
                        Report Question
                    </Button>
                </FlexBox>
            </FlexBox>
        </Modal>
    );
};
