import axiosAPI from '@/shared/api';

export const sendPasswordResetLink = request => async dispatch => {
    dispatch(sendPasswordResetLinkRequested());

    return axiosAPI()
        .post('/password/email', request)
        .then(response => {
            dispatch(sendPasswordResetLinkSuccess(response.data));
        })
        .catch(error => {
            dispatch(sendPasswordResetLinkFailed(error.response));
        });
};

function sendPasswordResetLinkRequested() {
    return {
        type: 'SEND_PASSWORD_RESET_LINK_REQUESTED',
    };
}

function sendPasswordResetLinkSuccess(payload) {
    return {
        type: 'SEND_PASSWORD_RESET_LINK_SUCCESS',
        payload,
    };
}

function sendPasswordResetLinkFailed(error) {
    return {
        type: 'SEND_PASSWORD_RESET_LINK_FAILED',
        error,
    };
}
