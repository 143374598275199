import { articlesReducer } from './articles/articles.reducer';
import { videosReducer } from './videos/videos.reducer';
import { eventsReducer } from './events/events.reducer';
import { coursesReducer } from './courses/courses.reducer';

export const libraryReducers = {
    libraryArticlesReducer: articlesReducer,
    libraryVideosReducer: videosReducer,
    libraryEventsReducer: eventsReducer,
    libraryCoursesReducer: coursesReducer,
};
