import { connect } from 'react-redux';
import { MainNav as MainNavComponent } from './main-nav.component';
import { queueForExpiring, expireStore, signout } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
    };
};

export const MainNav = connect(mapStateToProps, {
    queueForExpiring,
    expireStore,
    signout,
})(MainNavComponent);
