import React from 'react';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './categories.styles.module.scss';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import { ButtonBase, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const CategoriesSkeleton = () => {
    return (
        <React.Fragment>
            <Skeleton
                variant="rect"
                key={'start'}
                className="mb-4"
                width={70}
                height={14}
            ></Skeleton>
            <FlexBox row wrap>
                {[...Array(10)].map((item, i) => {
                    return (
                        <Skeleton
                            className={classes.category}
                            variant="rect"
                            key={i}
                            width={70}
                            height={36}
                        ></Skeleton>
                    );
                })}
            </FlexBox>
        </React.Fragment>
    );
};

export const Categories = props => {
    const renderCategories = () => {
        return props.categories.map(r => {
            return (
                <Link key={r.id} to={r.link}>
                    <ButtonBase className={classes.category}>
                        <SafeHtml className={classes.name}>{r.name}</SafeHtml>
                    </ButtonBase>
                </Link>
            );
        });
    };

    if (props.categories.length) {
        return (
            <React.Fragment>
                <Typography variant="subtitle2" className={clsx(classes.title, 'mb-4')}>
                    CATEGORIES
                </Typography>
                <FlexBox wrap row>
                    {renderCategories()}
                </FlexBox>
            </React.Fragment>
        );
    } else {
        return <CategoriesSkeleton></CategoriesSkeleton>;
    }
};
