import React, { Fragment, useState, useEffect } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography, Container, Skeleton } from '@mui/material';
import classes from './page.styles.module.scss';
import { Section, PostContent, PostMeta } from '@/shared/components';
import { PostTrack } from '@/shared/articles/post-track';
import clsx from 'clsx';
import { get, has } from 'lodash';

export const Page = props => {
    const { page, path } = props;
    const [canShow, setCanShow] = useState(false);

    useEffect(() => {
        props.getResult(path);
    }, [path]);

    useDidUpdateEffect(() => {
        setCanShow(true);
    }, [props.loaded]);

    const photo = get(page, 'photos.medium.url');

    const PageSkeleton = () => {
        return (
            <Fragment>
                <Section>
                    <FlexBox aligncenter>
                        <Container maxWidth="md">
                            <Skeleton width={'90%'} height={75}></Skeleton>

                            <FlexBox alignstart justifybetween row>
                                {photo && (
                                    <Skeleton
                                        className={classes.photo}
                                        variant="rect"
                                        width={'250px'}
                                        height={'300px'}
                                    ></Skeleton>
                                )}
                                <Container maxWidth="md">
                                    {[...Array(7)].map((item, i) => (
                                        <Skeleton
                                            className="mt-1"
                                            key={i}
                                            width={'100%'}
                                        ></Skeleton>
                                    ))}
                                    <Skeleton
                                        className="mt-1 mb-3"
                                        key={'end1'}
                                        width={'80%'}
                                    ></Skeleton>
                                    {[...Array(4)].map((item, i) => (
                                        <Skeleton
                                            className="mt-1"
                                            key={i + 10}
                                            width={'100%'}
                                        ></Skeleton>
                                    ))}
                                    <Skeleton
                                        className="mt-1"
                                        key={'end2'}
                                        width={'40%'}
                                    ></Skeleton>
                                </Container>
                            </FlexBox>
                        </Container>
                    </FlexBox>
                </Section>
            </Fragment>
        );
    };

    return (
        (canShow && (
            <Fragment>
                <PostMeta post={page} />
                <Section className="pb-m-4">
                    <Container maxWidth="md" disableGutters>
                        <FlexBox alignstart justifybetween row>
                            <Typography variant="h1" className={classes.title}>
                                {page.title}
                            </Typography>
                            {window.isDesktop && (
                                <PostTrack
                                    className="ml-5 ml-m-0 mt-2"
                                    post={page}
                                    showEdit
                                    showBookmark={false}
                                />
                            )}
                        </FlexBox>

                        <FlexBox alignstart justifybetween row>
                            {photo && (
                                <img
                                    className={clsx(classes.photo, 'mt-5', 'mr-5')}
                                    alt={page.title}
                                    src={photo}
                                />
                            )}
                            <PostContent className="wp-content" content={page.content} />
                        </FlexBox>
                    </Container>
                </Section>
            </Fragment>
        )) || <PageSkeleton></PageSkeleton>
    );
};
