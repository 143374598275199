import Pusher from 'pusher-js';
import { pusher_key, pusher_reset_channel } from 'config';
import helpers from '@/shared/util/helpers';

export const pusherReset = () => {
    var pusher = new Pusher(pusher_key, { cluster: 'mt1', forceTLS: true });
    var channel = pusher.subscribe(pusher_reset_channel);

    channel.bind('reset', function(data) {
        if (data.message) {
            alert(data.message);
        }
        window.localStorage.clear();

        if (data.crosssite) {
            helpers.resetIframe();
        }

        if (data.redirect) {
            window.location = data.redirect;
        } else {
            window.location.reload(true);
        }
    });
};

export default pusherReset;
