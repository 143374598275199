import axiosAPI from '@/shared/api';

export const getIncomplete = () => async dispatch => {
    dispatch(incompleteRequested());
    return axiosAPI()
        .get('/account/incomplete')
        .then(response => {
            dispatch(incompleteSuccess(response.data));
        })
        .catch(error => {
            dispatch(incompleteFailed(error.response));
        });
};

function incompleteRequested(author, criteria) {
    return {
        type: 'ACCOUNT_INCOMPLETE_REQUESTED',
        payload: {
            author,
            criteria,
        },
    };
}

function incompleteFailed(error) {
    return {
        type: 'ACCOUNT_INCOMPLETE_FAILED',
        error,
    };
}

function incompleteSuccess(payload) {
    return {
        type: 'ACCOUNT_INCOMPLETE_SUCCESS',
        payload,
    };
}

export const postAnswer = (id, answer) => async dispatch => {
    dispatch(accountAnswerRequested());
    return axiosAPI()
        .post('/account/incomplete', { [id]: answer })
        .then(response => {
            dispatch(accountAnswerSuccess(response.data, id));
        })
        .catch(error => {
            dispatch(accountAnswerFailed(error.response));
        });
};

function accountAnswerRequested() {
    return {
        type: 'ACCOUNT_ANSWER_REQUESTED',
    };
}

function accountAnswerSuccess(data, id) {
    return {
        type: 'ACCOUNT_ANSWER_SUCCESS',
        removeId: id,
        ...data,
    };
}

function accountAnswerFailed(error) {
    return {
        type: 'ACCOUNT_ANSWER_FAILED',
        error,
    };
}
