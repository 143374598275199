import React, { Fragment, useEffect, useState } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
//import history from '@/shared/history';
import { Typography, Skeleton } from '@mui/material';
import { PostGrid, ShareStory } from '@/shared/components';
import { get, has, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Tags } from '@/shared/articles/tags';

export const Category = props => {
    const [canShow, setCanShow] = useState(false);

    // const sort = option => {
    //     history.replace({
    //         pathname: props.slug,
    //         search: `?sort_by=${option}`,
    //     });

    //     props.getResults(props.slug, 1, {
    //         ...props.criteria,
    //         sort_by: option,
    //     });
    // };

    useEffect(() => {
        //console.log(props);
        const categoryHasChanged =
            has(props, 'category.slug') && !isEqual(props.category.slug, props.slug);

        const tagHasChanged = has(props, 'ptag') && !isEqual(props.tag, props.ptag);

        const qparams = queryString.parse(props.location.search);
        let page = get(qparams, 'page', 1);

        if (props.total == 0 || categoryHasChanged || tagHasChanged) {
            if (tagHasChanged) {
                qparams.tag = props.ptag;
            }
            props.getResults(props.slug, page, qparams);
        } else {
            setCanShow(true);
        }
    }, [props.slug, props.ptag]);

    useDidUpdateEffect(() => {
        setCanShow(true);
    }, [props.loaded]);

    const isSuccessStory = props.category?.slug == 'success-stories';

    return (
        <Fragment>
            <Helmet>
                <title>{props.category.name}</title>
                <link rel="canonical" href={props.category.canonical} />
            </Helmet>
            <div className="pb-6">
                {(canShow && (
                    <Typography variant="h3" className="my-5">
                        {props.category.name}
                    </Typography>
                )) || (
                        <Skeleton variant="rect" className="my-5" width={'50%'} height={50}></Skeleton>
                    )}
                {canShow && isSuccessStory && (
                    <div>
                        <ShareStory></ShareStory>
                        <FlexBox wrap row className="my-5">
                            <Tags
                                tags={props.category.tags}
                                categoryLink={props.category.link}
                                currentTag={props.ptag}
                            />
                        </FlexBox>
                    </div>
                )}

                {/* <div className={'mb-4 ml-0'} style={{ textAlign: 'right' }}>
                <Sort
                    key="sort"
                    criteria={props.criteria}
                    onSort={sort}
                    keepScrollPosition
                    showSelected={true}
                    options={[
                        { title: 'Most Recent', value: 'newest' },
                        { title: 'Popular this Week', value: 'popweek' },
                        { title: 'Popular this Month', value: 'popmonth' },
                        { title: 'Popular this Year', value: 'popyear' },
                        { title: 'Popular all Time', value: 'popall' },
                    ]}
                />
            </div> */}
                <PostGrid showAsListItem={!window.isDesktop} resultsKey="articles" {...props} />
            </div>
        </Fragment>
    );
};
