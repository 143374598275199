import { connect } from 'react-redux';
import { Video as VideoComponent } from './video.component';
import { getResult } from './video.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return {
        token: state.authReducer.token,
        ...state.videoReducer,
    };
};

export const Video = connect(mapStateToProps, {
    getResult,
    expireStore,
})(VideoComponent);
