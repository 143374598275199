import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography, Grid, ButtonBase } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './stats.styles.module.scss';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';

const hover = {
    hidden: {
        width: '0%',
        height: '0%',
        bottom: 0,
        right: 0,
        left: '100%',
        top: '100%',
        borderRadius: 100,
    },
    show: {
        width: '100%',
        borderRadius: 8,
        height: '100%',
        left: '0%',
        top: '0%',
        opacity: 1,
    },
    exit: {
        width: '0%',
        height: '0%',
        bottom: 0,
        right: 0,
        left: '100%',
        top: '100%',
        borderRadius: 100,
        opacity: 0,
        transition: {
            delay: 0.2,
        },
    },
};
const hoverContent = {
    hidden: {
        opacity: 0,
        y: 10,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.2,
        },
    },
    exit: {
        opacity: 0,
        y: 10,
    },
};

const Stat = ({ text, number, yourNumber }) => {
    const [isShown, setIsShown] = useState(false);
    const above = yourNumber > number;
    return (
        <FlexBox className={classes.statContainer} row justifybetween>
            <FlexBox>
                <Typography style={{ marginTop: 4, fontWeight: 800, fontSize: 32 }} variant="h3">
                    {number || '--'}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                    {text}
                </Typography>
                {yourNumber && (
                    <ButtonBase
                        onClick={() => setIsShown(true)}
                        //onMouseLeave={() => setIsShown(false)}
                        disableRipple
                        className={clsx(classes.compare, above && classes.compareAbove)}
                    >
                        <FlexBox row>
                            {above && <i className="fas fa-long-arrow-up"></i>}
                            {!above && <i className="fas fa-long-arrow-down"></i>}
                            <i className="fas fa-user"></i>
                        </FlexBox>
                    </ButtonBase>
                )}
                <AnimatePresence>
                    {isShown && (
                        <motion.div
                            className={clsx(classes.overlay, above && classes.overlayAbove)}
                            onClick={() => setIsShown(false)}
                            transition={{ type: 'timing', duration: 0.3 }}
                            variants={hover}
                            initial="hidden"
                            animate="show"
                            exit="exit"
                        >
                            <FlexBox
                                // aligncenter
                                justifycenter
                                className={classes.content}
                                transition={{ duration: 0.3, type: 'timing' }}
                                variants={hoverContent}
                            >
                                <Typography
                                    style={{
                                        marginTop: 4,
                                        fontWeight: 800,
                                        fontSize: 32,
                                        color: 'white',
                                    }}
                                    variant="h3"
                                >
                                    {yourNumber}
                                </Typography>
                                <Typography style={{ color: 'white' }} variant="subtitle2">
                                    Your {text}
                                </Typography>
                                <ButtonBase
                                    onClick={() => setIsShown(false)}
                                    //onMouseLeave={() => setIsShown(false)}
                                    disableRipple
                                    className={clsx(classes.compare)}
                                >
                                    <FlexBox row>
                                        <i
                                            style={{ color: 'white' }}
                                            className="fas fa-compress-alt"
                                        ></i>
                                    </FlexBox>
                                </ButtonBase>
                            </FlexBox>
                        </motion.div>
                    )}
                </AnimatePresence>
            </FlexBox>
        </FlexBox>
    );
};

export const Stats = ({
    current_week_total_participants,
    current_week_total_completions,
    average_score_all,
    average_score_user,
    average_time_all,
    average_time_user,
}) => {
    const getAveragePointsPerQuestion = time => {
        if (!time) return null;
        const averageTimeAllQuarter = (Math.round(time * 4) / 4).toFixed(2);
        return (10 - averageTimeAllQuarter) * 100;
    };

    return (
        <Grid className="mt-4" container spacing={2}>
            <Grid item xs={6} md={3}>
                <Stat icon="users" number={current_week_total_participants} text="Players"></Stat>
            </Grid>
            <Grid item xs={6} md={3}>
                <Stat icon="award" number={current_week_total_completions} text="Completed"></Stat>
            </Grid>
            <Grid item xs={6} md={3}>
                <Stat
                    above
                    icon="clock"
                    number={getAveragePointsPerQuestion(average_time_all)}
                    text="Avg per Question"
                    yourNumber={getAveragePointsPerQuestion(average_time_user)}
                ></Stat>
            </Grid>
            <Grid item xs={6} md={3}>
                <Stat
                    icon="coins"
                    number={average_score_all && Math.ceil(average_score_all)}
                    text="Avg Score"
                    yourNumber={average_score_user && Math.ceil(average_score_user)}
                ></Stat>
            </Grid>
        </Grid>
    );
};
