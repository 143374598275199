import axiosAPI from '@/shared/api';

export const validateReset = (token, id) => async dispatch => {
    dispatch(validateResetRequested());

    return axiosAPI()
        .post('/password/token', { token, id })
        .then(response => {
            dispatch(validateResetSuccess(response.data));
        })
        .catch(error => {
            dispatch(validateResetFailed(error.response));
        });
};

function validateResetRequested() {
    return {
        type: 'VALIDATE_RESET_REQUESTED',
    };
}

function validateResetSuccess(payload) {
    return {
        type: 'VALIDATE_RESET_SUCCESS',
        payload,
    };
}

function validateResetFailed(error) {
    return {
        type: 'VALIDATE_RESET_FAILED',
        error,
    };
}

export const resetPassword = request => async dispatch => {
    dispatch(resetPasswordRequested());

    return axiosAPI()
        .post('/password/reset', request)
        .then(response => {
            dispatch(resetPasswordSuccess(response.data));
        })
        .catch(error => {
            dispatch(resetPasswordFailed(error.response));
        });
};

function resetPasswordRequested() {
    return {
        type: 'RESET_PASSWORD_REQUESTED',
    };
}

function resetPasswordSuccess(payload) {
    return {
        type: 'RESET_PASSWORD_SUCCESS',
        payload,
    };
}

function resetPasswordFailed(error) {
    return {
        type: 'RESET_PASSWORD_FAILED',
        error,
    };
}
