import moment from 'moment';

const initialState = {
    loaded: null,
    resource: {},
};

export const resourceReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'RESOURCE_REQUESTED':
            return {
                ...state,
            };
        case 'RESOURCE_SUCCESS': {
            const resource = action.payload.resource;
            return {
                ...state,
                resource,
                loaded: moment(),
            };
        }
        case 'RESOURCE_FAILED':
            return {
                ...state,
                error: action.error,
            };
        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            let resource = state.resource;
            if (resource.id == postable_id) {
                resource.likes_count = likes_count;
                resource.post_track = { bookmark_flag, like_flag, enrollment_flag };
            }

            return {
                ...state,
                resource,
            };
        }
    }
};
