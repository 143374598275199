import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import history from '@/shared/history';
import classes from './curriculum.module.scss';
import { get } from 'lodash';
import clsx from 'clsx';

export const Curriculum = props => {

    const { list, lessonId, preview, lessonProgression } = props;
    const currentLessonRef = useRef(null);
    let prevLesson = {};

    const getLessonList = () => {
        return list.map(section => {
            return (
                <FlexBox key={section.id} className={classes.section}>
                    <h4 className={classes.sectionTitle}>{section.title}</h4>
                    {
                        section.lessons.map(lesson => {
                            const isCurrent = lesson.id == lessonId;
                            const videoUrl = lesson.video_url;
                            const videoTime = get(lesson.post_track, 'meta.video_time', 0);
                            const isComplete = get(lesson.post_track, 'meta.is_complete', false);
                            const isNextLesson = (prevLesson.isComplete && !isComplete);

                            prevLesson = { id: lesson.id, isComplete };

                            let lessonClasses = [classes.lesson];

                            if (preview) {
                                lessonClasses.push(classes.preview);
                            }

                            if (isCurrent) {
                                lessonClasses.push(classes.current);
                            }

                            if (isComplete) {
                                lessonClasses.push(classes.complete);
                            }

                            if (isNextLesson) {
                                lessonClasses.push(classes.isNextLesson);
                            }

                            if (lessonProgression) {
                                lessonClasses.push(classes.lessonProgression);
                            }

                            let lessonStartClasses = [classes.lessonStart];
                            if (lesson.is_sample) {
                                lessonStartClasses.push(classes.sample);
                            }

                            let status = 'new';
                            if (isCurrent || (!isComplete && videoTime > 0)) {
                                status = 'incomplete';
                            }
                            else if (isComplete) {
                                status = 'complete';
                            }

                            const refProps = isCurrent ? { ref: currentLessonRef } : null;

                            return (
                                <div key={lesson.id} {...refProps} className={clsx(lessonClasses)} onClick={() => {
                                    if (isComplete || isNextLesson || lessonProgression) {
                                        history.replace(lesson.link);
                                    }
                                }}>
                                    <div className={classes.lessonStatusTitle}>
                                        {!preview && (
                                            <div className={classes.lessonStatus}>
                                                {status == 'new' && (
                                                    <i className="mr-2 far fa-circle"></i>
                                                ) || status == 'incomplete' && (
                                                    <i className="fas fa-adjust"></i>
                                                ) || (
                                                        <i className="mr-2 fas fa-check-circle"></i>
                                                    )}
                                            </div>
                                        )}
                                        <div className={classes.lessonTitle}>
                                            {videoUrl && (
                                                <i className="fab fa-youtube"></i>
                                            ) || (
                                                    <i className="fas fa-file-alt"></i>
                                                )}
                                            {lesson.title}
                                        </div>
                                    </div>
                                    {preview && (
                                        <div className={clsx(lessonStartClasses)}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                {lesson.is_sample && (
                                                    <span>Preview</span>
                                                ) || (<span>Start</span>)}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            );

                        })
                    }

                </FlexBox>
            );
        });
    };

    useEffect(() => {
        currentLessonRef.current && props.onCurrentLessonReady(currentLessonRef.current);
    }, [list]);

    return list && getLessonList() || null;


}