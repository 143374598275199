import { connect } from 'react-redux';
import { ResetPassword as ResetPasswordComponent } from './reset-password.component';
import { validateReset, resetPassword } from './reset-password.actions';

const mapStateToProps = state => {
    return state.resetPasswordReducer;
};

export const ResetPassword = connect(mapStateToProps, {
    validateReset,
    resetPassword,
})(ResetPasswordComponent);
