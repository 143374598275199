import React, { Fragment, useEffect } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { PostGrid } from '@/shared/components';
import helpers from '@/shared/util/helpers';
import { Helmet } from 'react-helmet';
import { Typography, Icon, Link } from '@mui/material';
import classes from './home.styles.module.scss';
import clsx from 'clsx';
import { LottiePlaceholder } from '@/shared/components';
import lottieJson from '../library/library.json';

export const Home = props => {
    const { token } = props;
    useEffect(() => {
        props.expireStore();
        props.getResults();
    }, []);

    const placeholder = (
        <LottiePlaceholder
            json={lottieJson}
            title="Get to Bookmarking!"
            message="Keep content you like and want to view or revisit later by bookmarking
    them."
        ></LottiePlaceholder>
    );

    useDidUpdateEffect(() => {
        props.getResults();
    }, [props.total]);

    const gridSizes = window.isDesktop ? { xs: 4 } : { xs: 12, md: 6, lg: 4 };
    const headingLinkStyle = { textDecoration: 'none', color: 'inherit' };
    // This was code before update, throwing error I think with self reference before initialization.
    // const headingLinkStyle = { ...headingLinkStyle, textDecoration: 'none', color: 'inherit' };
    const { setTitle } = helpers;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Home')}</title>
            </Helmet>
            <Fragment>
                <div id="home" className="pb-6">
                    <Typography variant="h3" className={clsx(classes.pageTitle, 'my-5')}>
                        Recent
                    </Typography>
                    <PostGrid showAsListItem={!window.isDesktop} resultsKey="recent" {...props} />
                    <div className={clsx(classes.spacingtop, 'py-m-5')}>
                        <Typography variant="h3" className={clsx(classes.pageTitle, 'my-5')}>
                            Trending
                        </Typography>
                        <PostGrid
                            showAsListItem={!window.isDesktop}
                            resultsKey="trending"
                            {...props}
                        />
                    </div>
                    <Typography variant="h3" className={clsx(classes.pageTitle, 'my-5')}>
                        Popular
                    </Typography>
                    <PostGrid showAsListItem={!window.isDesktop} resultsKey="popular" {...props} />

                    {token?.global_auths?.has_cm_user && (
                        <div className={clsx(classes.spacingtop, 'py-m-5')}>
                            <Link style={headingLinkStyle} className="w-100" to="/library">
                                <Typography
                                    variant="h3"
                                    className={clsx(classes.pageTitle, 'my-5')}
                                >
                                    Favorites
                                    <Icon
                                        className={clsx(
                                            classes.icon,
                                            'fas fa-chevron-right mr-3 ml-2 mt-1'
                                        )}
                                    />
                                </Typography>
                            </Link>
                            <PostGrid
                                showAsListItem={!window.isDesktop}
                                resultsKey="library"
                                {...props}
                                placeholder={placeholder}
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        </Fragment>
    );
};
