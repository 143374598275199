import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { PostGrid } from '@/shared/components';

export const TopArticles = props => {
    return (
        <PostGrid
            columns={1}
            showAsListItem={true}
            resultsKey="articles"
            skeletonCount={4}
            {...props}
        />
    );
};
