import React, { Fragment } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import classes from './faqs.module.scss';

export const Faqs = props => {

    const renderFaqs = () => {

        return props.questionsAndAnswers.map(qa => {
            let question = qa[0];
            let answer = qa[1];

            return (
                <Accordion key={question}>
                    <AccordionSummary
                        expandIcon={<i className="fas fa-angle-down"></i>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.question}>{question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            );
        });

    };

    return (
        <Fragment>
            {renderFaqs()}
        </Fragment>
    );

};