import React, { Fragment, useState, useEffect } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Typography, Container, Button, IconButton, Icon } from '@mui/material';
import { Skeleton } from '@mui/material';
import { ScrollPopup } from '@/shared/components';
import classes from './banner.styles.module.scss';
import clsx from 'clsx';
import { app_url } from 'config';
import { useHistory } from 'react-router-dom';

export const SignupPopup = ({ caption, title, showSignup = true, showSearch = true }) => {
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(true);

    const signup = () => {
        history.push('/signup');
    };
    const search = () => {
        history.push(app_url + '/gsearch');
    };

    if (showPopup) {
        return (
            <ScrollPopup bottom={70} right={10}>
                <FlexBox aligncenter justifybetween row className={classes.bottomWidget}>
                    <IconButton className={classes.closeBtn} onClick={() => setShowPopup(false)}>
                        <Icon className="fas fa-sm fa-times"></Icon>
                    </IconButton>
                    <FlexBox>
                        <Typography
                            // align="center"
                            className={classes.bannerCaption}
                            variant="subtitle2"
                        >
                            {caption || 'What are you waiting for?'}
                        </Typography>
                        <Typography
                            // align="center"
                            className={clsx('mt-1', classes.bannerText)}
                            variant="h2"
                        >
                            {title || 'Sign up for FREE!'}
                        </Typography>
                        <FlexBox row className={'mt-4 pt-2'}>
                            {showSignup && (
                                <Button
                                    className={clsx('mr-3', classes.bannerBtn)}
                                    variant="contained"
                                    color="primary"
                                    onClick={signup}
                                >
                                    Try for Free
                                </Button>
                            )}
                            {showSearch && (
                                <Button className={classes.bannerSecondaryBtn} variant="contained" onClick={search}>
                                    Start Searching
                                </Button>
                            )}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </ScrollPopup>
        );
    } else {
        return null;
    }
};
export const SignupBlueBanner = ({ caption, title, showSignup = true, showSearch = true }) => {
    return (
        <FlexBox justifycenter aligncenter className={classes.banner}>
            <Typography align="center" className={classes.bannerCaption} variant="subtitle2">
                {caption || 'What are you waiting for?'}
            </Typography>
            <Typography align="center" className={clsx('mt-2', classes.bannerText)} variant="h2">
                {title || 'Signup now and meet the love of your life'}
            </Typography>
            <FlexBox row className={'mt-4'}>
                {showSignup && (
                    <Button className={clsx('mr-3', classes.bannerBtn)} variant="contained" color="primary" onClick={signup}>
                        Try for Free
                    </Button>
                )}
                {showSearch && (
                    <Button className={classes.bannerSecondaryBtn} variant="contained" onClick={search}>
                        Start Searching
                    </Button>
                )}
            </FlexBox>
        </FlexBox>
    );
};
