const initialState = {};

export const triviaReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'GET_TRIVIA_REQUESTED': {
            return {
                ...state,
                loaded: false,
            };
        }
        case 'GET_TRIVIA_SUCCESS': {
            return {
                ...state,
                ...action.payload,
                loaded: true,
            };
        }
        case 'GET_TRIVIA_FAILED': {
            return {
                ...state,
                loaded: true,
            };
        }
        case 'GET_QUESTION_SUCCESS': {
            return {
                ...state,
                ...action.payload,
            };
        }
        case 'CLEAR_QUESTION': {
            return {
                ...state,
                ...action.payload,
                question: null,
            };
        }
        case 'GET_RESULT_SUCCESS': {
            return {
                ...state,
                ...action.payload,
            };
        }
        case 'GET_SCORES_REQUESTED': {
            return {
                ...state,
                scoresLoading: true,
            };
        }
        case 'GET_SCORES_FAILED': {
            return {
                ...state,
                ...action.payload,
                scoresLoading: false,
            };
        }
        case 'GET_SCORES_SUCCESS': {
            return {
                ...state,
                ...action.payload,
                moreLoaded: true,
                scoresLoading: false,
            };
        }
        case 'POST_ANSWER_SUCCESS': {
            return {
                ...state,
                ...action.payload,
                success: Date.now(),
            };
        }
        case 'POST_ANSWER_FAILED': {
            return {
                ...state,
                error: Date.now(),
            };
        }
        case 'SHOW_END_TRIVIA': {
            return {
                ...state,
                completed: Date.now(),
            };
        }
        case 'REPORT_QUESTION_SUCCESS': {
            return {
                ...state,
                reportSuccess: Date.now(),
            };
        }
    }
};
