import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
    Typography,
    Link as ExternalLink,
    Grid,
    IconButton,
    Icon,
    ButtonBase,
} from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './floating-footer.styles.module.scss';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { Section } from '@/shared/components';
import clsx from 'clsx';
import moment from 'moment';
import { throttle } from 'lodash';
//import { app_url } from 'config';
import { motion } from 'framer-motion';

const variants = {
    hidden: { y: '150%' },
    minimized: { y: 'calc(100% - 30px)' },
    expanded: { y: '0%' },
    notFixed: { y: '0%' },
};

export const FloatingFooter = ({ fixed, logo, links, showRaphael, showAppButtons, showSocial }) => {
    const [footerVisible, setFooterVisible] = useState(false);
    const [footerExpanded, setFooterExpanded] = useState(false);
    const footerVisibleRef = useRef(false);
    const footerExpandedRef = useRef(false);
    const fixedRef = useRef(false);
    const lastScrollRef = useRef(0);
    const isFixed = fixed && !window.Renderfy;

    const scroll = e => {
        if (fixedRef.current) {
            const scrollThreshold = Math.min(
                document.body.scrollHeight - window.innerHeight - 100,
                window.innerHeight * 2
            );
            const scrollTop = e.target.documentElement.scrollTop;
            if (scrollTop > scrollThreshold) {
                if (!footerVisibleRef.current) {
                    setFooterVisible(true);
                }
            } else if (scrollTop < scrollThreshold) {
                if (footerVisibleRef.current) {
                    setFooterVisible(false);
                }
            }
            if (footerExpandedRef.current && lastScrollRef.current > scrollTop + 50) {
                setFooterExpanded(false);
            }
            lastScrollRef.current = scrollTop;
        }
    };

    const scrollThrottled = throttle(scroll, 100);

    useEffect(() => {
        footerVisibleRef.current = footerVisible;
    }, [footerVisible]);
    useEffect(() => {
        footerExpandedRef.current = footerExpanded;
    }, [footerExpanded]);

    useEffect(() => {
        if (isFixed) {
            window.addEventListener('scroll', scrollThrottled);
        }
        fixedRef.current = isFixed;

        return () => {
            if (fixedRef.current) {
                window.removeEventListener('scroll', scrollThrottled);
            }
        };
    }, [isFixed]);

    const toggleFooterExpanded = () => {
        setFooterExpanded(!footerExpanded);
    };

    const renderLink = (link, index) => {
        const target = link.target ? link.target : '_self';
        return (
            <li key={index} className={classes.link}>
                {link.href ? (
                    <ExternalLink target={target} href={`${link.href}`}>
                        {link.text}
                    </ExternalLink>
                ) : (
                    <Link to={link.to}>{link.text}</Link>
                )}
            </li>
        );
    };

    const renderBottom = () => {
        return (
            <Section bgcolor="black" className="py-3">
                <FlexBox flex1 column justifycenter aligncenter>
                    <ExternalLink
                        color="textPrimary"
                        variant="body2"
                        href="https://straphael.net"
                        target="_blank"
                    >
                        <img
                            height="70"
                            src="https://d2q86h5pvn46dj.cloudfront.net/resources/raphael_footer_small.png"
                            alt=""
                        />
                    </ExternalLink>
                    <Typography style={{ color: 'white' }} variant="caption">
                        St. Raphael, patron of singles
                    </Typography>
                </FlexBox>
                <FlexBox style={{ flex: 2 }} className="p-3" column justifycenter aligncenter>
                    <Typography style={{ color: 'white' }} variant="caption" align="center">
                        CatholicMatch, Emotigram, Grow in Faith - Fall in Love, and Faith Focused
                        Dating are registered trademarks and/or trademarks of CatholicMatch, LLC
                    </Typography>
                </FlexBox>
                <FlexBox flex1></FlexBox>
            </Section>
        );
    };

    const inner = (
        <Fragment>
            <Section bgcolor="darkgray">
                {isFixed && (
                    <FlexBox row justifyend aligncenter flex1>
                        <IconButton onClick={toggleFooterExpanded} className={classes.fixedButton}>
                            <Icon
                                fontSize="small"
                                className={`fas fa-chevron-${footerExpanded ? 'down' : 'up'}`}
                            ></Icon>
                        </IconButton>
                    </FlexBox>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} zeroMinWidth>
                        <div>
                            {logo && (
                                <Link to={logo.link}>
                                    <img height={logo.height | 24} src={logo.src} alt={logo.alt} />
                                </Link>
                            )}
                            <FlexBox row className={clsx(classes.socialContainer, 'my-4 mb-m-2')}>
                                <ExternalLink
                                    target="_blank"
                                    href="https://www.facebook.com/CatholicMatch/"
                                    className={classes.socialBtn}
                                >
                                    <img
                                        alt="facebook"
                                        src="https://d2q86h5pvn46dj.cloudfront.net/emails/templates/facebook2x.png"
                                    ></img>
                                </ExternalLink>
                                <ExternalLink
                                    target="_blank"
                                    href="https://www.instagram.com/catholicmatch/"
                                    className={classes.socialBtn}
                                >
                                    <img
                                        alt="instagram"
                                        src="https://d2q86h5pvn46dj.cloudfront.net/emails/templates/instagram2x.png"
                                    ></img>
                                </ExternalLink>
                                <ExternalLink
                                    target="_blank"
                                    href="https://www.pinterest.com/catholicmatch"
                                    className={classes.socialBtn}
                                >
                                    <img
                                        alt="pinterest"
                                        src="https://d2q86h5pvn46dj.cloudfront.net/emails/templates/pinterest2x.png"
                                    ></img>
                                </ExternalLink>
                                <ExternalLink
                                    target="_blank"
                                    href="https://twitter.com/catholicmatch"
                                    className={classes.socialBtn}
                                >
                                    <img
                                        alt="twitter"
                                        src="https://d2q86h5pvn46dj.cloudfront.net/emails/templates/twitterx2x.png"
                                    ></img>
                                </ExternalLink>
                                <ExternalLink
                                    target="_blank"
                                    href="https://www.youtube.com/catholicmatch"
                                    className={classes.socialBtn}
                                >
                                    <img
                                        alt="youtube"
                                        src="https://d2q86h5pvn46dj.cloudfront.net/emails/templates/youtube2x.png"
                                    ></img>
                                </ExternalLink>
                            </FlexBox>
                            {showAppButtons && (
                                <FlexBox row className="mt-3">
                                    <ExternalLink
                                        target="_blank"
                                        href="https://apps.apple.com/us/app/catholicmatch-dating-app/id1438033563"
                                    >
                                        <img
                                            height="40"
                                            alt="appstore"
                                            src="https://d2q86h5pvn46dj.cloudfront.net/resources/app_store_simple.png"
                                        ></img>
                                    </ExternalLink>
                                    <ExternalLink
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.catholicmatch.app"
                                    >
                                        <img
                                            className="ml-3"
                                            height="40"
                                            alt="google play"
                                            src="https://d2q86h5pvn46dj.cloudfront.net/resources/google_play_simple.png"
                                        ></img>
                                    </ExternalLink>
                                </FlexBox>
                            )}
                        </div>
                    </Grid>
                    {links.map((linkSet, i) => {
                        return (
                            <Grid key={i} item xs={12} md={2} zeroMinWidth className={'py-m-1'}>
                                <ul className={classes.list}>{linkSet.map(renderLink)}</ul>
                            </Grid>
                        );
                    })}
                </Grid>
                <Typography className={clsx(classes.white, 'mt-4')} variant="caption" href="#">
                    © Copyright {moment().format('YYYY')} - All rights reserved.
                </Typography>
            </Section>
            {showRaphael && renderBottom()}
        </Fragment>
    );

    if (!isFixed) {
        return inner;
    }

    return (
        <motion.div
            initial="hidden"
            animate={footerVisible ? (footerExpanded ? 'expanded' : 'minimized') : 'hidden'}
            variants={variants}
            transition={{ type: 'spring', bounce: 0.15, duration: 0.3 }}
            className={clsx(classes.fixed)}
        >
            {inner}
        </motion.div>
    );
};
