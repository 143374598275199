import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from '../../layouts/main-layout';
import { Author } from '../../author';

export const AuthorRoutes = () => {
    return (
        <Switch>
            <Route exact path={['/author/:author']}>
                <MainLayout fullWidth={true} footerFixed={true}>
                    <Route
                        exact
                        path="/author/:author"
                        bar={true}
                        render={props => {
                            const slug = props.match.params.author;
                            return <Author key={slug} slug={slug} {...props}></Author>;
                        }}
                    />
                </MainLayout>
            </Route>
        </Switch>
    );
};

export default AuthorRoutes;
