import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteGuard } from '@/shared/route-guard';
import { MainLayout } from '../../layouts/main-layout';
import { Trivia } from '../../trivia';
import { Play as TriviaPlay } from '../../trivia/play';
import { get } from 'lodash';

export const TriviaRoutes = props => {
    const user = get(props, 'token.user');
    return (
        <Switch>
            <RouteGuard
                guards={['user', 'statusOkay']}
                exact
                path="/trivia/play"
                render={props => {
                    if (user) {
                        return <TriviaPlay {...props}></TriviaPlay>;
                    } else {
                        return <Redirect to="/trivia" />;
                    }
                }}
            />
            <Route exact path={['/trivia']}>
                <MainLayout>
                    <Route
                        exact
                        path="/trivia"
                        render={props => {
                            return <Trivia {...props}></Trivia>;
                        }}
                    />
                </MainLayout>
            </Route>
        </Switch>
    );
};

export default TriviaRoutes;
