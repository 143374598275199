import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { Avatar, Typography, Button, IconButton, Tabs, Tab } from '@mui/material';
import { StyledAppBar as AppBar, NavToolbar as Toolbar } from '@/shared/styles/appbar.styles';
import { PostContent } from '@/shared/components';
import FlexBox from '@/shared/components/flex-box/flex-box';
import ProgressBar from '@/shared/components/progress-bar/progress-bar';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import classes from './lesson.styles.module.scss';
import { has, get, isEqual } from 'lodash';
import { VimeoPlayer } from '@/shared/components/vimeo-player/vimeo-player';
import { Curriculum } from '../curriculum/curriculum';
import { TabPanel } from '@/shared/components/tab-panel/tab-panel.component';
import { useHistory } from 'react-router-dom';

export const Lesson = props => {
    const history = useHistory();
    const getLessons = () => {
        let lessons = [];
        curriculum.forEach(section => {
            lessons = [...lessons, ...section.lessons];
        });
        return lessons;
    };

    const getPrevLesson = () => {
        let prev = null;
        lessons.forEach((lesson, index, lessons) => {
            if (props.lesson.id == lesson.id && index > 0) {
                prev = lessons[index - 1];
            }
        });
        return prev;
    };

    const getCurrLesson = () => {
        return lessons.find(lesson => {
            return props.lesson.id == lesson.id;
        });
    };

    const getNextLesson = () => {
        let next = null;
        lessons.forEach((lesson, index, lessons) => {
            if (props.lesson.id == lesson.id && index < lessons.length) {
                next = lessons[index + 1];
            }
        });
        return next;
    };

    const canViewLesson = () => {
        // Sample Lessons
        if (get(props, 'lesson.is_sample', false)) return true;

        // Not logged in or enrolled
        if (!get(props, 'token.user') && !get(props, 'course.post_track.enrollment_flag')) return false;

        // Lesson progression is enabled
        if (lessonProgression) return true;

        const currentLesson = getCurrLesson();
        const currentLessonIsComplete = get(currentLesson, 'post_track.meta.is_complete', false);

        // Current lesson id is actual course current lesson id
        if (currentLesson.id == get(props, 'course.post_track.meta.current_lesson_id', lessons[0].id)) return true;

        // Current lesson is complete
        if (currentLessonIsComplete) return true;

        return false;
    };

    const [canShow, setCanShow] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const sidebarRef = useRef(null);

    const { curriculum, post_track } = props.course;
    const courseProgress = get(post_track, 'meta.current_progress', 0);
    const lessonProgressionDisabled = get(props.course, 'settings.course_disable_lesson_progression', '');
    const lessonProgression = lessonProgressionDisabled == 'on' ? true : false;

    const lessons = curriculum && getLessons();
    const prevLesson = curriculum && getPrevLesson();
    const currLesson = curriculum && getCurrLesson();
    const nextLesson = curriculum && getNextLesson();
    const startTime = get(props.lesson.post_track, 'meta.video_time', 0);
    let currentTime = 0;

    useEffect(() => {
        const courseHasChanged = has(props, 'course.slug') && !isEqual(props.course.slug, props.courseSlug);
        if (courseHasChanged) setCanShow(false);
        props.getResult(props.lessonId);
    }, [props.lessonId]);

    useDidUpdateEffect(() => {
        if (canViewLesson()) {
            setCanShow(true);
        } else {
            history.replace(props.course.link);
        }
    }, [props.loaded]);

    return (
        <Fragment>
            {canShow && (
                <FlexBox justifybetween>
                    <AppBar position={'fixed'} color="inherit">
                        <Toolbar>
                            <FlexBox row justifybetween style={{ width: '100%' }}>
                                <FlexBox row>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => {
                                            history.push('/courses');
                                        }}
                                    >
                                        <i className="far fa-chevron-left"></i>
                                    </IconButton>
                                </FlexBox>
                                <FlexBox row aligncenter>
                                    {prevLesson && (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                history.replace(prevLesson.link);
                                            }}
                                        >
                                            <i className="mr-2 fas fa-arrow-left"></i> Previous Lesson
                                        </Button>
                                    )}
                                    {nextLesson && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="ml-3"
                                            onClick={() => {
                                                props.saveProgress(props.lessonId, currentTime, true);
                                                history.replace(nextLesson.link);
                                            }}
                                        >
                                            Complete and continue <i className="ml-2 fas fa-arrow-right"></i>
                                        </Button>
                                    )}
                                </FlexBox>
                            </FlexBox>
                        </Toolbar>
                    </AppBar>

                    <FlexBox>
                        <div ref={sidebarRef} className={classes.sidebar}>
                            <div
                                className={classes.coursePhoto}
                                style={{
                                    backgroundImage: `url(${props.course.photos.medium.url})`,
                                }}
                            />

                            <h3 className={classes.courseTitle}>{props.course.title}</h3>

                            <div className={classes.progressWrapper}>
                                <ProgressBar progress={courseProgress} className={classes.progressBar} />
                                <span className={classes.progressPc}>{courseProgress}%</span>{' '}
                                <span className={classes.progressCo}>complete</span>
                            </div>

                            <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
                                <Tab label="Curriculum" />
                                <Tab label="Instructor" />
                            </Tabs>

                            <TabPanel value={tabValue} index={0}>
                                <Curriculum
                                    list={curriculum}
                                    lessonId={props.lesson.id}
                                    lessonProgression={lessonProgression}
                                    onCurrentLessonReady={el => {
                                        sidebarRef.current.scrollTo({
                                            top: el.offsetTop - 230,
                                            behavior: 'smooth',
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tabValue} index={1}>
                                <div className={classes.courseInstructor}>
                                    <Avatar
                                        alt={props.course.instructor_name}
                                        src={props.course.instructor_photo.url}
                                        className={classes.courseInstructorPhoto}
                                    />
                                    <Typography variant="h3" gutterBottom>
                                        {props.course.instructor_name}
                                    </Typography>
                                    <Typography variant="h6">
                                        <SafeHtml>{props.course.instructor_bio}</SafeHtml>
                                    </Typography>
                                </div>
                            </TabPanel>

                            <div className="mt-5"></div>
                        </div>

                        <div className={classes.content}>
                            <Typography variant="h3" gutterBottom className="mt-3">
                                {props.lesson.title}
                            </Typography>
                            <VimeoPlayer
                                url={props.lesson.video_url}
                                startTime={startTime}
                                onPause={data => {
                                    props.saveProgress(props.lessonId, data.seconds);
                                }}
                                onTimeUpdate={data => {
                                    currentTime = data.seconds;
                                }}
                            />
                            <PostContent content={props.lesson.content} onLinkClick={() => setCanShow(false)} />
                        </div>
                    </FlexBox>
                </FlexBox>
            )}
        </Fragment>
    );
};
