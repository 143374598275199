import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Signin } from '@/shared/auth/signin';
import { ForgotPassword } from '@/shared/auth/forgot-password';
import { ResetPassword } from '@/shared/auth/reset-password';
import { TwoFactorAuthentication } from '@/shared/auth/tfa';
import { Signup } from '@/shared/signup';
import { Verify } from '@/shared/verify';

export const GuestRoutes = ({ layout: MainLayout, signinHeading, signupHeading }) => {
    return (
        <Switch>
            <Route
                path={['/signin', '/pwdreset', '/signup']}
                render={() => {
                    return (
                        <MainLayout guest onlyLogo hideFooter={true}>
                            <Route
                                path="/signin"
                                exact
                                render={props => <Signin {...props} heading={signinHeading} />}
                            />
                            <Route
                                path={'/signin/tfa'}
                                exact
                                render={props => <TwoFactorAuthentication {...props} />}
                            />
                            <Route
                                path={'/signin/forgot_password'}
                                exact
                                render={props => <ForgotPassword {...props} />}
                            />
                            <Route
                                path={'/pwdreset/:token/:userId'}
                                exact
                                render={props => {
                                    const token = props.match.params.token;
                                    const userId = props.match.params.userId;
                                    return (
                                        <ResetPassword
                                            resetToken={token}
                                            userId={userId}
                                            {...props}
                                        ></ResetPassword>
                                    );
                                }}
                            />
                            <Route
                                path="/signup/verify"
                                exact
                                render={props => (
                                    <Verify {...props} context="signup" view="verify_code" />
                                )}
                            />
                            <Route
                                path="/signup"
                                exact
                                render={props => <Signup {...props} heading={signupHeading} />}
                            />
                        </MainLayout>
                    );
                }}
            ></Route>
        </Switch>
    );
};

export default GuestRoutes;
