import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Container } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './play.styles.module.scss';
import { Question } from '../question';

export const Play = ({ current_answers, history, clearQuestion }) => {
    useEffect(() => { }, []);
    const onBack = () => {
        clearQuestion();
        history.replace('/trivia');
    };

    const showRules = current_answers?.count == 0;

    return (
        <FlexBox className={classes.questionsContainer}>
            <Container maxWidth="md" className="mt-5 mt-m-4">
                <Question showRules={showRules} onBack={onBack}></Question>
            </Container>
        </FlexBox>
    );
};
