import React, { useRef } from 'react';
import { Confetti } from '@/shared/components';
import wrongAnswer from '../../wrong.json';
import rightAnswer from '../../correct.json';
import { Player } from '@lottiefiles/react-lottie-player';

export const QuestionResult = ({ isCorrect }) => {
    const lottieRef = useRef(null);

    return (
        <React.Fragment>
            <Player
                speed={1}
                autoplay={true}
                ref={lottieRef}
                keepLastFrame={true}
                src={isCorrect ? rightAnswer : wrongAnswer}
                style={{ height: '250px', width: '250px' }}
            ></Player>
            {isCorrect && <Confetti></Confetti>}
        </React.Fragment>
    );
};
