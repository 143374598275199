import { connect } from 'react-redux';
import { Report as ReportComponent } from './report.component';
import { reportQuestion } from '../trivia.actions';

const mapStateToProps = state => {
    return state.triviaReducer;
};

export const Report = connect(mapStateToProps, {
    reportQuestion,
})(ReportComponent);
