import moment from 'moment';

const initialState = {
    loaded: null,
    video: {},
    related: [],
};

export const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'VIDEO_REQUESTED':
            return {
                ...state,
            };
        case 'VIDEO_SUCCESS': {
            const { video, related } = action.payload;
            return {
                ...state,
                video,
                related,
                loaded: moment(),
            };
        }
        case 'VIDEO_FAILED':
            return {
                ...state,
                error: action.error,
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, view_count } = post_track;

            let video = state.video;
            let related = state.related;

            if (video.id == postable_id) {
                video.likes_count = likes_count;
                video.post_track = {
                    bookmark_flag,
                    like_flag,
                    view_count,
                };
            } else {
                related = state.related.map(art => {
                    if (art.id == postable_id) {
                        return {
                            ...art,
                            likes_count,
                            post_track: {
                                bookmark_flag,
                                like_flag,
                                view_count,
                            },
                        };
                    }
                    return art;
                });
            }

            return {
                ...state,
                video,
                related,
            };
        }
    }
};
