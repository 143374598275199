import { connect } from 'react-redux';
import { ErrorBoundary as ErrorBoundaryComponent } from './error-boundary.component';
import { resetStore, getHandshake, updateWebVersion } from '@/shared/auth/auth.actions.js';

const mapStateToProps = state => {
    return {};
};

export const ErrorBoundary = connect(mapStateToProps, {
    resetStore,
    getHandshake,
    updateWebVersion,
})(ErrorBoundaryComponent);
