import { connect } from 'react-redux';
import { Resources as ResourcesComponent } from './resources.component';
import { getResults } from './resources.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.resourcesReducer;
};

export const Resources = connect(mapStateToProps, {
    getResults,
    expireStore,
})(ResourcesComponent);
