import React, { Fragment, useEffect, useState } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { Avatar, Typography, Button } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { PostTrack } from '@/shared/articles';
import { Curriculum } from '../curriculum/curriculum';
import { PostGrid, PostContent, Section } from '@/shared/components';
import { Faqs } from './faqs/faqs';
import classes from './course.styles.module.scss';
import themes from './themes/themes';
import { get, has, isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

const ThemeDefault = props => {
    const [canShow, setCanShow] = useState(true);

    return (
        canShow && (
            <Fragment>
                <div
                    className={classes.hero}
                    style={{
                        backgroundImage: `url(${props.course.photos.large.url})`,
                    }}
                >
                    <Typography variant="h1" gutterBottom className={classes.title}>
                        {props.course.title}
                    </Typography>
                </div>

                <Section bgcolor="background000" size="md">
                    <PostTrack post={props.course} showLikes captureViews />
                    <PostContent content={props.course.content} />

                    {(props.course.is_bundle && (
                        <Fragment>
                            <Typography variant="h2" gutterBottom>
                                Courses Included with Purchase
                            </Typography>
                            <PostGrid results={props.course.bundled_courses} columns={2} onClick={() => setCanShow(false)} />
                        </Fragment>
                    )) || (
                        <Fragment>
                            <Typography variant="h2" gutterBottom>
                                Course Curriculum
                            </Typography>
                            <Curriculum list={props.course.curriculum} preview={true} />
                        </Fragment>
                    )}
                </Section>

                {!props.course.is_bundle && (
                    <Section bgcolor="background200" size="md">
                        <Typography variant="h2" gutterBottom>
                            Meet Your Instructor
                        </Typography>
                        <FlexBox row>
                            <Avatar
                                alt={props.course.instructor_name}
                                src={props.course.instructor_photo.url}
                                className={classes.instructorPhoto}
                            />
                            <div>
                                <Typography variant="h3" gutterBottom>
                                    {props.course.instructor_name}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {props.course.instructor_bio}
                                </Typography>
                            </div>
                        </FlexBox>
                    </Section>
                )}

                <Section bgcolor="background100" size="md">
                    <Typography variant="h2" gutterBottom>
                        Frequently Asked Questions
                    </Typography>
                    <Faqs
                        questionsAndAnswers={[
                            [
                                'When does the course start and finish?',
                                'The course starts now and never ends! It is a completely self-paced online course - you decide when you start and when you finish.',
                            ],
                            [
                                'How long do I have access to the course?',
                                'After enrolling, you have unlimited access to this course for as long as you like - across any and all devices you own.',
                            ],
                            [
                                'So, what am I getting from this course exactly?',
                                'You will get the tools you need to date more effectively, so that you spend more time dating great people, and less time in dead end relationships. You will also learn how to attract more dates by becoming the best YOU possible.',
                            ],
                            [
                                'What if I am unhappy with the course?',
                                'We would never want you to be unhappy! If you are unsatisfied with your purchase, contact us in the first 10 days and we will give you a full refund.',
                            ],
                            [
                                'Who do I contact if I have questions?',
                                'Our customer support team is ready to help! Just drop us a line.',
                            ],
                        ]}
                    />
                </Section>

                <Section bgcolor="default" size="md" innerStyle={{ textAlign: 'center' }}>
                    <Typography variant="h2" gutterBottom>
                        Get Started Now
                    </Typography>
                    <Button variant="contained" color="primary" size="large" className="ml-3" onClick={() => {}}>
                        Enroll
                    </Button>
                </Section>
            </Fragment>
        )
    );
};

export const Course = props => {
    const { curriculum } = props.course;
    const [canShow, setCanShow] = useState(false);
    const history = useHistory();

    const themeComponent = () => {
        const ThemeComponent = themes[props.course.id] ? themes[props.course.id] : ThemeDefault;
        return <ThemeComponent {...props} />;
    };

    const getLessons = () => {
        let lessons = [];
        curriculum.forEach(section => {
            lessons = [...lessons, ...section.lessons];
        });
        return lessons;
    };

    const getLessonById = id => {
        return lessons.find(lesson => {
            return id == lesson.id;
        });
    };

    const lessons = curriculum && getLessons();

    useEffect(() => {
        const courseHasChanged = has(props, 'course.slug') && !isEqual(props.course.slug, props.courseSlug);
        if (courseHasChanged) setCanShow(false);
        props.getResult(props.courseSlug);
    }, [props.courseSlug]);

    useDidUpdateEffect(() => {
        if (get(props, 'token.user') && get(props, 'course.post_track.enrollment_flag')) {
            const currentLessonId = get(props, 'course.post_track.meta.current_lesson_id', props.course.first_lesson_id);
            const currentLesson = getLessonById(currentLessonId);
            history.replace(currentLesson.link);
        } else {
            setCanShow(true);
        }
    }, [props.loaded]);

    return canShow && themeComponent();
};
