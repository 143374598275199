import { connect } from 'react-redux';
import { Home as HomeComponent } from './home.component';
import { getResults } from './home.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return { ...state.homeReducer, token: state.authReducer.token };
};

export const Home = connect(mapStateToProps, {
    getResults,
    expireStore,
})(HomeComponent);
