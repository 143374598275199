import { connect } from 'react-redux';
import { Redirection as RedirectionComponent } from './redirection.component';
import { clearRedirect } from './redirection.actions';

const mapStateToProps = state => {
    return {
        ...state.redirectionReducer,
        token: state.authReducer.token,
    };
};

export const Redirection = connect(mapStateToProps, { clearRedirect })(RedirectionComponent);
