import React, { Fragment, useEffect, useState } from 'react';
import SafeHtml from '@/shared/components/safe-html/safe-html';
import { Container } from '@mui/material';
import { isFunction } from 'lodash';
import classes from './post-content.module.scss';
import { decode } from 'html-entities';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { useHistory } from 'react-router-dom';
export const PostContent = ({ content, maxWidth = 'md', onLinkClick }) => {
    const [lbOpen, setLbOpen] = useState(false);
    const [lbIndex, setLbIndex] = useState(0);
    const [lbImages, setLbImages] = useState([]);
    const history = useHistory();
    const handleImages = () => {
        let images = [];
        let nodes = document.querySelectorAll('.wp-content .wp-block-image');

        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].querySelector('a')) {
                let anchor = nodes[i].querySelector('a');
                if (anchor.href.match(/\.(jpg|jpeg|png|gif)$/)) {
                    images.push({ src: anchor.href });
                    anchor.onclick = e => {
                        e.preventDefault();
                        setLbIndex(i);
                        setLbOpen(true);
                    };
                }
            }
            if (nodes[i].querySelector('figcaption')) {
                let caption = nodes[i].querySelector('figcaption');
                images[i].description = caption;
            }
        }

        setLbImages(images);
    };

    const handleLinks = () => {
        let nodes = document.querySelectorAll('.wp-content a');
        for (let i = 0; i < nodes.length; i++) {
            let anchor = nodes[i];
            if (!anchor.href) continue;
            const url = new URL(anchor.href);
            if (url.host.match(/catholicmatch\.com/)) {
                if (url.pathname.match(/wp-content\/uploads/)) continue;

                // Check for inline anchor
                if (url.pathname == window.location.pathname && url.hash) {
                    continue;
                }

                anchor.onclick = e => {
                    e.preventDefault();
                    isFunction(onLinkClick) && onLinkClick(e);
                    history.push(url.pathname);
                };
            } else {
                anchor.setAttribute('target', 'top');
            }
            anchor.innerHTML = decode(anchor.innerHTML);
        }
    };

    const handleTweets = () => {
        const clickToTweets = document.getElementsByClassName('.wp-content clicktotweet');
        for (const c of clickToTweets) {
            c.onclick = e => {
                const text = e.target.innerText;
                const href =
                    'https://twitter.com/intent/tweet?via=catholicmatch&related=catholicmatch&url=' +
                    window.location.href +
                    '&text=' +
                    text;
                window.open(href, 'tweetWin', 'width=600,height=600');
            };
        }
    };

    useEffect(() => {
        handleImages();
        handleLinks();
        handleTweets();
    }, [content]);

    return (
        <Fragment>
            {lbOpen && (
                <Lightbox
                    plugins={[Captions, Thumbnails]}
                    open={lbOpen}
                    close={() => setLbOpen(false)}
                    slides={lbImages}
                    index={lbIndex}
                />
            )}
            <Container disableGutters maxWidth={maxWidth} className={classes.test}>
                <SafeHtml className="wp-content">{content}</SafeHtml>
            </Container>
        </Fragment>
    );
};
