import { connect } from 'react-redux';
import { Signup as SignupComponent } from './signup.component';
import { signup } from './signup.actions';

const mapStateToProps = state => {
    return {
        ...state.signupReducer,
        token: state.authReducer.token,
        sid: state.verifyReducer.sid,
    };
};

export const Signup = connect(mapStateToProps, { signup })(SignupComponent);
