import { connect } from 'react-redux';
import { ForgotPassword as ForgotPasswordComponent } from './forgot-password.component';
import { sendPasswordResetLink } from './forgot-password.actions';

const mapStateToProps = state => {
    return {
        ...state.forgotPasswordReducer,
        token: state.authReducer.token,
    };
};

export const ForgotPassword = connect(mapStateToProps, {
    sendPasswordResetLink,
})(ForgotPasswordComponent);

