import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import clsx from 'clsx';
import classes from './section.module.scss';
import { useIsLightTheme } from '@/shared/util/hooks';

export const Section = props => {
    const theme = useTheme();
    const isLightTheme = useIsLightTheme();
    const { bgcolor, className, style, size = 'xl' } = props;

    const bgcolors = {
        default: theme.palette.background['100'],
        background000: theme.palette.background['000'],
        background100: theme.palette.background['200'],
        background200: theme.palette.background['300'],
        lightgray: isLightTheme ? theme.palette.grey['300'] : theme.palette.grey['700'],
        darkgray: isLightTheme ? theme.palette.grey['700'] : theme.palette.grey['300'],
        black: isLightTheme ? theme.palette.grey['900'] : theme.palette.grey['100'],
    };

    const backgroundColor = bgcolors[bgcolor] || bgcolors['default'];
    const sectionStyles = { backgroundColor, ...style };

    return (
        <div className={clsx(classes.section, className)} style={sectionStyles}>
            <Container disableGutters maxWidth={size}>
                {props.children}
            </Container>
        </div>
    );
};
