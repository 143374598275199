import axiosAPI from '@/shared/api';

export const getResult = id => async dispatch => {
    dispatch(resultRequested());

    return axiosAPI()
        .get(`/video/${id}`)
        .then(response => {
            dispatch(resultSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultFailed(error.response));
        });
};

function resultRequested() {
    return {
        type: 'VIDEO_REQUESTED',
    };
}

function resultFailed(error) {
    return {
        type: 'VIDEO_FAILED',
        error,
    };
}

function resultSuccess(payload) {
    return {
        type: 'VIDEO_SUCCESS',
        payload,
    };
}
