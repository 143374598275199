import React from 'react';
import { Helmet } from 'react-helmet';
import { facebook_id, twitter_id } from 'config';
import { has } from 'lodash';
import { decode } from 'html-entities';

export const PostMeta = ({ post }) => {
    const { meta } = post;

    const title = decode(meta.title);
    const description = decode(meta.description);

    if (!meta) {
        window.Sentry.captureException(new Error(`Meta data null for post: ${post.id}`));
        return (
            <Helmet>
                <title>{decode(post.title)}</title>
            </Helmet>
        );
    }

    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={meta.canonical} />

            <meta name="author" content={post.author.name} />
            <meta name="description" content={description} />
            {has(meta, 'robots') && <meta name="robots" content={meta.robots} />}

            <meta property="fb:app_id" content={facebook_id} />
            <meta property="og:site_name" content="CatholicMatch - Faith Focused Dating" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={meta.url} />
            {has(meta, 'image') && <meta property="og:image" content={meta.image.url} />}
            {has(meta, 'image') && <meta property="og:image:width" content={meta.image.width} />}
            {has(meta, 'image') && <meta property="og:image:height" content={meta.image.height} />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={`@${twitter_id}`} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:url" content={meta.url} />
            {has(meta, 'image') && <meta name="twitter:image" content={meta.image.url} />}
        </Helmet>
    );
};
