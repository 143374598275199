import { connect } from 'react-redux';
import { TwoFactorAuthentication as TFAComponent } from './tfa.component';
import { selectTFAChannel, sendTFACode, confirmTFACode, trustDevice } from './tfa.actions';
import { getUser } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return {
        ...state.tfaReducer,
    };
};

export const TwoFactorAuthentication = connect(mapStateToProps, {
    selectTFAChannel,
    sendTFACode,
    confirmTFACode,
    trustDevice,
    getUser,
})(TFAComponent);
