import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loading: true,
    courses: [],
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
    lastLoadAt: null,
};

export const coursesReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'COURSES_REQUESTED':
            return {
                ...state,
                loading: true,
                courses: action.payload.page == 1 ? [] : state.courses,
            };
        case 'COURSES_SUCCESS': {
            let courses = action.payload.courses;
            const pagination = action.payload.courses_pagination;

            if (pagination.current_page > 1) {
                courses = [...state.courses, ...courses];
                courses = uniqBy(courses, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                courses,
                ...action.payload.courses_pagination,
                loading: false,
                lastLoadAt: moment(),
            };
        }
        case 'COURSES_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const {
                postable_id,
                bookmark_flag,
                like_flag,
                enrollment_flag,
            } = post_track;

            return {
                ...state,
                courses: state.courses.map(a => {
                    if (a.id == postable_id)
                        return {
                            ...a,
                            likes_count: likes_count,
                            post_track: {
                                ...a.post_track,
                                bookmark_flag,
                                like_flag,
                                enrollment_flag,
                            },
                        };
                    return a;
                }),
            };
        }

        case 'EXPIRE_STORE': {
            if (
                action.force ||
                (state.lastLoadAt &&
                    moment(state.lastLoadAt) < moment().subtract(60, 'minutes'))
            ) {
                return initialState;
            }
            return { ...state };
        }
    }
};
