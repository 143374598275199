import { connect } from 'react-redux';
import { Courses as CoursesComponent } from './courses.component';
import { getResults } from './courses.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.coursesReducer;
};

export const Courses = connect(mapStateToProps, {
    getResults, expireStore
})(CoursesComponent);
