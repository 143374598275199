import React, { useEffect, Fragment } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import { PostGrid } from '@/shared/components';
import { Typography, Grid } from '@mui/material';
import classes from './videos.styles.module.scss';
import clsx from 'clsx';
import helpers from '@/shared/util/helpers';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { get } from 'lodash';

const { setTitle } = helpers;

export const Videos = props => {
    const getResults = () => {
        const qparams = queryString.parse(props.location.search);
        let page = get(qparams, 'page', 1);

        if (props.total == 0) {
            if (!window.Renderfy) page = 1;
            props.getResults(props.slug, page, qparams);
        }
    };

    useEffect(() => {
        props.expireStore();
        getResults();
    }, []);

    useDidUpdateEffect(() => {
        getResults();
    }, [props.total]);

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Videos')}</title>
            </Helmet>
            <div id="videos" className="pb-6">
                <Typography variant="h3" className={clsx(classes.pageTitle, 'my-5')}>
                    Videos
                </Typography>
                <PostGrid showAsListItem={!window.isDesktop} resultsKey="videos" {...props} />
            </div>
        </Fragment>
    );
};
