import { connect } from 'react-redux';
import { Courses as CoursesComponent } from './courses.component';
import { getResults } from '../library.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.libraryCoursesReducer;
};

export const Courses = connect(mapStateToProps, {
    getResults,
    expireStore,
})(CoursesComponent);
