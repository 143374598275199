import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import classes from './share-story.styles.module.scss';
import clsx from 'clsx';
import { app_url } from 'config';
export const ShareStory = props => {
    return (
        <Container maxWidth="md" className={clsx(classes.container, 'p-4 p-m-3')}>
            <Typography
                style={{
                    fontSize: 22,
                }}
                variant="h5"
                className={'mb-3'}
            >
                Found the love of your life on CatholicMatch?
            </Typography>

            <Button
                href={`${app_url}/share`}
                target="_blank"
                className={classes.btn}
                variant="contained"
                color="primary"
            >
                Share Your Story
            </Button>
            <img
                className={classes.img}
                height={window.isDesktop ? 190 : 150}
                src="https://d2q86h5pvn46dj.cloudfront.net/website/hearts.png"
                alt="CatholicMatch"
            />
        </Container>
    );
};
