import { connect } from 'react-redux';
import { Author as AuthorComponent } from './author.component';
import { getResults } from './author.actions';
import { expireStore } from '@/shared/auth/auth.actions';

const mapStateToProps = state => {
    return state.authorReducer;
};

export const Author = connect(mapStateToProps, {
    getResults, expireStore
})(AuthorComponent);
