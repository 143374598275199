import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDidUpdateEffect } from '@/shared/util/hooks';

export const Redirection = ({ token, canRedirect, redirectUrl, redirectState, clearRedirect }) => {
    useDidUpdateEffect(() => {
        if (canRedirect) {
            clearRedirect();
        }
    }, [canRedirect]);

    const redirect = (url, state) => {
        const isAbsolute = /^https?:\/\//gi.test(url);
        const urlObject = new URL(isAbsolute ? url : window.location.origin + url);
        const isSameOrigin = window.location.origin == urlObject.origin;
        const isApi = /^\/api\//.test(urlObject.pathname);
        const urlHasChanged = window.location.href != urlObject.href;
        let isReactRoute =
            !/^\/(splash|about|help|promo|forums|admin)/gi.test(urlObject.pathname) && isSameOrigin;

        if (urlObject.pathname == '/' && !token?.user) {
            isReactRoute = false;
        }

        if (isApi) {
            window.Sentry.captureException(new Error(`Redirect called for api url: ${url}`));
            return null;
        }

        if (!urlHasChanged) {
            //window.Sentry.captureException(new Error(`Redirect called for same url: ${url}`));
            return null;
        }

        if (isReactRoute) {
            return (
                <Redirect
                    to={{
                        pathname: urlObject.pathname,
                        state,
                    }}
                />
            );
        } else {
            window.localStorage.setItem('externalRedirectInProgress', true);
            window.location.href = url;
            return null;
        }
    };

    if (redirectUrl && canRedirect) {
        return redirect(redirectUrl, redirectState);
    }

    return null;
};
