// Signup
import React, { Fragment, useRef } from 'react';
import { Helmet } from 'react-helmet';
import helpers from '@/shared/util/helpers';
import { useInput } from '@/shared/util/hooks';
import { Link } from 'react-router-dom';
import {
    Button,
    Container,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Link as ExternalLink,
} from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { PhoneInput } from '@/shared/components/phone-input';
import history from '@/shared/history';
import { app_url } from 'config';
import { useDidUpdateEffect } from '@/shared/util/hooks';

export const Signup = ({ isComponent, heading, ...props }) => {
    const { value: email, bind: bindEmail } = useInput('');
    const { value: password, bind: bindPassword } = useInput('');
    const { value: terms_of_use, bind: bindTerms } = useInput(false);
    const { value: phone_code, setValue: setPhoneCode } = useInput('');
    const { value: phone_number, bind: bindPhoneNumber } = useInput('');
    const { verify_required, country } = props.token.guest;

    const buttonRef = useRef(null);

    useDidUpdateEffect(() => {
        if (props.sid) {
            history.push('/signup/verify');
        }
    }, [props.sid]);

    const handleEnterPress = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            event.preventDefault();
            signupUser();
        }
    };

    const signupUser = () => {
        let request = {
            email,
            password,
            terms_of_use,
        };

        if (verify_required == 'verify_phone') {
            const fullNumber = phone_number ? `+${phone_code}${phone_number}` : null;
            request = { ...request, phone_number: fullNumber };
        }

        props.signup(request);
    };

    const handleCountrySelect = (country, code) => {
        setPhoneCode(code);
    };

    const { setTitle } = helpers;

    const inner = (
        <Fragment>
            <TextField
                {...bindEmail}
                className={'w-100'}
                variant="outlined"
                name="email"
                label="Email Address"
                inputProps={{
                    autoCapitalize: 'none',
                    autoComplete: 'on',
                    autoCorrect: 'off',
                }}
                onKeyPress={handleEnterPress}
            />
            <TextField
                {...bindPassword}
                className="mt-3 w-100"
                variant="outlined"
                label="Password"
                type="password"
                name="password"
                autoComplete="current-password"
                inputProps={{
                    autoCapitalize: 'none',
                    autoComplete: 'off',
                    autoCorrect: 'off',
                }}
                onKeyPress={handleEnterPress}
            />
            {verify_required == 'verify_phone' && (
                <PhoneInput
                    {...bindPhoneNumber}
                    key="phone_number"
                    className="mt-3 w-100"
                    label="Phone"
                    onKeyPress={handleEnterPress}
                    initialCountry={country}
                    onCountrySelect={handleCountrySelect}
                />
            )}
            <FormControlLabel
                className="w-100 mt-3"
                control={
                    <Checkbox
                        {...bindTerms}
                        checked={terms_of_use}
                        name="terms_of_use"
                        color="primary"
                    />
                }
                label={
                    <Typography>
                        I agree to the{' '}
                        <ExternalLink target="_blank" href={`${app_url}/terms`}>
                            terms and conditions
                        </ExternalLink>
                    </Typography>
                }
            />
            <Button
                className="mt-3 w-100"
                variant="contained"
                color="primary"
                size="large"
                disableRipple
                ref={buttonRef}
                onClick={signupUser}
            >
                Continue
            </Button>
        </Fragment>
    );

    if (isComponent) {
        return inner;
    }

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Sign Up')}</title>
            </Helmet>
            <Container maxWidth="xs" className="py-5">
                <FlexBox column aligncenter w100>
                    <Typography align="center" variant="h2" component="h1" className="mb-m-4 mb-5">
                        {heading}
                    </Typography>
                    {inner}
                    <Typography color="textSecondary" className="mt-4" variant="subtitle2">
                        Already a member?{' '}
                        <Link to="/signin">
                            <Typography color="primary" variant="subtitle2" component="span">
                                Sign In
                            </Typography>
                        </Link>
                    </Typography>
                </FlexBox>
            </Container>
        </Fragment>
    );
};
