import React, { useEffect, useState } from 'react';
import { Typography, ButtonBase } from '@mui/material';
import classes from './question-content.styles.module.scss';
import clsx from 'clsx';

const Option = ({ value, text, onSelect, showResult, isCorrect }) => {
    return (
        <ButtonBase
            onClick={() => onSelect(value)}
            key={value}
            className={clsx(
                classes.option,
                showResult && (isCorrect ? classes.correct : classes.incorrect)
            )}
        >
            <Typography align="center" variant="h5">
                {text}
            </Typography>
        </ButtonBase>
    );
};

export const QuestionContent = ({ question, onAnswer, showAnswer }) => {
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (selected) {
            onAnswer(selected);
        }
    }, [selected]);

    const onSelect = option => {
        if (!selected) {
            setSelected(option);
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h3" align="center" className="mb-4 mt-4">
                {question.question_text}
            </Typography>

            {Object.keys(question.options).map(key => {
                const showResult =
                    selected == key || (showAnswer && question.correct_answer == key);
                return (
                    <Option
                        key={key}
                        value={key}
                        isCorrect={question.correct_answer == key}
                        showResult={showResult}
                        text={question.options[key]}
                        onSelect={onSelect}
                    ></Option>
                );
            })}
        </React.Fragment>
    );
};
