import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { UserProgress } from './user-progress';
import { Scores } from './scores';
import { Stats } from './stats';
import { useHistory } from 'react-router-dom';

export const Trivia = ({ getTrivia }) => {
    const history = useHistory();
    useEffect(() => {
        getTrivia();
    }, []);

    const onPlay = () => {
        history.push('/trivia/play');
    };

    return (
        <Container disableGutters maxWidth="md" className="my-5">
            <UserProgress onPlay={onPlay}></UserProgress>
            <Stats></Stats>
            <div className="mt-4 mt-m-4">
                <Scores></Scores>
            </div>
        </Container>
    );
};
