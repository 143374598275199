import moment from 'moment';

const initialState = {
    loaded: null,
    page: {},
};

export const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'PAGE_REQUESTED':
            return {
                ...state,
            };
        case 'PAGE_SUCCESS': {
            const page = action.payload;
            return {
                ...state,
                page,
                loaded: moment(),
            };
        }
        case 'PAGE_FAILED':
            return {
                ...state,
                error: action.error,
            };
    }
};
