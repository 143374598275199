import React, { useState, useEffect } from 'react';
import Modal from '@/shared/components/modal/modal';
import FlexBox from '@/shared/components/flex-box/flex-box';
import Cookies from 'js-cookie';
import { TextField, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import { useInput } from '@/shared/util/hooks';
import _ from 'lodash';
import classes from './newsletter-signup.styles.module.scss';
import clsx from 'clsx';

export const NewsletterSignup = props => {
    const { value: email, setValue: setEmailValue, bind: bindEmail } = useInput('');
    const [selections, setSelections] = useState({});
    const [isVisible, setIsVisible] = useState(false);

    bindEmail.onChange = event => {
        setEmailValue(event.target.value.replace(/ /g, ''));
    };

    useEffect(() => {
        if (!Cookies.get('newsletter')) {
            props.getNewsletters();
            const expires = _.has(props, 'token.user') ? 180 : 30;
            Cookies.set('newsletter', 1, { expires });
        }
    }, []);

    useEffect(() => {
        if (props.visible) {
            setTimeout(() => setIsVisible(true), 10000);
        } else {
            setIsVisible(false);
        }
    }, [props.visible]);

    useEffect(() => {
        let map = {};
        if (props.newsletters.length) {
            props.newsletters.map(newsletter => {
                map = { ...map, [newsletter.title]: true };
            });
            setSelections(map);
        }
    }, [props.newsletters]);

    const toggleNewsletter = event => {
        const map = { ...selections, [event.target.value]: !selections[event.target.value] };
        setSelections(map);
    };

    const subscribe = () => {
        const selectedNewsletters = Object.keys(selections).reduce((result, selection, index) => {
            if (selections[selection]) {
                result.push(props.newsletters[index].title);
            }
            return result;
        }, []);
        props.subscribeToNewsletter({ email, list: selectedNewsletters });
    };

    if (isVisible) {
        return (
            <Modal
                closeClassName={classes.close}
                onClose={() => props.hideNewsletterSignupModal()}
                size="auto"
                mobileFullscreen={false}
            >
                <FlexBox className={clsx(window.isDesktop && 'mw-400', 'p-3 mx-auto')}>
                    <img
                        src="https://d2q86h5pvn46dj.cloudfront.net/website/plus_newsletter.png"
                        className={classes.img}
                        alt="singles, couples, church & priest"
                    />
                    <FlexBox className="mt-4">
                        <div id="newsletter-response" className="hidden"></div>
                        <Typography className={classes.title} variant="h2" align="center">
                            Keep In Touch With Us!
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            component="label"
                            htmlFor="email"
                            className="sr-only"
                        >
                            Email
                        </Typography>
                        <TextField
                            className={classes.input}
                            {...bindEmail}
                            placeholder="Email Address"
                            id="email"
                            type="email"
                            variant="outlined"
                            size={'small'}
                            rows={1}
                        />
                        <Typography color={'textSecondary'} variant="body2" className="mt-3 mb-0">
                            Which content do you want to receive?
                        </Typography>
                        <FlexBox row wrap className={classes.checkContainer}>
                            {props.newsletters.length &&
                                props.newsletters.map((value, index) => {
                                    const isEven = index % 2 == 0;
                                    return (
                                        <div
                                            key={index}
                                            className={clsx(
                                                classes.checkbox,
                                                isEven && classes.checkbox55
                                            )}
                                        >
                                            <FormControlLabel
                                                className={classes.checkboxLabel}
                                                label={value.title}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        name="list[]"
                                                        checked={
                                                            _.isEmpty(selections)
                                                                ? false
                                                                : selections[value.title]
                                                        }
                                                        onChange={toggleNewsletter}
                                                        value={value.title}
                                                    />
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </FlexBox>

                        <Button
                            size="large"
                            className="mt-3"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={subscribe}
                        >
                            Subscribe
                        </Button>
                    </FlexBox>
                </FlexBox>
            </Modal>
        );
    }
    return null;
};
