import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (type = 'articles', page = 1, criteria = {}) => async dispatch => {
    criteria = { ...criteria, page };
    dispatch(resultsRequested(type, criteria, page));

    let url = '/library';
    if (type) {
        url += `/${type}`;
    }

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(resultsSuccess(type, response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(type, error.response));
        });
};

function resultsRequested(resultType, criteria, page) {
    const type = `LIBRARY_${resultType.toUpperCase()}_REQUESTED`;
    return {
        type,
        payload: {
            criteria,
            page,
        },
    };
}

function resultsFailed(resultType, error) {
    const type = `LIBRARY_${resultType.toUpperCase()}_FAILED`;
    return {
        type,
        error,
    };
}

function resultsSuccess(resultType, payload) {
    const type = `LIBRARY_${resultType.toUpperCase()}_SUCCESS`;
    return {
        type,
        payload,
    };
}
