import React, { useEffect, useState } from 'react';
import helpers from '@/shared/util/helpers';
import { Typography, Avatar, Grid, ButtonBase, Link } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './rank-list.styles.module.scss';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { app_url } from 'config';

const colors = ['#edb210', '#47bcd1', '#e83755'];
const RankItem = ({ player, index, isCurrent = true }) => {
    const score = parseInt(player._score);

    const highlightPlace = isCurrent && index < 3;

    return (
        <div className={clsx('mb-1', classes.item)}>
            <div
                style={{ width: `${(score / 40000) * 100}%` }}
                className={classes.pointRatio}
            ></div>
            <FlexBox style={{ position: 'relative' }} w100 aligncenter h100 row>
                <FlexBox
                    justifycenter
                    aligncenter
                    style={{ opacity: highlightPlace ? 1 - index * 0.2 : 1 }}
                    className={clsx(classes.ranking, highlightPlace && classes.highlightRank)}
                >
                    #{player._ranking}
                </FlexBox>

                <ButtonBase
                    className="ml-4 mr-4 ml-m-4"
                    href={`${app_url}/profile/${player.user_id}`}
                    component="a"
                >
                    <Avatar
                        className={classes.avatar}
                        alt={player.user.first_name}
                        src={player._photo}
                    ></Avatar>
                </ButtonBase>
                <FlexBox className="mr-4">
                    <Typography className={classes.nameText} variant={'h5'}>
                        <Link
                            className={classes.link}
                            to={`${app_url}/profile/${player.user_id}`}
                        >
                            {player.user.first_name}
                        </Link>
                    </Typography>
                </FlexBox>
                <FlexBox className="ml-auto" row>
                    <FlexBox className={classes.score}>
                        <Typography variant={'h4'} className={classes.scoreText}>
                            {player._score}
                        </Typography>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </div>
    );
};

export const RankList = ({ users, isCurrent }) => {
    if (!users) {
        return null;
    }

    const playerList = users.map((player, i) => {
        return (
            <motion.div key={player.user_id}>
                <RankItem isCurrent={isCurrent} index={i} player={player}></RankItem>
            </motion.div>
        );
    });
    return <motion.div className={classes.mainContainer}>{playerList}</motion.div>;
};
