import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loading: true,
    articles: [],
    term: null,
    criteria: null,
    total: 0,
    count: 0,
    per_page: 0,
    current_page: 0,
    total_pages: 0,
};

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'SEARCH_REQUESTED': {
            const isNew = state.term !== action.payload.criteria?.s;
            return {
                ...state,
                loading: true,
                criteria: action.payload.criteria,
                articles: isNew ? [] : state.articles,
            };
        }
        case 'SEARCH_SUCCESS': {
            let articles = action.payload.articles;
            const pagination = action.payload.articles_pagination;
            const term = action.payload.term;

            if (pagination.current_page > 1) {
                articles = [...state.articles, ...articles];
                articles = uniqBy(articles, r => {
                    return r.id;
                });
            }
            return {
                ...state,
                articles,
                ...action.payload.articles_pagination,
                term,
                loading: false,
                lastLoadAt: moment(),
            };
        }
        case 'SEARCH_FAILED':
            return {
                ...state,
                error: action.error,
                loading: false,
                criteria: {},
            };

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            return {
                ...state,
                articles: state.articles.map(a => {
                    if (a.id == postable_id)
                        return {
                            ...a,
                            likes_count: likes_count,
                            post_track: {
                                ...a.post_track,
                                bookmark_flag,
                                like_flag,
                                enrollment_flag,
                            },
                        };
                    return a;
                }),
            };
        }

        case 'EXPIRE_STORE': {
            if (
                action.refresh ||
                (state.lastLoadAt && moment(state.lastLoadAt) < moment().subtract(60, 'minutes'))
            ) {
                return initialState;
            }
            return { ...state };
        }
    }
};
