import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from '../../layouts/main-layout';
import { Courses } from '../../courses';
import { Course } from '../../courses/course';
import { Lesson } from '../../courses/lesson';

export const CourseRoutes = () => {
    return (
        <Switch>
            <Route path="/courses">
                <MainLayout>
                    <Route
                        path="/courses"
                        exact
                        render={props => {
                            return <Courses {...props}></Courses>;
                        }}
                    />
                </MainLayout>
            </Route>

            <Route exact path={['/course/:slug']}>
                <MainLayout fullWidth={true}>
                    <Route
                        exact
                        path="/course/:slug"
                        render={props => {
                            const { slug } = props.match.params;
                            return <Course key={slug} courseSlug={slug} {...props}></Course>;
                        }}
                    />
                </MainLayout>
            </Route>
            <Route exact path={['/course/:slug/lesson/:id']}>
                <Route
                    exact
                    path="/course/:slug/lesson/:id"
                    render={props => {
                        const { slug, id } = props.match.params;
                        return (
                            <Lesson key={id} courseSlug={slug} lessonId={id} {...props}></Lesson>
                        );
                    }}
                />
            </Route>
        </Switch>
    );
};

export default CourseRoutes;
