import React, { Fragment, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useInput, useDidUpdateEffect } from '@/shared/util/hooks';
import { Button, Typography, OutlinedInput, Link } from '@mui/material';
import history from '@/shared/history';
import { PostGrid } from '@/shared/components';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './search.styles.module.scss';
import { isEqual } from 'lodash';
import helpers from '@/shared/util/helpers';

export const Search = props => {
    const { value: term, bind: bindTerm, setValue: setTerm } = useInput('');
    const dismissKeyboardRef = useRef();

    const termHasChanged = props.term && !isEqual(props.term, props.queryTerm);

    useEffect(() => {
        setTerm(props.queryTerm);
        if (props.total == 0 || termHasChanged) {
            props.getResults(props.queryTerm, 1);
        }
    }, [props.queryTerm]);

    useDidUpdateEffect(() => {
        if (termHasChanged && props.term) {
            dismissKeyboardRef.current.focus();
            history.replace({
                pathname: props.location.pathname,
                search: `?s=${props.term}`,
            });

            setTerm(props.term);
        }
    }, [props.term]);

    const doSearch = () => {
        if (term != props.queryTerm) {
            props.getResults(term, 1);
        }
    };

    const handleEnterPress = e => {
        const code = e.keyCode || e.which;

        if (code === 13 && e.target.value.length > 2) {
            e.preventDefault();
            doSearch();
        }
    };
    const { setTitle } = helpers;

    return (
        <Fragment>
            <Helmet>
                <title>{setTitle('Search Results')}</title>
            </Helmet>
            <Typography variant="h3" className="my-5">
                {props.total > 0 && props.total} Search Results
            </Typography>
            <FlexBox row className="mb-5">
                <OutlinedInput
                    {...bindTerm}
                    style={{ height: 42 }}
                    name="term"
                    className={classes.input}
                    id="term-input"
                    placeholder="Type term"
                    onChange={e => {
                        setTerm(e.target.value);
                    }}
                    onKeyPress={handleEnterPress}
                />
                <Button
                    ref={dismissKeyboardRef}
                    disableRipple
                    variant="contained"
                    color="primary"
                    className="ml-3"
                    disabled={term && term.length < 3 ? true : false}
                    onClick={doSearch}
                >
                    Search
                </Button>
            </FlexBox>

            <PostGrid
                placeholderTitle={!props.term && 'Start Searching!'}
                placeholderMessage={!props.term && 'What are you interested in?'}
                showAsListItem={!window.isDesktop}
                resultsKey="articles"
                {...props}
            />
        </Fragment>
    );
};
