import React, { useEffect, useState } from 'react';
import classes from './scroll-popup.styles.module.scss';
import { throttle } from 'lodash';

export const ScrollPopup = ({ scrollThreshold = 600, top, bottom, right, left, children }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', scrollThrottled);
        return () => {
            window.removeEventListener('scroll', scrollThrottled);
        };
    }, []);

    const scroll = function scroll(e) {
        if (e.target.documentElement.scrollTop > scrollThreshold) {
            if (!show) {
                setShow(true);
                window.removeEventListener('scroll', scrollThrottled);
            }
        }
    };

    const scrollThrottled = throttle(scroll, 100);
    if (!show) return null;

    const topPoint = top >= 0 ? top : 'auto';
    const bottomPoint = bottom >= 0 ? bottom : 'auto';
    const rightPoint = right >= 0 ? right : 'auto';
    const leftPoint = left >= 0 ? left : 'auto';

    return (
        <div
            style={{ top: topPoint, bottom: bottomPoint, right: rightPoint, left: leftPoint }}
            className={classes.popup}
        >
            {children}
        </div>
    );
};
