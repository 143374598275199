import React, { useEffect, useState, Fragment } from 'react';
import { useDidUpdateEffect } from '@/shared/util/hooks';
import classes from './tfa.styles.module.scss';
import { Button, Typography, Container } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import Confirm from '@/shared/components/confirm/confirm';
import { VerifyCode } from '@/shared/verify/verify.component';

export const TwoFactorAuthentication = props => {
    const [showSelectChannel, setShowSelectChannel] = useState(false);
    const [sentChannel, setSentChannel] = useState('');

    useEffect(function () {
        props.selectTFAChannel();
    }, []);

    useDidUpdateEffect(() => {
        if (props.channels && !sentChannel) {
            const primary = props.channels.find(obj => obj.is_primary === 1);
            setSentChannel(primary);
        }
    }, [props.channels]);

    const doConfirmTrust = () => {
        props.trustDevice({ device_token: props.device_token });
    };

    const doCancelTrust = () => {
        props.getUser();
    };

    const EnterCode = props => {
        return (
            <Fragment>
                {props.confirmSuccess && (
                    <Confirm
                        content="Add this browser to your trusted devices?"
                        onCancel={doCancelTrust}
                        onConfirm={doConfirmTrust}
                        confirmText="Trust"
                    ></Confirm>
                )}
                <VerifyCode
                    channel={sentChannel}
                    onSubmit={code => {
                        props.confirmTFACode(code);
                    }}
                    onResend={() => {
                        setShowSelectChannel(true);
                    }}
                />
            </Fragment>
        );
    };

    const SelectChannel = props => {
        const primary = props.channels.find(obj => obj.is_primary === 1);
        const secondary = props.channels.find(obj => obj.is_primary === 0);

        const resendCode = channel => {
            setSentChannel(channel);
            props.sendTFACode({ channel });
            setShowSelectChannel(false);
        };

        return (
            <React.Fragment>
                <Typography variant="h2" component="h1">
                    Didn't get a verification code?
                </Typography>
                <Typography className="mt-2 mb-4" variant="body1">
                    Select how we should send you the code
                </Typography>
                <Container maxWidth="xs" disableGutters>
                    <FlexBox w100 className={classes.resendOption} row justifybetween aligncenter>
                        <Typography variant="h6" component="h1">
                            {primary.type == 'SMS' ? 'Send a text' : 'Send an email'} to{' '}
                            {primary.value}
                        </Typography>
                        <Button
                            className="ml-3"
                            onClick={() => resendCode(primary.type.toLowerCase())}
                            color="primary"
                            size="small"
                        >
                            Resend
                        </Button>
                    </FlexBox>
                    {secondary && (
                        <FlexBox
                            w100
                            className={classes.resendOption}
                            row
                            justifybetween
                            aligncenter
                        >
                            <Typography variant="h6" component="h1">
                                {secondary.type == 'SMS' ? 'Send a text' : 'Send an email'} to{' '}
                                {secondary.value}
                            </Typography>
                            <Button
                                className="ml-3"
                                onClick={() => resendCode(secondary.type.toLowerCase())}
                                color="primary"
                                size="small"
                            >
                                Resend
                            </Button>
                        </FlexBox>
                    )}
                </Container>
                <a
                    href="https://catholicmatch.zendesk.com/"
                    rel="noreferrer"
                    target="_blank"
                    id="contact"
                    className="mx-auto mt-4"
                >
                    <Typography variant="subtitle2" color="textSecondary">
                        Contact Support
                    </Typography>
                </a>
            </React.Fragment>
        );
    };

    return (
        <Container maxWidth="sm" className="py-5" style={{ textAlign: 'center' }}>
            {showSelectChannel ? <SelectChannel {...props} /> : <EnterCode {...props} />}
        </Container>
    );
};
