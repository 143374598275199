import moment from 'moment';

const initialState = {
    lesson: {},
    course: {},
    loaded: null,
};

export const lessonReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'LESSON_REQUESTED':
            return {
                ...state,
            };
        case 'LESSON_SUCCESS': {
            const { lesson, course } = action.payload;
            return {
                ...state,
                lesson,
                course,
                loaded: moment(),
            };
        }
        case 'LESSON_FAILED':
            return {
                ...state,
                error: action.error,
            };

    }
};
