import axiosAPI from '@/shared/api';

export const authenticateUser = request => async dispatch => {
    dispatch(authenticateUserRequested());

    // Add _done (set within route-guard)
    request._done = window.localStorage.getItem('_done');
    window.localStorage.removeItem('_done');

    return axiosAPI()
        .post('/auth/signin', request)
        .then(response => {
            dispatch(authenticateUserSuccess(response.data));
        })
        .catch(error => {
            dispatch(authenticateUserFailed(error.response));
        });
};

function authenticateUserRequested() {
    return {
        type: 'AUTHENTICATE_USER_REQUESTED',
    };
}

function authenticateUserSuccess(payload) {
    return {
        type: 'AUTHENTICATE_USER_SUCCESS',
        payload,
    };
}

function authenticateUserFailed(error) {
    return {
        type: 'AUTHENTICATE_USER_FAILED',
        error,
    };
}
