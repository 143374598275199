import moment from 'moment';
import { uniqBy } from 'lodash';

const initialState = {
    loading: true,
    popular: [],
    trending: [],
    recent: [],
    library: [],
    lastLoadAt: null,
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'HOME_REQUESTED':
            return {
                ...state,
                loading: true,
            };
        case 'HOME_SUCCESS': {
            const { popular, trending, recent, library } = action.payload;
            return {
                ...state,
                popular,
                trending,
                recent,
                library,
                loading: false,
                lastLoadAt: moment(),
            };
        }

        case 'HOME_FAILED': {
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        }

        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            const updateId = a => {
                if (a.id == postable_id)
                    return {
                        ...a,
                        likes_count: likes_count,
                        post_track: {
                            ...a.post_track,
                            bookmark_flag,
                            like_flag,
                            enrollment_flag,
                        },
                    };
                return a;
            };

            const data = {
                ...state,
                popular: state.popular.map(updateId),
                trending: state.trending.map(updateId),
                recent: state.recent.map(updateId),
                library: state.library.filter(a => {
                    if (!bookmark_flag) {
                        return a.id !== postable_id;
                    }
                    return true;
                }),
            };

            if (bookmark_flag) {
                const all = [].concat(data.popular, data.trending, data.recent);
                const post = all.find(v => {
                    return v.id == postable_id;
                });
                if (post) {
                    data.library.unshift(post);
                }
            }

            return data;
        }

        case 'EXPIRE_STORE': {
            if (
                action.force ||
                (state.lastLoadAt && moment(state.lastLoadAt) < moment().subtract(60, 'minutes'))
            ) {
                return initialState;
            }
            return { ...state };
        }
    }
};

export { authorReducer } from '../author/author.reducer';
