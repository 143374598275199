import { connect } from 'react-redux';
import { RouteGuard as RouteGuardComponent } from './route-guard.component';
import { queueRedirect } from '@/shared/redirection/redirection.actions';

const mapStateToProps = state => {
    return {
        ...state.redirectionReducer,
        token: state.authReducer.token,
    };
};

export const RouteGuard = connect(mapStateToProps, { queueRedirect })(RouteGuardComponent);
