import React, { Fragment } from 'react';
import { Typography, Container } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Skeleton } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import { PostTrack } from '@/shared/articles/post-track';
import { PostInfo, PostInfoSkeleton } from '@/shared/components';
import classes from './post-card.module.scss';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import clsx from 'clsx';

const PostListItem = ({ post, showAsListItem }) => {
    const photo = get(post, 'photos.thumbnail.url');
    const resourcePhoto = get(post, 'photos.large.url');
    const isResource = post.type == 'resource';
    const isCourse = post.type == 'sfwd-courses';

    const listItem = (
        <FlexBox w100 row alignstart className="cursor-pointer">
            {(isResource && (
                <div
                    className={classes.thumbnailResource}
                    style={{ backgroundImage: `url(${resourcePhoto})` }}
                    aria-label={`${post.title}`}
                ></div>
            )) || (
                    <div
                        className={classes.thumbnail}
                        style={{ backgroundImage: `url(${photo})` }}
                        aria-label={`${post.title}`}
                    ></div>
                )}
            <FlexBox className="ml-3 mr-1">
                <Typography variant="body1" className={classes.postTitle}>
                    {post.title}
                </Typography>
                <PostInfo caption noLinks showAvatar={false} post={post}></PostInfo>
                {isResource && (
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        underline="none"
                        gutterBottom
                        className={(classes.postExcerpt, 'my-3')}
                    >
                        {(!showAsListItem && `${post.excerpt.substring(0, 240)}...`) ||
                            `${post.excerpt.substring(0, 75)}...`}
                    </Typography>
                )}
            </FlexBox>
        </FlexBox>
    );

    return (
        <Container maxWidth="md" className="ml-0" disableGutters>
            <FlexBox w100 row alignstart className={classes.listArticle}>
                {(isCourse && <MuiLink href={post.permalink}>{listItem}</MuiLink>) || (
                    <Link className="w-100" to={post.link}>
                        {listItem}
                    </Link>
                )}

                <PostTrack
                    small
                    className={classes.favoriteIcon}
                    post={post}
                    showBookmark={false}
                />
            </FlexBox>
        </Container>
    );
};

export const PostCardSkeleton = ({ type, showAsListItem }) => {
    const hasCourses = type == 'courses';
    const isResource = type == 'resources';

    if (!showAsListItem && !isResource) {
        return (
            <FlexBox>
                <Skeleton variant="rect" className={clsx(classes.thumbnail)}></Skeleton>
                <Skeleton className="mt-1" width={'90%'}></Skeleton>
                {hasCourses && <PostInfoSkeleton className="mt-3"></PostInfoSkeleton>}
            </FlexBox>
        );
    } else if (showAsListItem || isResource) {
        return (
            <FlexBox row w100 className={classes.listArticle}>
                <Skeleton
                    variant="rect"
                    className={isResource ? classes.thumbnailResource : clsx(classes.thumbnail)}
                ></Skeleton>
                <FlexBox w100 className="ml-3">
                    <Skeleton className="mt-1" width={'60%'}></Skeleton>
                    <Skeleton className="mt-1" width={'40%'}></Skeleton>
                    {hasCourses && <PostInfoSkeleton className="mt-3"></PostInfoSkeleton>}
                    {isResource && !showAsListItem && (
                        <Skeleton
                            key={3}
                            variant="rect"
                            width="85%"
                            height="120px"
                            className="my-3"
                        ></Skeleton>
                    )}
                    {isResource && showAsListItem && (
                        <Skeleton
                            key={3}
                            variant="rect"
                            width="85%"
                            height="72px"
                            className="my-3"
                        ></Skeleton>
                    )}
                </FlexBox>
            </FlexBox>
        );
    }
};

export const PostCard = ({ post, showAsListItem, type }) => {
    const isCourse = post.type == 'sfwd-courses';
    const isResource = type == 'resources';
    const photo = get(post, 'photos.large.url');

    const price = () => {
        if (post.post_track.enrollment_flag) {
            return 'Enrolled';
        }
        if (post.settings.course_price == '0.00') {
            return 'Free';
        }
        return `$${post.settings.course_price}`;
    };

    const duration = () => {
        if (post.youtube_video_duration == '00:00') {
            return 'UPCOMING';
        } else {
            return post.youtube_video_duration;
        }
    };

    const scheduledDate = new Date(post.youtube_video_scheduled_starttime);

    if (showAsListItem || isResource) {
        return <PostListItem post={post} showAsListItem={showAsListItem}></PostListItem>;
    }

    const renderCourse = () => {
        return (
            <Fragment>
                <MuiLink
                    style={{ width: '100%' }}
                    aria-label={`${post.title}`}
                    color="textPrimary"
                    underline="none"
                    href={post.permalink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div
                        className={classes.thumbnail}
                        style={{ backgroundImage: `url(${photo})` }}
                    ></div>
                    <FlexBox flex1 w100>
                        <Typography variant="h5" className="mr-2">
                            {post.title}
                        </Typography>
                    </FlexBox>
                    <FlexBox row justifybetween aligncenter w100 className={'mt-1'}>
                        {(post.is_bundle && (
                            <Typography variant="h6" display="block">
                                {post.bundled_courses.length} course bundle
                            </Typography>
                        )) || <PostInfo noLinks post={post}></PostInfo>}
                        <Typography className={classes.badge} variant="h6">
                            {price()}
                        </Typography>
                    </FlexBox>
                </MuiLink>
                <PostTrack white className={classes.favoriteContainer} post={post} />
            </Fragment>
        );
    };

    const renderPost = () => {
        return (
            <Fragment>
                <Link aria-label={`${post.title}`} to={post.link} style={{ width: '100%' }}>
                    <div className={classes.thumbnail} style={{ backgroundImage: `url(${photo})` }}>
                        <div className={classes.thumbnailVideoDuration}>{duration()}</div>
                    </div>

                    <FlexBox flex1 w100>
                        <Typography variant="h5" className="mr-2">
                            {post.title}
                        </Typography>

                        {(post.youtube_video_scheduled_starttime &&
                            post.youtube_video_duration === '00:00' && (
                                <div className="mt-1">
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.scheduledStarttime}
                                    >
                                        Scheduled for{' '}
                                        {scheduledDate.toLocaleTimeString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })}{' '}
                                        EST
                                    </Typography>
                                </div>
                            )) || (
                                <div className="mt-1">
                                    <PostInfo caption noLinks showAvatar={false} post={post}></PostInfo>
                                </div>
                            )}
                    </FlexBox>
                </Link>
                <PostTrack white className={classes.favoriteContainer} post={post} />
            </Fragment>
        );
    };

    return (
        <FlexBox column alignstart className={clsx(classes.article, 'mb-m-4')}>
            {(isCourse && renderCourse()) || renderPost()}
        </FlexBox>
    );
};
