import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import { get } from 'lodash';

export const VimeoPlayer = props => {

    const player = useRef(null);

    useEffect(() => {

        if (props.url) {

            player.current = new Player('vimeo', {url:props.url, responsive:1, title:false, byline:false, portrait:false});

            const startTime = get(props,'startTime',0);
            player.current && player.current.setCurrentTime(startTime).then().catch(error => {});

            /*
                * Not enabling autoplay as muted must be set to true in most browers.
            if (props.lesson.settings.lesson_video_auto_start == "on") {
                player.current.on('loaded', data => {
                    console.log('loaded: ', data);
                    player.current.play().then(() => {}).catch(error => console.log(error));
                });
            }
            */

            player.current && player.current.on('pause', props.onPause);
            player.current && player.current.on('timeupdate', props.onTimeUpdate);
        }

        return () => {
            player.current && player.current.destroy().then(() => {
                player.current = null;
            }).catch(error => {});
        }

    },[props.url]);

    return (
        <div id="vimeo" key={props.url}></div>
    )

}

