import moment from 'moment';

const initialState = {
    loaded: null,
    course: {},
};

export const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
        case 'COURSE_REQUESTED':
            return {
                ...state,
            };
        case 'COURSE_SUCCESS': {
            const course = action.payload.course;
            return {
                ...state,
                course,
                loaded: moment(),
            };
        }
        case 'COURSE_FAILED':
            return {
                ...state,
                error: action.error,
            };
        case 'POST_ACTION_SUCCESS': {
            const { post_track, likes_count } = action.payload;
            const { postable_id, bookmark_flag, like_flag, enrollment_flag } = post_track;

            let course = state.course;
            if (course.id == postable_id) {
                course.likes_count = likes_count;
                course.post_track = { bookmark_flag, like_flag, enrollment_flag };
            }

            return {
                ...state,
                course,
            };
        }
    }
};
