import React, { useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
};

function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

export const Confetti = ({ fireworks }) => {
    const confettiRef = useRef(null);
    const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);
    useEffect(() => {
        if (fireworks) {
            startAnimation();
        } else {
            handlerFire();
        }
    }, []);

    const getAnimationSettings = (originXA, originXB) => {
        return {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
            particleCount: 150,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2,
            },
        };
    };

    const nextTickAnimation = () => {
        confettiRef && confettiRef.current(getAnimationSettings(0.1, 0.3));
        confettiRef && confettiRef.current(getAnimationSettings(0.7, 0.9));
    };

    const startAnimation = () => {
        if (!isAnimationEnabled) {
            setIsAnimationEnabled(true);
            intervalRef.current = setInterval(nextTickAnimation, 400);
            timeoutRef.current = setTimeout(() => {
                clearInterval(intervalRef.current);
                clearTimeout(timeoutRef.current);
            }, 3000);
        }
    };

    const makeShot = (particleRatio, opts) => {
        confettiRef &&
            confettiRef.current?.({
                ...opts,
                origin: { y: 0.3 },
                particleCount: Math.floor(200 * particleRatio),
            });
    };

    const fire = () => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        makeShot(0.2, {
            spread: 60,
        });

        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    };

    const handlerFire = () => {
        fire();
    };

    const getInstance = ({ confetti }) => {
        confettiRef.current = confetti;
    };

    return (
        <>
            <ReactCanvasConfetti onInit={getInstance} style={canvasStyles} />
        </>
    );
};
