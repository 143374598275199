import { combineReducers } from 'redux';
import { homeReducer } from './home/home.reducer';
import { articlesReducer } from './articles/articles.reducer';
import { authorReducer } from './author/author.reducer';
import { coursesReducer } from './courses/courses.reducer';
import { courseReducer } from './courses/course/course.reducer';
import { videosReducer } from './videos/videos.reducer';
import { videoReducer } from './videos/video/video.reducer';

import { resourcesReducer } from './resources/resources.reducer';
import { resourceReducer } from './resources/resource/resource.reducer';

import { lessonReducer } from './courses/lesson/lesson.reducer';
import { searchReducer } from './search/search.reducer';
import { pageReducer } from './page/page.reducer';
import { libraryReducers } from './library/library.reducer';
import { triviaReducer } from './trivia/trivia.reducer';
import { createAccountReducer } from './create-account/create-account.reducer';
import { newsletterSignupReducer } from './newsletter-signup/newsletter-signup.reducer';
import { sharedReducers } from '@/shared/reducer';

export default combineReducers({
    homeReducer,
    articlesReducer,
    authorReducer,
    searchReducer,
    coursesReducer,
    courseReducer,
    videosReducer,
    videoReducer,
    resourcesReducer,
    resourceReducer,
    lessonReducer,
    pageReducer,
    triviaReducer,
    newsletterSignupReducer,
    createAccountReducer,
    ...libraryReducers,
    ...sharedReducers,
});
