import axiosAPI from '@/shared/api';
import { keys } from 'lodash';

export const getResults = (category = null, page = 1, criteria = {}) => async dispatch => {
    criteria = { ...criteria, page };
    dispatch(resultsRequested(criteria, page));

    let url = '/articles';
    if (category) {
        url += `/${category}`;

        if (criteria.tag) {
            url += `/${criteria.tag}`;
        } else {
            delete criteria.tag;
        }
    }

    var queryString = keys(criteria)
        .map(key => key + '=' + criteria[key])
        .join('&');

    return axiosAPI()
        .get(`${url}?${queryString}`)
        .then(response => {
            dispatch(resultsSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultsFailed(error.response));
        });
};

function resultsRequested(criteria, page) {
    return {
        type: 'CATEGORY_REQUESTED',
        payload: {
            criteria,
            page,
        },
    };
}

function resultsFailed(error) {
    return {
        type: 'CATEGORY_FAILED',
        error,
    };
}

function resultsSuccess(payload) {
    return {
        type: 'CATEGORY_SUCCESS',
        payload,
    };
}
