import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from '../../layouts/main-layout';
import { Videos } from '../../videos';
import { Video } from '../../videos/video';

export const VideoRoutes = () => {
    return (
        <Switch>
            <Route exact path={['/videos', '/videos']}>
                <MainLayout footerFixed={true}>
                    <Route
                        path="/videos"
                        exact
                        render={props => {
                            return <Videos {...props}></Videos>;
                        }}
                    />
                </MainLayout>
            </Route>

            <Route path={['/videos/:slug']}>
                <MainLayout fullWidth={true}>
                    <Route
                        exact
                        path="/videos/:slug"
                        render={props => {
                            const slug = props.match.params.slug;
                            return <Video key={slug} slug={slug} {...props}></Video>;
                        }}
                    />
                </MainLayout>
            </Route>
        </Switch>
    );
};

export default VideoRoutes;
