import React, { useRef, useState, useEffect } from 'react';
import { Typography, Button, Icon } from '@mui/material';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './user-progress.styles.module.scss';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import { motion } from 'framer-motion';
import { Countdown } from '../countdown';
import moment from 'moment';

export const UserProgress = ({ current_answers, onPlay, token, loaded, total_questions }) => {
    const [start, setStart] = useState(
        !current_answers || !current_answers.rank || current_answers.count == 0
    );
    useEffect(() => {
        setStart(!current_answers || !current_answers.rank || current_answers.count == 0);
    }, [current_answers]);
    if (!current_answers && !loaded) {
        return <Skeleton variant="rect" width={'100%'} height={122} />;
    }
    const completed = current_answers.count == 40;

    if (start) {
        return (
            <FlexBox row aligncenter justifybetween className={clsx(classes.startContainer)}>
                <div>
                    <Typography style={{ color: 'white' }} className="mb-1" variant="h6">
                        Hey{` ${token?.user?.first_name || 'there'}`},
                    </Typography>
                    <Typography style={{ fontSize: 24, color: 'white' }} variant="h5">
                        Start this week's trivia challenge!
                    </Typography>
                </div>

                <Button
                    onClick={onPlay}
                    className={classes.startButton}
                    variant="contained"
                    color="primary"
                >
                    <FlexBox aligncenter row>
                        <Typography
                            style={{ fontWeight: 800, fontSize: window.isDesktop ? 28 : 16 }}
                            variant="h4"
                        >
                            PLAY NOW
                        </Typography>
                        <Icon className={clsx(classes.icon, 'fas fa-play', 'ml-3')} />
                    </FlexBox>
                </Button>
            </FlexBox>
        );
    }

    const initial = { scale: 0.5, opacity: 0, y: 20 };
    const animate = { scale: 1, opacity: 1, y: 0 };

    const rank = (
        <FlexBox aligncenter>
            <Typography color="textSecondary" align="center" variant="subtitle2">
                Your Rank
            </Typography>
            <FlexBox justifycenter aligncenter className={classes.secondaryBoxes}>
                #{current_answers.rank || '--'}
            </FlexBox>
        </FlexBox>
    );
    const remainingPoints = (total_questions - current_answers.count) * 1000;

    const availableScore = (
        <FlexBox aligncenter className="mx-5 mx-m-0">
            <Typography
                className="mb-2"
                color="textSecondary"
                align="center"
                variant="subtitle2"
                style={{ color: '#1299da', fontSize: 16 }}
            >
                Available Points
            </Typography>
            <FlexBox row className={classes.availablePoints}>
                {remainingPoints
                    .toString()
                    .split('')
                    .map((number, i) => (
                        <motion.div
                            key={i}
                            className="number"
                            initial={initial}
                            animate={animate}
                            transition={{ delay: 0.1 + i / 10 }}
                        >
                            {number}
                        </motion.div>
                    ))}
            </FlexBox>
        </FlexBox>
    );
    const now = moment();
    const restartDate = moment()
        .startOf('week')
        .add(7, 'days');
    const duration = moment.duration(restartDate.diff(now));
    const secondsRemaining = duration.asSeconds();
    const timeRemaining = (
        <FlexBox aligncenter className="mx-5 mx-m-0">
            <Typography
                className="mb-2"
                color="textSecondary"
                align="center"
                variant="subtitle2"
                style={{ color: completed ? 'rgba(0,0,0,.6)' : '#1299da', fontSize: 16 }}
            >
                Next challenge in
            </Typography>
            <FlexBox row className={classes.availablePoints}>
                <Countdown
                    onComplete={() => setStart(true)}
                    secondsRemaining={secondsRemaining}
                ></Countdown>
            </FlexBox>
        </FlexBox>
    );

    const score = (
        <FlexBox aligncenter>
            <Typography color="textSecondary" align="center" variant="subtitle2">
                Your Score
            </Typography>
            <FlexBox justifycenter aligncenter className={classes.secondaryBoxes}>
                {current_answers.points}
            </FlexBox>
        </FlexBox>
    );
    return (
        <FlexBox
            aligncenter
            className={clsx(classes.container, completed && classes.completedContainer)}
        >
            <Typography
                align="center"
                className={clsx('mb-2 mt-2')}
                style={{ fontSize: 24 }}
                variant="h5"
            >
                {completed ? "You're finished for this week!" : 'You can still improve your score!'}
            </Typography>

            {window.isDesktop ? (
                <FlexBox aligncenter justifycenter row className="mt-3">
                    {rank}
                    {completed ? timeRemaining : availableScore}
                    {score}
                </FlexBox>
            ) : (
                <FlexBox aligncenter>
                    <FlexBox row className="my-3">
                        <div className="mr-4">{rank}</div> {score}
                    </FlexBox>
                    {completed ? timeRemaining : availableScore}
                </FlexBox>
            )}

            {!completed && (
                <Button
                    onClick={onPlay}
                    className={classes.mainButton}
                    variant="contained"
                    color="primary"
                >
                    <FlexBox aligncenter row>
                        <Typography
                            style={{ fontWeight: 800, fontSize: 28, whiteSpace: 'nowrap' }}
                            variant="h4"
                        >
                            PLAY
                        </Typography>
                        <Icon className={clsx(classes.icon, 'fas fa-play', 'ml-3')} />
                    </FlexBox>
                </Button>
            )}
        </FlexBox>
    );
};
