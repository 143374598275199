import axiosAPI from '@/shared/api';

export const selectTFAChannel = (request = {}) => async dispatch => {
    dispatch(selectTFAChannelRequested());

    return axiosAPI()
        .get('/auth/tfa/select', request)
        .then(response => {
            dispatch(selectTFAChannelSuccess(response.data));
        })
        .catch(error => {
            dispatch(selectTFAChannelFailed(error.response));
        });
};

function selectTFAChannelRequested() {
    return {
        type: 'SELECT_TFA_CHANNEL_REQUESTED',
    };
}

function selectTFAChannelSuccess(payload) {
    return {
        type: 'SELECT_TFA_CHANNEL_SUCCESS',
        payload,
    };
}

function selectTFAChannelFailed(error) {
    return {
        type: 'SELECT_TFA_CHANNEL_FAILED',
        error,
    };
}

export const sendTFACode = request => async dispatch => {
    dispatch(sendTFACodeRequested());

    return axiosAPI()
        .post('/auth/tfa/send', request)
        .then(response => {
            dispatch(sendTFACodeSuccess(response.data));
        })
        .catch(error => {
            dispatch(sendTFACodeFailed(error.response));
        });
};

function sendTFACodeRequested() {
    return {
        type: 'SEND_TFA_CODE_REQUESTED',
    };
}

function sendTFACodeSuccess(payload) {
    return {
        type: 'SEND_TFA_CODE_SUCCESS',
        payload,
    };
}

function sendTFACodeFailed(error) {
    return {
        type: 'SEND_TFA_CODE_FAILED',
        error,
    };
}

export const confirmTFACode = short_code => async dispatch => {
    dispatch(confirmTFACodeRequested());

    return axiosAPI()
        .post('/auth/tfa/confirm', { short_code })
        .then(response => {
            dispatch(confirmTFACodeSuccess(response.data));
        })
        .catch(error => {
            dispatch(confirmTFACodeFailed(error.response));
        });
};

function confirmTFACodeRequested() {
    return {
        type: 'CONFIRM_TFA_CODE_REQUESTED',
    };
}

function confirmTFACodeSuccess(payload) {
    return {
        type: 'CONFIRM_TFA_CODE_SUCCESS',
        payload,
    };
}

function confirmTFACodeFailed(error) {
    return {
        type: 'CONFIRM_TFA_CODE_FAILED',
        error,
    };
}

export const trustDevice = request => async dispatch => {
    dispatch(trustDeviceRequested());

    return axiosAPI()
        .post('/auth/tfa/trust', request)
        .then(response => {
            dispatch(trustDeviceSuccess(response.data));
        })
        .catch(error => {
            dispatch(trustDeviceFailed(error.response));
        });
};

function trustDeviceRequested() {
    return {
        type: 'TRUST_DEVICE_REQUESTED',
    };
}

function trustDeviceSuccess(payload) {
    return {
        type: 'TRUST_DEVICE_SUCCESS',
        payload,
    };
}

function trustDeviceFailed(error) {
    return {
        type: 'TRUST_DEVICE_FAILED',
        error,
    };
}
