import { connect } from 'react-redux';
import { Question as QuestionComponent } from './question.component';
import { getQuestion, answerQuestion } from '../trivia.actions.js';

const mapStateToProps = state => {
    return state.triviaReducer;
};

export const Question = connect(mapStateToProps, {
    getQuestion,
    answerQuestion,
})(QuestionComponent);
