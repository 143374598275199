import React from 'react';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import FlexBox from '@/shared/components/flex-box/flex-box';
import classes from './featured.styles.module.scss';
import { PostTrack } from '@/shared/articles/post-track';
import { PostInfo, PostInfoSkeleton } from '@/shared/components';

export const FeaturedSkeleton = () => {
    return (
        <div className={classes.post}>
            <Skeleton variant="rect" className={classes.postImage} width="100%" height="225px"></Skeleton>
            <div>
                <Skeleton key={1} className="mt-2" variant="rect" width="100%" height="20px"></Skeleton>
                <PostInfoSkeleton smallAvatar className="my-3"></PostInfoSkeleton>
                <Skeleton key={3} variant="rect" width="100%" height="120px"></Skeleton>
            </div>
        </div>
    );
};

export const Featured = ({ article }) => {
    if (!article) {
        return <FeaturedSkeleton></FeaturedSkeleton>;
    }

    return (
        <div className={classes.post}>
            <div aria-hidden="true" className={classes.postImageContainer}>
                <Link
                    className={classes.thumbnail}
                    style={{ backgroundImage: `url(${article?.photos?.large?.url})` }}
                    aria-label={`${article.title}`}
                    to={article.link}
                ></Link>
                <PostTrack className={classes.favoriteContainer} white post={article} />
            </div>

            <div>
                <Link to={article.link}>
                    <Typography
                        variant="h3"
                        component="h1"
                        color="textPrimary"
                        className={classes.featuredTitle}
                        underline="none"
                        gutterBottom
                    >
                        {article.title}
                    </Typography>
                </Link>
                <FlexBox aligncenter justifystart row className="my-3">
                    <PostInfo smallAvatar showCategory post={article}></PostInfo>
                </FlexBox>
                <Link to={article.link}>
                    <Typography variant="body1" color="textPrimary" underline="none" gutterBottom>
                        {article.excerpt.substring(0, 120)}...
                    </Typography>
                </Link>
            </div>
        </div>
    );
};
