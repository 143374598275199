import helpers from '@/shared/util/helpers';

const GuestGuard = {
    test: token => (token?.user ? false : true),
    fail: () => '/home',
};

const UserGuard = {
    test: token => (token?.user ? true : false),
    fail: () => '/signin',
};

const AdminGuard = {
    test: token => token?.user?.auths?.is_admin,
    fail: () => '/home',
};

const DevGuard = {
    test: token => token?.user?.auths?.is_admin || token?.user?.auths?.show_dev,
    fail: () => '/home',
};

const ProfileIncompleteGuard = {
    test: token => {
        if (helpers.isPlus()) {
            return !token?.user?.plus_auths?.has_complete_account;
        }
        return (
            !token?.user?.auths?.has_complete_account || !token?.user?.auths?.has_complete_profile
        );
    },
    fail: () => '/home',
};

const ProfileCompleteGuard = {
    test: token => {
        if (helpers.isPlus()) {
            return token?.user?.plus_auths?.has_complete_account;
        }
        return token?.user?.auths?.has_complete_account && token?.user?.auths?.has_complete_profile;
    },
    fail: token => {
        return token?.user?.incomplete?.usercreated
            ? '/createprofile/account'
            : '/createprofile/incomplete';
    },
};

const ProfileActiveGuard = {
    test: token => token?.user?.auths?.has_active_profile,
    fail: () => '/account/settings',
};

const BasicUserGuard = {
    test: token => !token?.user?.auths?.has_subscription,
    fail: () => '/account/services',
};

const StatusIsDeleted = {
    test: token => token?.user?.auths?.status == 'DELETED',
    fail: () => '/home',
};

const StatusIsOkay = {
    test: token => token?.user?.auths?.status == 'OKAY',
    fail: () => '/account/undelete',
};

const NoSuspension = {
    test: token => !token?.user?.auths?.suspension,
    fail: () => '/account',
};

const testDenyGuard = {
    test: () => false,
    fail: () => '/account/services?_TEST_DENY',
};

const testAllowGuard = {
    test: () => true,
    fail: () => '/account/orders?_TEST_ALLOW',
};

export const Guards = {
    guest: GuestGuard,
    user: UserGuard,
    admin: AdminGuard,
    dev: DevGuard,
    profileComplete: ProfileCompleteGuard,
    profileIncomplete: ProfileIncompleteGuard,
    profileActive: ProfileActiveGuard,
    basicUser: BasicUserGuard,
    statusDeleted: StatusIsDeleted,
    statusOkay: StatusIsOkay,
    testDeny: testDenyGuard,
    testAllow: testAllowGuard,
    noSuspension: NoSuspension,
};
