import axiosAPI from '@/shared/api';

export const getTrivia = () => async dispatch => {
    dispatch(triviaRequested());
    return axiosAPI()
        .get('/trivia')
        .then(response => {
            dispatch(triviaSuccess(response.data));
        })
        .catch(error => {
            dispatch(triviaFailed(error.response));
        });
};

function triviaRequested() {
    return {
        type: 'GET_TRIVIA_REQUESTED',
    };
}

function triviaFailed(error) {
    return {
        type: 'GET_TRIVIA_FAILED',
        error,
    };
}

function triviaSuccess(payload) {
    return {
        type: 'GET_TRIVIA_SUCCESS',
        payload,
    };
}

export const reportQuestion = data => async dispatch => {
    dispatch(reportQuestionRequested());
    return axiosAPI()
        .post('/trivia/report', data)
        .then(response => {
            dispatch(reportQuestionSuccess(response.data));
        })
        .catch(error => {
            dispatch(reportQuestionFailed(error.response));
        });
};

function reportQuestionRequested() {
    return {
        type: 'REPORT_QUESTION_REQUESTED',
    };
}

function reportQuestionFailed(error) {
    return {
        type: 'REPORT_QUESTION_FAILED',
        error,
    };
}

function reportQuestionSuccess(payload) {
    return {
        type: 'REPORT_QUESTION_SUCCESS',
        payload,
    };
}

export const clearQuestion = () => async dispatch => {
    dispatch({
        type: 'CLEAR_QUESTION',
    });
};
export const getQuestion = () => async dispatch => {
    dispatch(questionRequested());
    return axiosAPI()
        .get('/trivia/play', {
            catchExceptions: ['forbidden.trivia_max_plays'],
        })
        .then(response => {
            dispatch(questionSuccess(response.data));
        })
        .catch(error => {
            dispatch(questionFailed(error.response.exception));
            if (error.response.exception.is('forbidden.trivia_max_plays')) {
                dispatch(endTrivia());
            }
        });
};

function endTrivia() {
    return {
        type: 'SHOW_END_TRIVIA',
    };
}

function questionRequested() {
    return {
        type: 'GET_QUESTION_REQUESTED',
    };
}
function questionSuccess(payload) {
    return {
        type: 'GET_QUESTION_SUCCESS',
        payload,
    };
}

function questionFailed(error) {
    return {
        type: 'GET_QUESTION_FAILED',
        error,
    };
}
export const answerQuestion = (id, guess) => async dispatch => {
    dispatch(postQuestionRequested());
    return axiosAPI()
        .post(`/trivia/play/${id}`, guess)
        .then(response => {
            dispatch(postQuestionSuccess(response.data));
        })
        .catch(error => {
            dispatch(postQuestionFailed());
        });
};

function postQuestionRequested() {
    return {
        type: 'POST_ANSWER_REQUESTED',
    };
}

function postQuestionFailed() {
    return {
        type: 'POST_ANSWER_FAILED',
    };
}

function postQuestionSuccess(payload) {
    return {
        type: 'POST_ANSWER_SUCCESS',
        payload,
    };
}
export const getResult = () => async dispatch => {
    dispatch(resultRequested());
    return axiosAPI()
        .get('/trivia/results')
        .then(response => {
            dispatch(resultSuccess(response.data));
        })
        .catch(error => {
            dispatch(resultFailed(error.response));
        });
};

function resultRequested() {
    return {
        type: 'GET_QUESTION_REQUESTED',
    };
}

function resultFailed(error) {
    return {
        type: 'GET_QUESTION_FAILED',
        error,
    };
}

function resultSuccess(payload) {
    return {
        type: 'GET_QUESTION_SUCCESS',
        payload,
    };
}

export const getScores = yw => async dispatch => {
    dispatch(scoresRequested());
    const url = yw ? `/trivia/scores/${yw}` : '/trivia/scores';
    return axiosAPI()
        .get(url)
        .then(response => {
            dispatch(scoresSuccess(response.data));
        })
        .catch(error => {
            dispatch(scoresFailed(error.response));
        });
};

function scoresRequested() {
    return {
        type: 'GET_SCORES_REQUESTED',
    };
}

function scoresFailed(error) {
    return {
        type: 'GET_SCORES_FAILED',
        error,
    };
}

function scoresSuccess(payload) {
    return {
        type: 'GET_SCORES_SUCCESS',
        payload,
    };
}
